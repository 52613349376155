import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { fetchJSON, apiRootEndpoint } from '../../helpers';
import {
    GET_EMPLOYEES,
    GET_CURRENT_EMPLOYEE
} from '../../constants/actionTypes';


import {
    getAllEmployeesSuccess,
    getAllEmployeesFailed,

    getCurrentEmployeeSuccess,
    getCurrentEmployeeFailed
} from './actions';

const apiUrl = `${apiRootEndpoint}/api/v1/employees`;

function* getAllEmployees({ payload: { offset, limit, filter } }) {
    const options = {
        method: 'GET',
    };

    try {
        let url = `${apiUrl}?offset=${offset}&limit=${limit}`;
        if(filter.query) {
            url = `${url}&query=${filter.query}`
        }
        const {total, data} = yield call(fetchJSON, url, options);
        if(data && data.length) {
            
            yield put(getAllEmployeesSuccess({total, data}));
        }
        else {
            yield put(getAllEmployeesFailed('No data found'));
        }
    } catch (error) {
        
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(getAllEmployeesFailed(message));
        
    }
}


function* getCurrentEmployee({ payload: id }) {
    const options = {
        method: 'GET',
    };
    
    try {
        let url = `${apiUrl}/${id}`;
       
        const employee = yield call(fetchJSON, url, options);
        yield put(getCurrentEmployeeSuccess(employee.data));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(getCurrentEmployeeFailed(message));
    }
}


export function* watchGetAllAEmployees() {
    yield takeEvery(GET_EMPLOYEES, getAllEmployees);
}
export function* watchGetCurrentEmployee() {
    yield takeEvery(GET_CURRENT_EMPLOYEE, getCurrentEmployee);
}

function* employeeSaga() {
    yield all([
        fork(watchGetAllAEmployees),
        fork(watchGetCurrentEmployee),
    ]);
}

export default employeeSaga;