/* AUTH */
export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAILED = 'LOGIN_USER_FAILED';

export const REGISTER_USER = 'REGISTER_USER';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_FAILED = 'REGISTER_USER_FAILED';

export const LOGOUT_USER = 'LOGOUT_USER';

export const FORGET_PASSWORD = 'FORGET_PASSWORD';
export const FORGET_PASSWORD_SUCCESS = 'FORGET_PASSWORD_SUCCESS';
export const FORGET_PASSWORD_FAILED = 'FORGET_PASSWORD_FAILED';

export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILED = 'RESET_PASSWORD_FAILED';

/* USER  */
export const GET_USERS = 'GET_USERS';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_FAILED = 'GET_USERS_FAILED';

export const SUBMIT_USER_FORM = 'SUBMIT_USER_FORM';
export const SUBMIT_USER_FORM_SUCCESS = 'SUBMIT_USER_FORM_SUCCESS';
export const SUBMIT_USER_FORM_FAILED = 'SUBMIT_USER_FORM_FAILED';

export const DELETE_USER = 'DELETE_USER';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAILED = 'DELETE_USER_FAILED';

export const GET_CURRENT_USER = 'GET_CURRENT_USER';
export const GET_CURRENT_USER_SUCCESS = 'GET_CURRENT_USER_SUCCESS';
export const GET_CURRENT_USER_FAILED = 'GET_CURRENT_USER_FAILED';

/* TIMESHEET  */
export const GET_TIMESHEETS = 'GET_TIMESHEETS';
export const GET_TIMESHEETS_SUCCESS = 'GET_TIMESHEETS_SUCCESS';
export const GET_TIMESHEETS_FAILED = 'GET_TIMESHEETS_FAILED';

export const SUBMIT_TIMESHEET_FORM = 'SUBMIT_TIMESHEET_FORM';
export const SUBMIT_TIMESHEET_FORM_SUCCESS = 'SUBMIT_TIMESHEET_FORM_SUCCESS';
export const SUBMIT_TIMESHEET_FORM_FAILED = 'SUBMIT_TIMESHEET_FORM_FAILED';

export const DELETE_TIMESHEET = 'DELETE_TIMESHEET';
export const DELETE_TIMESHEET_SUCCESS = 'DELETE_TIMESHEET_SUCCESS';
export const DELETE_TIMESHEET_FAILED = 'DELETE_TIMESHEET_FAILED';

export const EXPORT_TIMESHEET = 'EXPORT_TIMESHEET';
export const EXPORT_TIMESHEET_SUCCESS = 'EXPORT_TIMESHEET_SUCCESS';
export const EXPORT_TIMESHEET_FAILED = 'EXPORT_TIMESHEET_FAILED';


/* TASK  */
export const GET_TASKS = 'GET_TASKS';
export const GET_TASKS_SUCCESS = 'GET_TASKS_SUCCESS';
export const GET_TASKS_FAILED = 'GET_TASKS_FAILED';

export const SUBMIT_TASK_FORM = 'SUBMIT_TASK_FORM';
export const SUBMIT_TASK_FORM_SUCCESS = 'SUBMIT_TASK_FORM_SUCCESS';
export const SUBMIT_TASK_FORM_FAILED = 'SUBMIT_TASK_FORM_FAILED';

export const DELETE_TASK = 'DELETE_TASK';
export const DELETE_TASK_SUCCESS = 'DELETE_TASK_SUCCESS';
export const DELETE_TASK_FAILED = 'DELETE_TASK_FAILED';

/* AUTH USER  */
export const SUBMIT_CHANGE_PASSWORD_FORM = 'SUBMIT_CHANGE_PASSWORD_FORM';
export const SUBMIT_CHANGE_PASSWORD_FORM_SUCCESS = 'SUBMIT_CHANGE_PASSWORD_FORM_SUCCESS';
export const SUBMIT_CHANGE_PASSWORD_FORM_FAILED = 'SUBMIT_CHANGE_PASSWORD_FORM_FAILED';


/* COUNTRY  */
export const GET_COUNTRY = 'GET_COUNTRY';
export const GET_COUNTRY_SUCCESS = 'GET_COUNTRY_SUCCESS';
export const GET_COUNTRY_FAILED = 'GET_COUNTRY_FAILED';

export const SUBMIT_COUNTRY_FORM = 'SUBMIT_COUNTRY_FORM';
export const SUBMIT_COUNTRY_FORM_SUCCESS = 'SUBMIT_COUNTRY_FORM_SUCCESS';
export const SUBMIT_COUNTRY_FORM_FAILED = 'SUBMIT_COUNTRY_FORM_FAILED';

export const DELETE_COUNTRY = 'DELETE_COUNTRY';
export const DELETE_COUNTRY_SUCCESS = 'DELETE_COUNTRY_SUCCESS';
export const DELETE_COUNTRY_FAILED = 'DELETE_COUNTRY_FAILED';

/* BRANCH  */
export const GET_BRANCHES = 'GET_BRANCHES';
export const GET_BRANCHES_SUCCESS = 'GET_BRANCHES_SUCCESS';
export const GET_BRANCHES_FAILED = 'GET_BRANCHES_FAILED';

export const SUBMIT_BRANCH_FORM = 'SUBMIT_BRANCH_FORM';
export const SUBMIT_BRANCH_FORM_SUCCESS = 'SUBMIT_BRANCH_FORM_SUCCESS';
export const SUBMIT_BRANCH_FORM_FAILED = 'SUBMIT_BRANCH_FORM_FAILED';

export const DELETE_BRANCH = 'DELETE_BRANCH';
export const DELETE_BRANCH_SUCCESS = 'DELETE_BRANCH_SUCCESS';
export const DELETE_BRANCH_FAILED = 'DELETE_BRANCH_FAILED';

/* STUDENT  */
export const GET_STUDENTS = 'GET_STUDENTS';
export const GET_STUDENTS_SUCCESS = 'GET_STUDENTS_SUCCESS';
export const GET_STUDENTS_FAILED = 'GET_STUDENTS_FAILED';

export const SUBMIT_STUDENT_FORM = 'SUBMIT_STUDENT_FORM';
export const SUBMIT_STUDENT_FORM_SUCCESS = 'SUBMIT_STUDENT_FORM_SUCCESS';
export const SUBMIT_STUDENT_FORM_FAILED = 'SUBMIT_STUDENT_FORM_FAILED';

export const DELETE_STUDENT = 'DELETE_STUDENT';
export const DELETE_STUDENT_SUCCESS = 'DELETE_STUDENT_SUCCESS';
export const DELETE_STUDENT_FAILED = 'DELETE_STUDENT_FAILED';

export const GET_CURRENT_STUDENT = 'GET_CURRENT_STUDENT';
export const GET_CURRENT_STUDENT_SUCCESS = 'GET_CURRENT_STUDENT_SUCCESS';
export const GET_CURRENT_STUDENT_FAILED = 'GET_CURRENT_STUDENT_FAILED';

/* COURSE  */
export const GET_COURSES = 'GET_COURSES';
export const GET_COURSES_SUCCESS = 'GET_COURSES_SUCCESS';
export const GET_COURSES_FAILED = 'GET_COURSES_FAILED';

export const SUBMIT_COURSE_FORM = 'SUBMIT_COURSE_FORM';
export const SUBMIT_COURSE_FORM_SUCCESS = 'SUBMIT_COURSE_FORM_SUCCESS';
export const SUBMIT_COURSE_FORM_FAILED = 'SUBMIT_COURSE_FORM_FAILED';

export const DELETE_COURSE = 'DELETE_COURSE';
export const DELETE_COURSE_SUCCESS = 'DELETE_COURSE_SUCCESS';
export const DELETE_COURSE_FAILED = 'DELETE_COURSE_FAILED';


/* INSTITUTE  */
export const GET_INSTITUTES = 'GET_INSTITUTES';
export const GET_INSTITUTES_SUCCESS = 'GET_INSTITUTES_SUCCESS';
export const GET_INSTITUTES_FAILED = 'GET_INSTITUTES_FAILED';

export const SUBMIT_INSTITUTE_FORM = 'SUBMIT_INSTITUTE_FORM';
export const SUBMIT_INSTITUTE_FORM_SUCCESS = 'SUBMIT_INSTITUTE_FORM_SUCCESS';
export const SUBMIT_INSTITUTE_FORM_FAILED = 'SUBMIT_INSTITUTE_FORM_FAILED';

export const DELETE_INSTITUTE = 'DELETE_INSTITUTE';
export const DELETE_INSTITUTE_SUCCESS = 'DELETE_INSTITUTE_SUCCESS';
export const DELETE_INSTITUTE_FAILED = 'DELETE_INSTITUTE_FAILED';

/* CREDIT NOTES  */
export const GET_CREDIT_NOTES = 'GET_CREDIT_NOTES';
export const GET_CREDIT_NOTES_SUCCESS = 'GET_CREDIT_NOTES_SUCCESS';
export const GET_CREDIT_NOTES_FAILED = 'GET_CREDIT_NOTES_FAILED';

export const SUBMIT_CREDIT_NOTE_FORM = 'SUBMIT_CREDIT_NOTE_FORM';
export const SUBMIT_CREDIT_NOTE_FORM_SUCCESS = 'SUBMIT_CREDIT_NOTE_FORM_SUCCESS';
export const SUBMIT_CREDIT_NOTE_FORM_FAILED = 'SUBMIT_CREDIT_NOTE_FORM_FAILED';

export const DELETE_CREDIT_NOTE = 'DELETE_CREDIT_NOTE';
export const DELETE_CREDIT_NOTE_SUCCESS = 'DELETE_CREDIT_NOTE_SUCCESS';
export const DELETE_CREDIT_NOTE_FAILED = 'DELETE_CREDIT_NOTE_FAILED';


export const VERIFY_CREDIT_NOTE_STUDENT = 'VERIFY_CREDIT_NOTE_STUDENT';
export const VERIFY_CREDIT_NOTE_STUDENT_SUCCESS = 'VERIFY_CREDIT_NOTE_STUDENT_SUCCESS';
export const VERIFY_CREDIT_NOTE_STUDENT_FAILED = 'VERIFY_CREDIT_NOTE_STUDENT_FAILED';

export const SUBMIT_CREDIT_NOTE_BANK_DETAILS_FORM = 'SUBMIT_CREDIT_NOTE_BANK_DETAILS_FORM';
export const VERIFY_CREDIT_NOTE_BANK_DETAILS_SUCCESS = 'SUBMIT_CREDIT_NOTE_BANK_DETAILS_SUCCESS';
export const VERIFY_CREDIT_NOTE_BANK_DETAILS_FAILED = 'SUBMIT_CREDIT_NOTE_BANK_DETAILS_FAILED';

export const SEND_CREDIT_NOTE_EMAIL = 'SEND_CREDIT_NOTE_EMAIL';
export const SEND_CREDIT_NOTE_EMAIL_SUCCESS = 'SEND_CREDIT_NOTE_EMAIL_SUCCESS';
export const SEND_CREDIT_NOTE_EMAIL_FAILED = 'SEND_CREDIT_NOTE_EMAIL_FAILED';

export const EXPORT_CREDIT_NOTES = 'EXPORT_CREDIT_NOTES';
export const EXPORT_CREDIT_NOTES_SUCCESS = 'EXPORT_CREDIT_NOTES_SUCCESS';
export const EXPORT_CREDIT_NOTES_FAILED = 'EXPORT_CREDIT_NOTES_FAILED';

export const DOWNLOAD_CREDIT_NOTE = 'DOWNLOAD_CREDIT_NOTE';
export const DOWNLOAD_CREDIT_NOTE_SUCCESS = 'DOWNLOAD_CREDIT_NOTE_SUCCESS';
export const DOWNLOAD_CREDIT_NOTE_FAILED = 'DOWNLOAD_CREDIT_NOTE_FAILED';

export const DOWNLOAD_SELECTED_CREDIT_NOTES = 'DOWNLOAD_SELECTED_CREDIT_NOTES';
export const DOWNLOAD_SELECTED_CREDIT_NOTES_SUCCESS = 'DOWNLOAD_SELECTED_CREDIT_NOTES_SUCCESS';
export const DOWNLOAD_SELECTED_CREDIT_NOTE_FAILED = 'DOWNLOAD_SELECTED_CREDIT_NOTE_FAILED';

export const TOGGLE_ALL_CREDIT_NOTES = 'TOGGLE_ALL_CREDIT_NOTES';
export const TOGGLE_ALL_CREDIT_NOTES_SUCCESS = 'TOGGLE_ALL_CREDIT_NOTES_SUCCESS';
export const TOGGLE_ALL_CREDIT_NOTES_FAILED = 'TOGGLE_ALL_CREDIT_NOTES_FAILED';

export const TOGGLE_CREDIT_NOTE = 'TOGGLE_CREDIT_NOTE';
export const TOGGLE_CREDIT_NOTE_SUCCESS = 'TOGGLE_CREDIT_NOTE_SUCCESS';
export const TOGGLE_CREDIT_NOTE_FAILED = 'TOGGLE_CREDIT_NOTE_FAILED';

/* COVID 19 ASSISTANCE */
export const GET_C19AS = 'GET_C19AS';
export const GET_C19AS_SUCCESS = 'GET_C19AS_SUCCESS';
export const GET_C19AS_NOTES_FAILED = 'GET_C19AS_NOTES_FAILED';

export const SUBMIT_C19A_FORM = 'SUBMIT_C19A_FORM';
export const SUBMIT_C19A_FORM_SUCCESS = 'SUBMIT_C19A_FORM_SUCCESS';
export const SUBMIT_C19A_FORM_FAILED = 'SUBMIT_C19A_FORM_FAILED';

export const DELETE_C19A = 'DELETE_C19A';
export const DELETE_C19A_SUCCESS = 'DELETE_C19A_SUCCESS';
export const DELETE_C19A_FAILED = 'DELETE_C19A_FAILED';


/* SUPPORT FUND STUDENT */
export const GET_SUPPORT_FUND_STUDENTS = 'GET_SUPPORT_FUND_STUDENTS';
export const GET_SUPPORT_FUND_STUDENTS_SUCCESS = 'GET_SUPPORT_FUND_STUDENTS_SUCCESS';
export const GET_SUPPORT_FUND_STUDENTS_NOTES_FAILED = 'GET_SUPPORT_FUND_STUDENTS_NOTES_FAILED';

export const SUBMIT_SUPPORT_FUND_STUDENT_FORM = 'SUBMIT_SUPPORT_FUND_STUDENT_FORM';
export const SUBMIT_SUPPORT_FUND_STUDENT_FORM_SUCCESS = 'SUBMIT_SUPPORT_FUND_STUDENT_FORM_SUCCESS';
export const SUBMIT_SUPPORT_FUND_STUDENT_FORM_FAILED = 'SUBMIT_SUPPORT_FUND_STUDENT_FORM_FAILED';

export const DELETE_SUPPORT_FUND_STUDENT = 'DELETE_SUPPORT_FUND_STUDENT';
export const DELETE_SUPPORT_FUND_STUDENT_SUCCESS = 'DELETE_SUPPORT_FUND_STUDENT_SUCCESS';
export const DELETE_SUPPORT_FUND_STUDENT_FAILED = 'DELETE_SUPPORT_FUND_STUDENT_FAILED';

export const EXPORT_SUPPORT_FUND_STUDENTS = 'EXPORT_SUPPORT_FUND_STUDENTS';
export const EXPORT_SUPPORT_FUND_STUDENTS_SUCCESS = 'EXPORT_SUPPORT_FUND_STUDENTS_SUCCESS';
export const EXPORT_SUPPORT_FUND_STUDENTS_FAILED = 'EXPORT_SUPPORT_FUND_STUDENTS_FAILED';


/* SUPPORT FUND ORGANIZATION */
export const GET_SUPPORT_FUND_ORGANIZATIONS = 'GET_SUPPORT_FUND_ORGANIZATIONS';
export const GET_SUPPORT_FUND_ORGANIZATIONS_SUCCESS = 'GET_SUPPORT_FUND_ORGANIZATIONS_SUCCESS';
export const GET_SUPPORT_FUND_ORGANIZATIONS_NOTES_FAILED = 'GET_SUPPORT_FUND_ORGANIZATIONS_NOTES_FAILED';

export const SUBMIT_SUPPORT_FUND_ORGANIZATION_FORM = 'SUBMIT_SUPPORT_FUND_ORGANIZATION_FORM';
export const SUBMIT_SUPPORT_FUND_ORGANIZATION_FORM_SUCCESS = 'SUBMIT_SUPPORT_FUND_ORGANIZATION_FORM_SUCCESS';
export const SUBMIT_SUPPORT_FUND_ORGANIZATION_FORM_FAILED = 'SUBMIT_SUPPORT_FUND_ORGANIZATION_FORM_FAILED';

export const DELETE_SUPPORT_FUND_ORGANIZATION= 'DELETE_SUPPORT_FUND_ORGANIZATION';
export const DELETE_SUPPORT_FUND_ORGANIZATION_SUCCESS = 'DELETE_SUPPORT_FUND_ORGANIZATION_SUCCESS';
export const DELETE_SUPPORT_FUND_ORGANIZATION_FAILED = 'DELETE_SUPPORT_FUND_ORGANIZATION_FAILED';

/* APPRAISAL FACTOR  */
export const GET_APPRAISAL_FACTORS = 'GET_APPRAISAL_FACTORS';
export const GET_APPRAISAL_FACTORS_SUCCESS = 'GET_APPRAISAL_FACTORS_SUCCESS';
export const GET_APPRAISAL_FACTORS_FAILED = 'GET_APPRAISAL_FACTORS_FAILED';

export const SUBMIT_APPRAISAL_FACTOR_FORM = 'SUBMIT_APPRAISAL_FACTOR_FORM';
export const SUBMIT_APPRAISAL_FACTOR_FORM_SUCCESS = 'SUBMIT_APPRAISAL_FACTOR_FORM_SUCCESS';
export const SUBMIT_APPRAISAL_FACTOR_FORM_FAILED = 'SUBMIT_APPRAISAL_FACTOR_FORM_FAILED';

export const DELETE_APPRAISAL_FACTOR = 'DELETE_APPRAISAL_FACTOR';
export const DELETE_APPRAISAL_FACTOR_SUCCESS = 'DELETE_APPRAISAL_FACTOR_SUCCESS';
export const DELETE_APPRAISAL_FACTOR_FAILED = 'DELETE_APPRAISAL_FACTOR_FAILED';

/* APPRAISAL SHEET  */
export const GET_APPRAISAL_SHEETS = 'GET_APPRAISAL_SHEETS';
export const GET_APPRAISAL_SHEETS_SUCCESS = 'GET_APPRAISAL_SHEETS_SUCCESS';
export const GET_APPRAISAL_SHEETS_FAILED = 'GET_APPRAISAL_SHEETS_FAILED';

export const SUBMIT_APPRAISAL_SHEET_FORM = 'SUBMIT_APPRAISAL_SHEET_FORM';
export const SUBMIT_APPRAISAL_SHEET_FORM_SUCCESS = 'SUBMIT_APPRAISAL_SHEET_FORM_SUCCESS';
export const SUBMIT_APPRAISAL_SHEET_FORM_FAILED = 'SUBMIT_APPRAISAL_SHEET_FORM_FAILED';

export const DELETE_APPRAISAL_SHEET = 'DELETE_APPRAISAL_SHEET';
export const DELETE_APPRAISAL_SHEET_SUCCESS = 'DELETE_APPRAISAL_SHEET_SUCCESS';
export const DELETE_APPRAISAL_SHEET_FAILED = 'DELETE_APPRAISAL_SHEET_FAILED';

export const GET_CURRENT_APPRAISAL_SHEET = 'GET_CURRENT_APPRAISAL_SHEET';
export const GET_CURRENT_APPRAISAL_SHEET_SUCCESS = 'GET_CURRENT_APPRAISAL_SHEET_SUCCESS';
export const GET_CURRENT_APPRAISAL_SHEET_FAILED = 'GET_CURRENT_APPRAISAL_SHEET_FAILED';

export const DOWNLOAD_APPRAISAL_SHEET = 'DOWNLOAD_APPRAISAL_SHEET';
export const DOWNLOAD_APPRAISAL_SHEET_SUCCESS = 'DOWNLOAD_APPRAISAL_SUCCESS';
export const DOWNLOAD_APPRAISAL_SHEET_FAILED = 'DOWNLOAD_APPRAISAL_FAILED';

/* APPRAISALS  */
export const GET_APPRAISALS = 'GET_APPRAISALS';
export const GET_APPRAISALS_SUCCESS = 'GET_APPRAISALS_SUCCESS';
export const GET_APPRAISALS_FAILED = 'GET_APPRAISALS_FAILED';

export const SUBMIT_APPRAISAL_FORM = 'SUBMIT_APPRAISAL_FORM';
export const SUBMIT_APPRAISAL_FORM_SUCCESS = 'SUBMIT_APPRAISAL_FORM_SUCCESS';
export const SUBMIT_APPRAISAL_FORM_FAILED = 'SUBMIT_APPRAISAL_FORM_FAILED';

export const DELETE_APPRAISAL = 'DELETE_APPRAISAL';
export const DELETE_APPRAISAL_SUCCESS = 'DELETE_APPRAISAL_SUCCESS';
export const DELETE_APPRAISAL_FAILED = 'DELETE_APPRAISAL_FAILED';

export const GET_CURRENT_APPRAISAL = 'GET_CURRENT_APPRAISAL';
export const GET_CURRENT_APPRAISAL_SUCCESS = 'GET_CURRENT_APPRAISAL_SUCCESS';
export const GET_CURRENT_APPRAISAL_FAILED = 'GET_CURRENT_APPRAISAL_FAILED';

export const DOWNLOAD_APPRAISAL = 'DOWNLOAD_APPRAISAL';
export const DOWNLOAD_APPRAISAL_SUCCESS = 'DOWNLOAD_APPRAISAL_SUCCESS';
export const DOWNLOAD_APPRAISAL_FAILED = 'DOWNLOAD_APPRAISAL_FAILED';

/* APPRAISAL FEEDBACK  */
export const GET_APPRAISAL_FEEDBACKS = 'GET_APPRAISAL_FEEDBACKS';
export const GET_APPRAISAL_FEEDBACKS_SUCCESS = 'GET_APPRAISAL_FEEDBACKS_SUCCESS';
export const GET_APPRAISAL_FEEDBACKS_FAILED = 'GET_APPRAISAL_FEEDBACKS_FAILED';

export const SUBMIT_APPRAISAL_FEEDBACK_FORM = 'SUBMIT_APPRAISAL_FEEDBACK_FORM';
export const SUBMIT_APPRAISAL_FEEDBACK_FORM_SUCCESS = 'SUBMIT_APPRAISAL_FEEDBACK_FORM_SUCCESS';
export const SUBMIT_APPRAISAL_FEEDBACK_FORM_FAILED = 'SUBMIT_APPRAISAL_FORM_FAILED';


/* COUNTRY  */
export const GET_DEPARTMENTS = 'GET_DEPARTMENTS';
export const GET_DEPARTMENTS_SUCCESS = 'GET_DEPARTMENTS_SUCCESS';
export const GET_DEPARTMENTS_FAILED = 'GET_DEPARTMENTS_FAILED';

export const SUBMIT_DEPARTMENT_FORM = 'SUBMIT_DEPARTMENT_FORM';
export const SUBMIT_DEPARTMENT_FORM_SUCCESS = 'SUBMIT_DEPARTMENT_FORM_SUCCESS';
export const SUBMIT_DEPARTMENT_FORM_FAILED = 'SUBMIT_DEPARTMENT_FORM_FAILED';

export const DELETE_DEPARTMENT = 'DELETE_DEPARTMENT';
export const DELETE_DEPARTMENT_SUCCESS = 'DELETE_DEPARTMENT_SUCCESS';
export const DELETE_DEPARTMENT_FAILED = 'DELETE_DEPARTMENT_FAILED';

/* ROLE  */
export const GET_ROLES = 'GET_ROLES';
export const GET_ROLES_SUCCESS = 'GET_ROLES_SUCCESS';
export const GET_ROLES_FAILED = 'GET_ROLES_FAILED';

export const SUBMIT_ROLE_FORM = 'SUBMIT_ROLE_FORM';
export const SUBMIT_ROLE_FORM_SUCCESS = 'SUBMIT_ROLE_FORM_SUCCESS';
export const SUBMIT_ROLE_FORM_FAILED = 'SUBMIT_ROLE_FORM_FAILED';

export const DELETE_ROLE = 'DELETE_ROLE';
export const DELETE_ROLE_SUCCESS = 'DELETE_ROLE_SUCCESS';
export const DELETE_ROLE_FAILED = 'DELETE_ROLE_FAILED';

export const GET_CURRENT_ROLE = 'GET_CURRENT_ROLE';
export const GET_CURRENT_ROLE_SUCCESS = 'GET_CURRENT_ROLE_SUCCESS';
export const GET_CURRENT_ROLE_FAILED = 'GET_CURRENT_ROLE_FAILED';

/* EMPLOYEE ONBOARDING */
export const GET_EMPLOYEE_ONBOARDINGS = 'GET_EMPLOYEE_ONBOARDINGS';
export const GET_EMPLOYEE_ONBOARDINGS_SUCCESS = 'GET_EMPLOYEE_ONBOARDINGS_SUCCESS';
export const GET_EMPLOYEE_ONBOARDINGS_FAILED = 'GET_EMPLOYEE_ONBOARDINGS_FAILED';

export const SUBMIT_EMPLOYEE_ONBOARDING_FORM = 'SUBMIT_EMPLOYEE_ONBOARDING_FORM';
export const SUBMIT_EMPLOYEE_ONBOARDING_FORM_SUCCESS = 'SUBMIT_EMPLOYEE_ONBOARDING_FORM_SUCCESS';
export const SUBMIT_EMPLOYEE_ONBOARDING_FORM_FAILED = 'SUBMIT_EMPLOYEE_ONBOARDING_FORM_FAILED';

export const DELETE_EMPLOYEE_ONBOARDING = 'DELETE_EMPLOYEE_ONBOARDING';
export const DELETE_EMPLOYEE_ONBOARDING_SUCCESS = 'DELETE_EMPLOYEE_ONBOARDING_SUCCESS';
export const DELETE_EMPLOYEE_ONBOARDING_FAILED = 'DELETE_EMPLOYEE_ONBOARDING_FAILED';

export const GET_CURRENT_EMPLOYEE_ONBOARDING = 'GET_CURRENT_EMPLOYEE_ONBOARDING';
export const GET_CURRENT_EMPLOYEE_ONBOARDING_SUCCESS = 'GET_CURRENT_EMPLOYEE_ONBOARDING_SUCCESS';
export const GET_CURRENT_EMPLOYEE_ONBOARDING_FAILED = 'GET_CURRENT_EMPLOYEE_ONBOARDING_FAILED';

export const DOWNLOAD_EMPLOYEE_ONBOARDING = 'DOWNLOAD_EMPLOYEE_ONBOARDING';
export const DOWNLOAD_EMPLOYEE_ONBOARDING_SUCCESS = 'DOWNLOAD_EMPLOYEE_ONBOARDING_SUCCESS';
export const DOWNLOAD_EMPLOYEE_ONBOARDING_FAILED = 'DOWNLOAD_EMPLOYEE_ONBOARDING_FAILED';

/* EMPLOYEES */
export const GET_EMPLOYEES = 'GET_EMPLOYEES';
export const GET_EMPLOYEES_SUCCESS = 'GET_EMPLOYEES_SUCCESS';
export const GET_EMPLOYEES_FAILED = 'GET_EMPLOYEES_FAILED';

export const GET_CURRENT_EMPLOYEE = 'GET_CURRENT_EMPLOYEE';
export const GET_CURRENT_EMPLOYEE_SUCCESS = 'GET_CURRENT_EMPLOYEE_SUCCESS';
export const GET_CURRENT_EMPLOYEE_FAILED = 'GET_CURRENT_EMPLOYEE_FAILED';


/* COE EMAIL  */
export const GET_COE_EMAILS = 'GET_COE_EMAILS';
export const GET_COE_EMAILS_SUCCESS = 'GET_COE_EMAILS_SUCCESS';
export const GET_COE_EMAILS_FAILED = 'GET_COE_EMAILS_FAILED';

export const SUBMIT_COE_EMAIL_FORM = 'SUBMIT_COE_EMAIL_FORM';
export const SUBMIT_COE_EMAIL_FORM_SUCCESS = 'SUBMIT_COE_EMAIL_FORM_SUCCESS';
export const SUBMIT_COE_EMAIL_FORM_FAILED = 'SUBMIT_COE_EMAIL_FORM_FAILED';

export const DELETE_COE_EMAIL = 'DELETE_COE_EMAIL';
export const DELETE_COE_EMAIL_SUCCESS = 'DELETE_COE_EMAIL_SUCCESS';
export const DELETE_COE_EMAIL_FAILED = 'DELETE_COE_EMAIL_FAILED';

export const IMPORT_COE_EMAILS = 'IMPORT_COE_EMAILS';
export const IMPORT_COE_EMAILS_SUCCESS = 'IMPORT_COE_EMAILS_SUCCESS';
export const IMPORT_COE_EMAILS_FAILED = 'IMPORT_COE_EMAILS_FAILED';



/* COE  */
export const GET_COES = 'GET_COES';
export const GET_COES_SUCCESS = 'GET_COES_SUCCESS';
export const GET_COES_FAILED = 'GET_COES_FAILED';

export const FETCH_COES = 'FETCH_COES';
export const FETCH_COES_SUCCESS = 'FETCH_COES_SUCCESS';
export const FETCH_COES_FAILED = 'FETCH_COES_FAILED';

export const SUBMIT_COES_FORM = 'SUBMIT_COES_FORM';
export const SUBMIT_COES_FORM_SUCCESS = 'SUBMIT_COES_FORM_SUCCESS';
export const SUBMIT_COES_FORM_FAILED = 'SUBMIT_COES_FORM_FAILED';

export const DELETE_COES = 'DELETE_COES';
export const DELETE_COES_SUCCESS = 'DELETE_COES_SUCCESS';
export const DELETE_COES_FAILED = 'DELETE_COES_FAILED';

export const EXPORT_COES = 'EXPORT_COES';
export const EXPORT_COES_SUCCESS = 'EXPORT_COES_SUCCESS';
export const EXPORT_COES_FAILED = 'EXPORT_COES_FAILED';

export const MATCH_COES = 'MATCH_COES';
export const MATCH_COES_SUCCESS = 'MATCH_COES_SUCCESS';
export const MATCH_COES_FAILED = 'MATCH_COES_FAILED';

/* COE TELEGRAM BOT  */
export const GET_TELEGRAM_COES = 'GET_TELEGRAM_COES';
export const GET_TELEGRAM_COES_SUCCESS = 'GET_TELEGRAM_COES_SUCCESS';
export const GET_TELEGRAM_COES_FAILED = 'GET_TELEGRAM_COES_FAILED';

export const GET_TELEGRAM_USERS = 'GET_TELEGRAM_USERS';
export const GET_TELEGRAM_USERS_SUCCESS = 'GET_TELEGRAM_USERS_SUCCESS';
export const GET_TELEGRAM_USERS_FAILED = 'GET_TELEGRAM_USERS_FAILED';

export const GET_TELEGRAM_COLLEGES = 'GET_TELEGRAM_COLLEGES';
export const GET_TELEGRAM_COLLEGES_SUCCESS = 'GET_TELEGRAM_COLLEGES_SUCCESS';
export const GET_TELEGRAM_COLLEGES_FAILED = 'GET_TELEGRAM_COLLEGES_FAILED';

export const GET_TELEGRAM_REPORT = 'GET_TELEGRAM_REPORT';
export const GET_TELEGRAM_REPORT_SUCCESS = 'GET_TELEGRAM_REPORT_SUCCESS';
export const GET_TELEGRAM_REPORT_FAILED = 'GET_TELEGRAM_REPORT_FAILED';


export const EXPORT_TELEGRAM_COES = 'EXPORT_TELEGRAM_COES';
export const EXPORT_TELEGRAM_COES_SUCCESS = 'EXPORT_TELEGRAM_COES_SUCCESS';
export const EXPORT_TELEGRAM_COES_FAILED = 'EXPORT_TELEGRAM_COES_FAILED';


export const SUBMIT_TELEGRAM_COE_FORM = 'SUBMIT_TELEGRAM_COE_FORM';
export const SUBMIT_TELEGRAM_COE_FORM_SUCCESS = 'SUBMIT_TELEGRAM_COE_FORM_SUCCESS';
export const SUBMIT_TELEGRAM_COE_FORM_FAILED = 'SUBMIT_TELEGRAM_COE_FORM_FAILED';

/* OCR  */
export const SUBMIT_OCR_FORM = 'SUBMIT_OCR_FORM';
export const SUBMIT_OCR_FORM_SUCCESS = 'SUBMIT_OCR_FORM_SUCCESS';
export const SUBMIT_OCR_FORM_FAILED = 'SUBMIT_OCR_FORM_FAILED';


export const SUBMIT_CRM_CLIENT_FORM = 'SUBMIT_CRM_CLIENT_FORM';
export const SUBMIT_CRM_CLIENT_FORM_SUCCESS = 'SUBMIT_CRM_CLIENT_FORM_SUCCESS';
export const SUBMIT_CRM_CLIENT_FORM_FAILED = 'SUBMIT_CRM_CLIENT_FORM_FAILED';

/* EMPLOYMENT CONTRACT  */
export const GET_EMPLOYMENT_CONTRACTS = 'GET_EMPLOYMENT_CONTRACTS';
export const GET_EMPLOYMENT_CONTRACTS_SUCCESS = 'GET_EMPLOYMENT_CONTRACTS_SUCCESS';
export const GET_EMPLOYMENT_CONTRACTS_FAILED = 'GET_EMPLOYMENT_CONTRACTS_FAILED';

export const SUBMIT_EMPLOYMENT_CONTRACT_FORM = 'SUBMIT_EMPLOYMENT_CONTRACT_FORM';
export const SUBMIT_EMPLOYMENT_CONTRACT_FORM_SUCCESS = 'SUBMIT_EMPLOYMENT_CONTRACT_FORM_SUCCESS';
export const SUBMIT_EMPLOYMENT_CONTRACT_FORM_FAILED = 'SUBMIT_EMPLOYMENT_CONTRACT_FORM_FAILED';

export const DELETE_EMPLOYMENT_CONTRACT = 'DELETE_EMPLOYMENT_CONTRACT';
export const DELETE_EMPLOYMENT_CONTRACT_SUCCESS = 'DELETE_EMPLOYMENT_CONTRACT_SUCCESS';
export const DELETE_EMPLOYMENT_CONTRACT_FAILED = 'DELETE_EMPLOYMENT_CONTRACT_FAILED';

export const GET_CURRENT_EMPLOYMENT_CONTRACT = 'GET_CURRENT_EMPLOYMENT_CONTRACT';
export const GET_CURRENT_EMPLOYMENT_CONTRACT_SUCCESS = 'GET_CURRENT_EMPLOYMENT_CONTRACT_SUCCESS';
export const GET_CURRENT_EMPLOYMENT_CONTRACT_FAILED = 'GET_CURRENT_EMPLOYMENT_CONTRACT_FAILED';

/* EVENTS  */
export const GET_EVENTS = 'GET_EVENTS';
export const GET_EVENTS_SUCCESS = 'GET_EVENTS_SUCCESS';
export const GET_EVENTS_FAILED = 'GET_EVENTS_FAILED';

export const SUBMIT_EVENT_FORM = 'SUBMIT_EVENT_FORM';
export const SUBMIT_EVENT_FORM_SUCCESS = 'SUBMIT_EVENT_FORM_SUCCESS';
export const SUBMIT_EVENT_FORM_FAILED = 'SUBMIT_EVENT_FORM_FAILED';

export const DELETE_EVENT = 'DELETE_EVENT';
export const DELETE_EVENT_SUCCESS = 'DELETE_EVENT_SUCCESS';
export const DELETE_EVENT_FAILED = 'DELETE_EVENT_FAILED';

/* EVENT PARTICIPANTS  */
export const GET_EVENT_PARTICIPANTS = 'GET_EVENT_PARTICIPANTS';
export const GET_EVENT_PARTICIPANTS_SUCCESS = 'GET_EVENT_PARTICIPANTS_SUCCESS';
export const GET_EVENT_PARTICIPANTS_FAILED = 'GET_EVENT_PARTICIPANTS_SUCCESS';

export const SUBMIT_EVENT_PARTICIPANT_FORM = 'SUBMIT_EVENT_PARTICIPANT_FORM';
export const SUBMIT_EVENT_PARTICIPANT_FORM_SUCCESS = 'SUBMIT_EVENT_PARTICIPANT_FORM_SUCCESS';
export const SUBMIT_EVENT_PARTICIPANT_FORM_FAILED = 'SUBMIT_EVENT_PARTICIPANT_FORM_FAILED';

export const DELETE_EVENT_PARTICIPANT = 'DELETE_EVENT_PARTICIPANT';
export const DELETE_EVENT_PARTICIPANT_SUCCESS = 'DELETE_EVENT_PARTICIPANT_SUCCESS';
export const DELETE_EVENT_PARTICIPANT_FAILED = 'DELETE_EVENT_PARTICIPANT_FAILED';

/* TRAINING PROVIDER  */
export const GET_TRAINING_PROVIDERS = 'GET_TRAINING_PROVIDERS';
export const GET_TRAINING_PROVIDERS_SUCCESS = 'GET_TRAINING_PROVIDERS_SUCCESS';
export const GET_TRAINING_PROVIDERS_FAILED = 'GET_TRAINING_PROVIDERS_FAILED';

export const SUBMIT_TRAINING_PROVIDER_FORM = 'SUBMIT_TRAINING_PROVIDER_FORM';
export const SUBMIT_TRAINING_PROVIDER_FORM_SUCCESS = 'SUBMIT_TRAINING_PROVIDER_FORM_SUCCESS';
export const SUBMIT_TRAINING_PROVIDER_FORM_FAILED = 'SUBMIT_TRAINING_PROVIDER_FORM_FAILED';

export const DELETE_TRAINING_PROVIDER = 'DELETE_TRAINING_PROVIDER';
export const DELETE_TRAINING_PROVIDER_SUCCESS = 'DELETE_TRAINING_PROVIDER_SUCCESS';
export const DELETE_TRAINING_PROVIDER_FAILED = 'DELETE_TRAINING_PROVIDER_FAILED';

/* TRAINING PROGRAM  */
export const GET_TRAINING_PROGRAMS = 'GET_TRAINING_PROGRAMS';
export const GET_TRAINING_PROGRAMS_SUCCESS = 'GET_TRAINING_PROGRAMS_SUCCESS';
export const GET_TRAINING_PROGRAMS_FAILED = 'GET_TRAINING_PROGRAMS_FAILED';

export const SUBMIT_TRAINING_PROGRAM_FORM = 'SUBMIT_TRAINING_PROGRAM_FORM';
export const SUBMIT_TRAINING_PROGRAM_FORM_SUCCESS = 'SUBMIT_TRAINING_PROGRAM_FORM_SUCCESS';
export const SUBMIT_TRAINING_PROGRAM_FORM_FAILED = 'SUBMIT_TRAINING_PROGRAM_FORM_FAILED';

export const DELETE_TRAINING_PROGRAM = 'DELETE_TRAINING_PROGRAM';
export const DELETE_TRAINING_PROGRAM_SUCCESS = 'DELETE_TRAINING_PROGRAM_SUCCESS';
export const DELETE_TRAINING_PROGRAM_FAILED = 'DELETE_TRAINING_PROGRAM_FAILED';

/* EMPLOYEE TRAINING  */
export const GET_EMPLOYEE_TRAININGS = 'GET_EMPLOYEE_TRAININGS';
export const GET_EMPLOYEE_TRAININGS_SUCCESS = 'GET_EMPLOYEE_TRAININGS_SUCCESS';
export const GET_EMPLOYEE_TRAININGS_FAILED = 'GET_EMPLOYEE_TRAININGS_FAILED';

export const GET_CURRENT_EMPLOYEE_TRAINING = 'GET_CURRENT_EMPLOYEE_TRAINING';
export const GET_CURRENT_EMPLOYEE_TRAINING_SUCCESS = 'GET_CURRENT_EMPLOYEE_TRAINING_SUCCESS';
export const GET_CURRENT_EMPLOYEE_TRAINING_FAILED = 'GET_CURRENT_EMPLOYEE_TRAINING_FAILED';

export const SUBMIT_EMPLOYEE_TRAINING_FORM = 'SUBMIT_EMPLOYEE_TRAINING_FORM';
export const SUBMIT_EMPLOYEE_TRAINING_FORM_SUCCESS = 'SUBMIT_EMPLOYEE_TRAINING_FORM_SUCCESS';
export const SUBMIT_EMPLOYEE_TRAINING_FORM_FAILED = 'SUBMIT_EMPLOYEE_TRAINING_FORM_FAILED';

export const DELETE_EMPLOYEE_TRAINING = 'DELETE_EMPLOYEE_TRAINING';
export const DELETE_EMPLOYEE_TRAINING_SUCCESS = 'DELETE_EMPLOYEE_TRAINING_SUCCESS';
export const DELETE_EMPLOYEE_TRAINING_FAILED = 'DELETE_EMPLOYEE_TRAINING_FAILED';



/* LEAVE REQUEST  */
export const GET_LEAVE_REQUESTS = 'GET_LEAVE_REQUESTS';
export const GET_LEAVE_REQUESTS_SUCCESS = 'GET_LEAVE_REQUESTS_SUCCESS';
export const GET_LEAVE_REQUESTS_FAILED = 'GET_LEAVE_REQUESTS_FAILED';

export const GET_CURRENT_LEAVE_REQUEST_TRAINING = 'GET_CURRENT_LEAVE_REQUEST_TRAINING';
export const GET_CURRENT_LEAVE_REQUEST_SUCCESS = 'GET_CURRENT_LEAVE_REQUEST_SUCCESS';
export const GET_CURRENT_LEAVE_REQUEST_FAILED = 'GET_CURRENT_LEAVE_REQUEST_FAILED';

export const SUBMIT_LEAVE_REQUEST_FORM = 'SUBMIT_LEAVE_REQUEST_FORM';
export const SUBMIT_LEAVE_REQUEST_FORM_SUCCESS = 'SUBMIT_LEAVE_REQUEST_FORM_SUCCESS';
export const SUBMIT_LEAVE_REQUEST_FORM_FAILED = 'SUBMIT_LEAVE_REQUEST_FORM_FAILED';

export const DELETE_LEAVE_REQUEST = 'DELETE_LEAVE_REQUEST';
export const DELETE_LEAVE_REQUEST_SUCCESS = 'DELETE_LEAVE_REQUEST_SUCCESS';
export const DELETE_LEAVE_REQUEST_FAILED = 'DELETE_LEAVE_REQUEST_FAILED';

export const EXPORT_LEAVE_REQUESTS = 'EXPORT_LEAVE_REQUESTS';
export const EXPORT_LEAVE_REQUESTS_SUCCESS = 'EXPORT_LEAVE_REQUESTS_SUCCESS';
export const EXPORT_LEAVE_REQUESTS_FAILED = 'EXPORT_LEAVE_REQUESTS_FAILED';


/* TELEGRAM GROUP  */
export const GET_TELEGRAM_GROUPS = 'GET_TELEGRAM_GROUPS';
export const GET_TELEGRAM_GROUPS_SUCCESS = 'GET_TELEGRAM_GROUPS_SUCCESS';
export const GET_TELEGRAM_GROUPS_FAILED = 'GET_TELEGRAM_GROUPS_FAILED';

export const SUBMIT_TELEGRAM_GROUP_FORM = 'SUBMIT_TELEGRAM_GROUP_FORM';
export const SUBMIT_TELEGRAM_GROUP_FORM_SUCCESS = 'SUBMIT_TELEGRAM_GROUP_FORM_SUCCESS';
export const SUBMIT_TELEGRAM_GROUP_FORM_FAILED = 'SUBMIT_TELEGRAM_GROUP_FORM_FAILED';

export const DELETE_TELEGRAM_GROUP = 'DELETE_TELEGRAM_GROUP';
export const DELETE_TELEGRAM_GROUP_SUCCESS = 'DELETE_TELEGRAM_GROUP_SUCCESS';
export const DELETE_TELEGRAM_GROUP_FAILED = 'DELETE_TELEGRAM_GROUP_FAILED';


/* TARGET  */
export const GET_TARGETS = 'GET_TARGETS';
export const GET_TARGETS_SUCCESS = 'GET_TARGETS_SUCCESS';
export const GET_TARGETS_FAILED = 'GET_TARGETS_FAILED';

export const SUBMIT_TARGET_FORM = 'SUBMIT_TARGET_FORM';
export const SUBMIT_TARGET_FORM_SUCCESS = 'SUBMIT_TARGET_FORM_SUCCESS';
export const SUBMIT_TARGET_FORM_FAILED = 'SUBMIT_TARGET_FORM_FAILED';

export const DELETE_TARGET = 'DELETE_TARGET';
export const DELETE_TARGET_SUCCESS = 'DELETE_TARGET_SUCCESS';
export const DELETE_TARGET_FAILED = 'DELETE_TARGET_FAILED';