import {
    GET_TARGETS,
    GET_TARGETS_SUCCESS,
    GET_TARGETS_FAILED,

    SUBMIT_TARGET_FORM,
    SUBMIT_TARGET_FORM_SUCCESS,
    SUBMIT_TARGET_FORM_FAILED,

    DELETE_TARGET,
    DELETE_TARGET_SUCCESS,
    DELETE_TARGET_FAILED,
} from '../../constants/actionTypes';


/** GET ALL TELEGRAM GROUPS */
export const getAllTargets = ({offset, limit, filter}) => ({
    type: GET_TARGETS,
    payload: { offset, limit, filter }
});

export const getAllTargetsSuccess = ({total, data}) => ({
    type: GET_TARGETS_SUCCESS,
    payload: {total, data}
});

export const getAllTargetsFailed = (error) => ({
    type: GET_TARGETS_FAILED,
    payload: error
});




/** SUBMIT FORM */
export const submitTargetForm = (timesheet) => ({
    type: SUBMIT_TARGET_FORM,
    payload: timesheet
});

export const submitTargetFormSuccess = (user) => ({
    type: SUBMIT_TARGET_FORM_SUCCESS,
    payload: user
});

export const submitTargetFormFailed = (error) => ({
    type: SUBMIT_TARGET_FORM_FAILED,
    payload: error
});

/** DELETE ITEM */
export const deleteTarget = (id) => ({
    type: DELETE_TARGET,
    payload: id
});

export const deleteTargetSuccess = (id) => ({
    type: DELETE_TARGET_SUCCESS,
    payload: id
});

export const deleteTargetFailed = (error) => ({
    type: DELETE_TARGET_FAILED,
    payload: error
});