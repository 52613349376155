import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { fetchJSON, apiRootEndpoint } from '../../helpers';
import {
    GET_COURSES, 
    SUBMIT_COURSE_FORM,
    DELETE_COURSE
} from '../../constants/actionTypes';


import {
    getAllCoursesSuccess,
    getAllCoursesFailed,

    submitCourseFormSuccess,
    submitCourseFormFailed,

    deleteCourseSuccess,
    deleteCourseFailed,
} from './actions';

const apiUrl = `${apiRootEndpoint}/api/v1/courses`;

function* getAllCourses({ payload: { offset, limit, filter } }) {
    const options = {
        method: 'GET',
    };

    try {
        let url = `${apiUrl}?offset=${offset}&limit=${limit}`;
        if(filter.instituteId) {
            url = `${url}&instituteId=${filter.instituteId}`;
        }
        if(filter.query) {
            url = `${url}&query=${filter.query}`;
        }
        const {total, data} = yield call(fetchJSON, url, options);
        yield put(getAllCoursesSuccess({total, data}));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(getAllCoursesFailed(message));
    }
}

function* submitCourse({ payload: course }) {
    const options = {
        body: JSON.stringify(course),
        method: course.id ? 'PUT' :  'POST',
        headers: { 'Content-Type': 'application/json' }
    };

    try {
        let url = `${apiUrl}`;
        if(course.id) {
            url = `${apiUrl}/${course.id}`;
        }
        const response = yield call(fetchJSON, url, options);
        if(response.status === 'OK') {
            yield put(submitCourseFormSuccess(response.data));
        }
        else {
            yield put(submitCourseFormFailed(response.message));
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(submitCourseFormFailed(message));
    }
}

function* deleteCourse({ payload: id }) {
    const options = {
        method: 'DELETE',
    };
    try {
        const url = `${apiUrl}/${id}`;
        const response = yield call(fetchJSON, url, options);
        if(response.status === 'OK') {
            yield put(deleteCourseSuccess(id));
        }
        else {
            yield put(deleteCourseFailed(response.message));
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(deleteCourseFailed(message));
    }
}


export function* watchGetAllCourses() {
    yield takeEvery(GET_COURSES, getAllCourses);
}
export function* watchSubmitCourseForm() {
    yield takeEvery(SUBMIT_COURSE_FORM, submitCourse);
}
export function* watchDeleteCourse() {
    yield takeEvery(DELETE_COURSE, deleteCourse);
}

function* courseSaga() {
    yield all([
        fork(watchGetAllCourses),
        fork(watchSubmitCourseForm),
        fork(watchDeleteCourse),
    ]);
}

export default courseSaga;