import {
    GET_COE_EMAILS,
    GET_COE_EMAILS_SUCCESS,
    GET_COE_EMAILS_FAILED,

    SUBMIT_COE_EMAIL_FORM,
    SUBMIT_COE_EMAIL_FORM_SUCCESS,
    SUBMIT_COE_EMAIL_FORM_FAILED,

    DELETE_COE_EMAIL,
    DELETE_COE_EMAIL_SUCCESS,
    DELETE_COE_EMAIL_FAILED,

    IMPORT_COE_EMAILS,
    IMPORT_COE_EMAILS_SUCCESS,
    IMPORT_COE_EMAILS_FAILED
} from '../../constants/actionTypes';


/** GET ALL */
export const getAllCoeEmails = ({offset, limit, filter}) => ({
    type: GET_COE_EMAILS,
    payload: { offset, limit, filter }
});

export const getAllCoeEmailsSuccess = ({total, data}) => ({
    type: GET_COE_EMAILS_SUCCESS,
    payload: {total, data}
});

export const getAllCoeEmailsFailed = (error) => ({
    type: GET_COE_EMAILS_FAILED,
    payload: error
});

/** SUBMIT FORM */
export const submitCoeEmailForm = (country) => ({
    type: SUBMIT_COE_EMAIL_FORM,
    payload: country
});

export const submitCoeEmailFormSuccess = (role) => ({
    type: SUBMIT_COE_EMAIL_FORM_SUCCESS,
    payload: role
});

export const submitCoeEmailFormFailed = (error) => ({
    type: SUBMIT_COE_EMAIL_FORM_FAILED,
    payload: error
});

/** DELETE FORM */
export const deleteCoeEmail = (id) => ({
    type: DELETE_COE_EMAIL,
    payload: id
});

export const deleteCoeEmailSuccess = (id) => ({
    type: DELETE_COE_EMAIL_SUCCESS,
    payload: id
});

export const deleteCoeEmailFailed = (error) => ({
    type: DELETE_COE_EMAIL_FAILED,
    payload: error
});

/** IMPORT */
export const importCoeEmails = ({file}) => ({
    type: IMPORT_COE_EMAILS,
    payload: file
});

export const importCoeEmailsSuccess = ({total, data}) => ({
    type: IMPORT_COE_EMAILS_SUCCESS,
    payload: {total, data}
});

export const importCoeEmailsFailed = (error) => ({
    type: IMPORT_COE_EMAILS_FAILED,
    payload: error
});