import {
    GET_TASKS,
    GET_TASKS_SUCCESS,
    GET_TASKS_FAILED,

    SUBMIT_TASK_FORM,
    SUBMIT_TASK_FORM_SUCCESS,
    SUBMIT_TASK_FORM_FAILED,

    DELETE_TASK,
    DELETE_TASK_SUCCESS,
    DELETE_TASK_FAILED,
} from '../../constants/actionTypes'; 


/** GET ALL */
export const getAllTasks = ({offset, limit, filter}) => ({
    type: GET_TASKS,
    payload: { offset, limit, filter }
});

export const getAllTasksSuccess = ({total, data}) => ({
    type: GET_TASKS_SUCCESS,
    payload: {total, data}
});

export const getAllTasksFailed = (error) => ({
    type: GET_TASKS_FAILED,
    payload: error
});

/** SUBMIT FORM */
export const submitTaskForm = (task) => ({
    type: SUBMIT_TASK_FORM,
    payload: task
});

export const submitTaskFormSuccess = (task) => ({
    type: SUBMIT_TASK_FORM_SUCCESS,
    payload: task
});

export const submitTaskFormFailed = (error) => ({
    type: SUBMIT_TASK_FORM_FAILED,
    payload: error
});

/** DELETE FORM */
export const deleteTask = (id) => ({
    type: DELETE_TASK,
    payload: id
});

export const deleteTaskSuccess = (id) => ({
    type: DELETE_TASK_SUCCESS,
    payload: id
});

export const deleteTaskFailed = (error) => ({
    type: DELETE_TASK_FAILED,
    payload: error
});