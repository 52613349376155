import {
    GET_INSTITUTES,
    GET_INSTITUTES_SUCCESS,
    GET_INSTITUTES_FAILED,

    SUBMIT_INSTITUTE_FORM,
    SUBMIT_INSTITUTE_FORM_SUCCESS,
    SUBMIT_INSTITUTE_FORM_FAILED,

    DELETE_INSTITUTE,
    DELETE_INSTITUTE_SUCCESS,
    DELETE_INSTITUTE_FAILED,
} from '../../constants/actionTypes';

const INIT_STATE = {
    loading: false,
    institutes: [],
    submittingForm: false,
    error: null,
    deleting: false
};

const InstituteReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_INSTITUTES:
            return { ...state, loading: true };
        case GET_INSTITUTES_SUCCESS:
            return { ...state, institutes: action.payload.data, total: action.payload.total, loading: false, error: null };
        case GET_INSTITUTES_FAILED:
            return { ...state, error: action.payload, loading: false };

        case SUBMIT_INSTITUTE_FORM:
            return { ...state, error: null, submittingForm: true };
        case SUBMIT_INSTITUTE_FORM_SUCCESS:
            const alreadyExists = state.institutes.find((institute) => institute.id === action.payload.id);
            let institutes;
            let total = state.total;
            if (!alreadyExists) {
                institutes = [action.payload, ...state.institutes];
                total += 1;
            } else {
                institutes = state.institutes.map((institute) => {
                if (institute.id === action.payload.id) {
                  return action.payload;
                }
                return institute;
              });
            }
            return { ...state, total,  institutes, submittingForm: false };
        case SUBMIT_INSTITUTE_FORM_FAILED:
            return { ...state, error: action.payload, submittingForm: false };

        case DELETE_INSTITUTE:
            return { ...state, error: null, deleting: true };
        case DELETE_INSTITUTE_SUCCESS:
            return { ...state, total: state.total - 1, institutes: state.institutes.filter(institute => institute.id !== action.payload) , deleting: false };
        case DELETE_INSTITUTE_FAILED:
            return { ...state, error: action.payload, deleting: false };
        default: return { ...state };
    }
}

export default InstituteReducer;