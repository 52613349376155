import {
    GET_TIMESHEETS,
    GET_TIMESHEETS_SUCCESS,
    GET_TIMESHEETS_FAILED,

    SUBMIT_TIMESHEET_FORM,
    SUBMIT_TIMESHEET_FORM_SUCCESS,
    SUBMIT_TIMESHEET_FORM_FAILED,

    DELETE_TIMESHEET,
    DELETE_TIMESHEET_SUCCESS,
    DELETE_TIMESHEET_FAILED,

    EXPORT_TIMESHEET,
    EXPORT_TIMESHEET_SUCCESS,
    EXPORT_TIMESHEET_FAILED,
} from '../../constants/actionTypes';

const INIT_STATE = {
    loading: false,
    timesheets: [],
    submittingForm: false,
    error: null,
    deleting: false,
    exporting: false
};

const TimesheetReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_TIMESHEETS:
            return { ...state, loading: true };
        case GET_TIMESHEETS_SUCCESS:
            return { ...state, timesheets: action.payload.data, total: action.payload.total, loading: false, error: null };
        case GET_TIMESHEETS_FAILED:
            return { ...state, error: action.payload, loading: false };

        case SUBMIT_TIMESHEET_FORM:
            return { ...state, error: null, submittingForm: true };
        case SUBMIT_TIMESHEET_FORM_SUCCESS:
            const alreadyExists = state.timesheets.find((timesheet) => timesheet.id === action.payload.id);
            let timesheets;
            let total = state.total;
            if (!alreadyExists) {
                timesheets = [action.payload, ...state.timesheets];
                total += 1;
            } else {
                timesheets = state.timesheets.map((timesheet) => {
                if (timesheet.id === action.payload.id) {
                  return action.payload;
                }
                return timesheet;
              });
            }
            return { ...state, total,  timesheets, submittingForm: false };
        case SUBMIT_TIMESHEET_FORM_FAILED:
            return { ...state, error: action.payload, submittingForm: false };

        case DELETE_TIMESHEET:
            return { ...state, error: null, deleting: true };
        case DELETE_TIMESHEET_SUCCESS:
            return { ...state, total: state.total - 1, timesheets: state.timesheets.filter(timesheet => timesheet.id !== action.payload) , deleting: false };
        case DELETE_TIMESHEET_FAILED:
            return { ...state, error: action.payload, deleting: false };

        case EXPORT_TIMESHEET:
            return { ...state, error: null, exporting: true };
        case EXPORT_TIMESHEET_SUCCESS:
            return { ...state, exporting: false };
        case EXPORT_TIMESHEET_FAILED:
            return { ...state, error: action.payload, exporting: false };
        
        default: return { ...state };
    }
}

export default TimesheetReducer;