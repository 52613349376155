import {
    GET_APPRAISAL_SHEETS,
    GET_APPRAISAL_SHEETS_SUCCESS,
    GET_APPRAISAL_SHEETS_FAILED,

    SUBMIT_APPRAISAL_SHEET_FORM,
    SUBMIT_APPRAISAL_SHEET_FORM_SUCCESS,
    SUBMIT_APPRAISAL_SHEET_FORM_FAILED,

    DELETE_APPRAISAL_SHEET,
    DELETE_APPRAISAL_SHEET_SUCCESS,
    DELETE_APPRAISAL_SHEET_FAILED,

    GET_CURRENT_APPRAISAL_SHEET,
    GET_CURRENT_APPRAISAL_SHEET_SUCCESS,
    GET_CURRENT_APPRAISAL_SHEET_FAILED,

    DOWNLOAD_APPRAISAL_SHEET,
    DOWNLOAD_APPRAISAL_SHEET_SUCCESS,
    DOWNLOAD_APPRAISAL_SHEET_FAILED
} from '../../constants/actionTypes'; 


/** GET ALL */
export const getAllAppraisalSheets = ({offset, limit, filter}) => ({
    type: GET_APPRAISAL_SHEETS,
    payload: { offset, limit, filter }
});

export const getAllAppraisalSheetsSuccess = ({total, data}) => ({
    type: GET_APPRAISAL_SHEETS_SUCCESS,
    payload: {total, data}
});

export const getAllAppraisalSheetsFailed = (error) => ({
    type: GET_APPRAISAL_SHEETS_FAILED,
    payload: error
});

/** SUBMIT FORM */
export const submitAppraisalSheetForm = (item) => ({
    type: SUBMIT_APPRAISAL_SHEET_FORM,
    payload: item
});

export const submitAppraisalSheetFormSuccess = (item) => ({
    type: SUBMIT_APPRAISAL_SHEET_FORM_SUCCESS,
    payload: item
});

export const submitAppraisalSheetFormFailed = (error) => ({
    type: SUBMIT_APPRAISAL_SHEET_FORM_FAILED,
    payload: error
});

/** DELETE FORM */
export const deleteAppraisalSheet = (id) => ({
    type: DELETE_APPRAISAL_SHEET,
    payload: id
});

export const deleteAppraisalSheetSuccess = (id) => ({
    type: DELETE_APPRAISAL_SHEET_SUCCESS,
    payload: id
});

export const deleteAppraisalSheetFailed = (error) => ({
    type: DELETE_APPRAISAL_SHEET_FAILED,
    payload: error
});

/** GET CURRENT */
export const getCurrentAppraisalSheet = (id) => ({
    type: GET_CURRENT_APPRAISAL_SHEET,
    payload: id
});

export const getCurrentAppraisalSheetSuccess = (appraisalSheet) => ({
    type: GET_CURRENT_APPRAISAL_SHEET_SUCCESS,
    payload: appraisalSheet
});

export const getCurrentAppraisalSheetFailed = (error) => ({
    type: GET_CURRENT_APPRAISAL_SHEET_FAILED,
    payload: error
});

/** DOWNLOAD */
export const downloadAppraisalSheet = (id) => ({
    type: DOWNLOAD_APPRAISAL_SHEET,
    payload: id
});

export const downloadAppraisalSheetSuccess = () => ({
    type: DOWNLOAD_APPRAISAL_SHEET_SUCCESS
});

export const downloadAppraisalSheetFailed = (error) => ({
    type: DOWNLOAD_APPRAISAL_SHEET_FAILED,
    payload: error
});