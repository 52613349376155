import {
    SUBMIT_CHANGE_PASSWORD_FORM,
    SUBMIT_CHANGE_PASSWORD_FORM_SUCCESS,
    SUBMIT_CHANGE_PASSWORD_FORM_FAILED,
} from '../../constants/actionTypes'; 


/** SUBMIT CHANGE PASSWORD FORM */
export const submitChangePasswordForm = (user) => ({
    type: SUBMIT_CHANGE_PASSWORD_FORM,
    payload: user
});

export const submitChangePasswordFormSuccess = (user) => ({
    type: SUBMIT_CHANGE_PASSWORD_FORM_SUCCESS,
    payload: user
});

export const submitChangePasswordFormFailed = (error) => ({
    type: SUBMIT_CHANGE_PASSWORD_FORM_FAILED,
    payload: error
});