import {
    GET_CREDIT_NOTES,
    GET_CREDIT_NOTES_SUCCESS,
    GET_CREDIT_NOTES_FAILED,

    SUBMIT_CREDIT_NOTE_FORM,
    SUBMIT_CREDIT_NOTE_FORM_SUCCESS,
    SUBMIT_CREDIT_NOTE_FORM_FAILED,

    DELETE_CREDIT_NOTE,
    DELETE_CREDIT_NOTE_SUCCESS,
    DELETE_CREDIT_NOTE_FAILED,

    VERIFY_CREDIT_NOTE_STUDENT,
    VERIFY_CREDIT_NOTE_STUDENT_SUCCESS,
    VERIFY_CREDIT_NOTE_STUDENT_FAILED,

    SUBMIT_CREDIT_NOTE_BANK_DETAILS_FORM,
    VERIFY_CREDIT_NOTE_BANK_DETAILS_SUCCESS,
    VERIFY_CREDIT_NOTE_BANK_DETAILS_FAILED,

    SEND_CREDIT_NOTE_EMAIL,
    SEND_CREDIT_NOTE_EMAIL_SUCCESS,
    SEND_CREDIT_NOTE_EMAIL_FAILED,

    EXPORT_CREDIT_NOTES,
    EXPORT_CREDIT_NOTES_SUCCESS,
    EXPORT_CREDIT_NOTES_FAILED,

    DOWNLOAD_CREDIT_NOTE,
    DOWNLOAD_CREDIT_NOTE_SUCCESS,
    DOWNLOAD_CREDIT_NOTE_FAILED,

    DOWNLOAD_SELECTED_CREDIT_NOTES,
    DOWNLOAD_SELECTED_CREDIT_NOTES_SUCCESS,
    DOWNLOAD_SELECTED_CREDIT_NOTE_FAILED,

    TOGGLE_ALL_CREDIT_NOTES,

    TOGGLE_CREDIT_NOTE,
} from '../../constants/actionTypes';


/** GET ALL */
export const getAllCreditNotes = ({offset, limit, filter}) => ({
    type: GET_CREDIT_NOTES,
    payload: { offset, limit, filter }
});

export const getAllCreditNotesSuccess = ({total, data}) => ({
    type: GET_CREDIT_NOTES_SUCCESS,
    payload: {total, data}
});

export const getAllCreditNotesFailed = (error) => ({
    type: GET_CREDIT_NOTES_FAILED,
    payload: error
});

/** SUBMIT FORM */
export const submitCreditNoteForm = (creditNote) => ({
    type: SUBMIT_CREDIT_NOTE_FORM,
    payload: creditNote
});

export const submitCreditNoteFormSuccess = (creditNote) => ({
    type: SUBMIT_CREDIT_NOTE_FORM_SUCCESS,
    payload: creditNote
});

export const submitCreditNoteFormFailed = (error) => ({
    type: SUBMIT_CREDIT_NOTE_FORM_FAILED,
    payload: error
});

/** DELETE FORM */
export const deleteCreditNote = (id) => ({
    type: DELETE_CREDIT_NOTE,
    payload: id
});

export const deleteCreditNoteSuccess = (id) => ({
    type: DELETE_CREDIT_NOTE_SUCCESS,
    payload: id
});

export const deleteCreditNoteFailed = (error) => ({
    type: DELETE_CREDIT_NOTE_FAILED,
    payload: error
});

/** VERIFY */
export const verifyCreditNoteStudent = (data) => ({
    type: VERIFY_CREDIT_NOTE_STUDENT,
    payload: data
});

export const verifyCreditNoteStudentSuccess = (creditNote) => ({
    type: VERIFY_CREDIT_NOTE_STUDENT_SUCCESS,
    payload: creditNote
});

export const verifyCreditNoteStudentFailed = (error) => ({
    type: VERIFY_CREDIT_NOTE_STUDENT_FAILED,
    payload: error
});

/** BANK DETAILS */
export const submitCreditNoteBankDetails = (data) => ({
    type: SUBMIT_CREDIT_NOTE_BANK_DETAILS_FORM,
    payload: data
});

export const submitCreditNoteBankDetailsSuccess = (creditNote) => ({
    type: VERIFY_CREDIT_NOTE_BANK_DETAILS_SUCCESS,
    payload: creditNote
});

export const submitCreditNoteBankDetailsFailed = (error) => ({
    type: VERIFY_CREDIT_NOTE_BANK_DETAILS_FAILED,
    payload: error
});

/** SEND EMAIL */
export const sendCreditNoteEmail = (id) => ({
    type: SEND_CREDIT_NOTE_EMAIL,
    payload: id
});

export const sendCreditNoteEmailSuccess = (data) => ({
    type: SEND_CREDIT_NOTE_EMAIL_SUCCESS,
    payload: data
});

export const sendCreditNoteEmailFailed = (error) => ({
    type: SEND_CREDIT_NOTE_EMAIL_FAILED,
    payload: error
});

/** EXPORT */
export const exportCreditNotes = (data) => ({
    type: EXPORT_CREDIT_NOTES,
    payload: data
});

export const exportCreditNotesSuccess = () => ({
    type: EXPORT_CREDIT_NOTES_SUCCESS
});

export const exportCreditNotesFailed = (error) => ({
    type: EXPORT_CREDIT_NOTES_FAILED,
    payload: error
});

/** DOWNLOAD */
export const downloadCreditNote = (id) => ({
    type: DOWNLOAD_CREDIT_NOTE,
    payload: id
});

export const downloadCreditNoteSuccess = () => ({
    type: DOWNLOAD_CREDIT_NOTE_SUCCESS
});

export const downloadCreditNoteFailed = (error) => ({
    type: DOWNLOAD_CREDIT_NOTE_FAILED,
    payload: error
});

/** DOWNLOAD SELECTED*/
export const downloadSelectedCreditNotes = (creditNotes) => ({
    type: DOWNLOAD_SELECTED_CREDIT_NOTES,
    payload: creditNotes
});

export const downloadSelectedCreditNotesSuccess = () => ({
    type: DOWNLOAD_SELECTED_CREDIT_NOTES_SUCCESS
});

export const downloadSelectedCreditNotesFailed = (error) => ({
    type: DOWNLOAD_SELECTED_CREDIT_NOTE_FAILED,
    payload: error
});

/** TOGGLE ALL */
export const toggleAllCreditNotes = (checked) => ({
    type: TOGGLE_ALL_CREDIT_NOTES,
    payload: checked
});

/** TOGGLE ONE */
export const toggleCreditNote = ({id, checked}) => ({
    type: TOGGLE_CREDIT_NOTE,
    payload: {id, checked}
});