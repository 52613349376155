import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { fetchJSON, apiRootEndpoint } from '../../helpers';
import {
    GET_EMPLOYEE_TRAININGS, 
    SUBMIT_EMPLOYEE_TRAINING_FORM,
    DELETE_EMPLOYEE_TRAINING,
    GET_CURRENT_EMPLOYEE_TRAINING
} from '../../constants/actionTypes';


import {
    getAllEmployeeTrainingsSuccess,
    getAllEmployeeTrainingsFailed,

    getCurrentEmployeeTrainingSuccess,
    getCurrentEmployeeTrainingFailed,

    submitEmployeeTrainingFormSuccess,
    submitEmployeeTrainingFormFailed,

    deleteEmployeeTrainingSuccess,
    deleteEmployeeTrainingFailed,
} from './actions';

const apiUrl = `${apiRootEndpoint}/api/v1/employee-trainings`;

function* getAllEmployeeTrainings({ payload: { offset, limit, filter } }) {
    const options = {
        method: 'GET',
    };

    try {
        let url = `${apiUrl}?offset=${offset}&limit=${limit}`;
        if(filter.userId) {
            url = `${url}&userId=${filter.userId}`;
        }
        const {total, data} = yield call(fetchJSON, url, options);
        yield put(getAllEmployeeTrainingsSuccess({total, data}));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(getAllEmployeeTrainingsFailed(message));
    }
}

function* submitEmployeeTraining({ payload: item }) {
    const options = {
        body: JSON.stringify(item),
        method: item.id ? 'PUT' :  'POST',
        headers: { 'Content-Type': 'application/json' }
    };

    try {
        let url = `${apiUrl}`;
        if(item.id) {
            url = `${apiUrl}/${item.id}`;
        }
        const response = yield call(fetchJSON, url, options);
        
        if(response.status === 'OK') {
            yield put(submitEmployeeTrainingFormSuccess(response.data));
        }
        else {
            yield put(submitEmployeeTrainingFormFailed(response.message));
        }
    } catch (error) {
        let message;
        
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(submitEmployeeTrainingFormFailed(message));
    }
}

function* deleteEmployeeTraining({ payload: id }) {
    const options = {
        method: 'DELETE',
    };
    try {
        const url = `${apiUrl}/${id}`;
        const response = yield call(fetchJSON, url, options);
        if(response.status === 'OK') {
            yield put(deleteEmployeeTrainingSuccess(id));
        }
        else {
            yield put(deleteEmployeeTrainingFailed(response.message));
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(deleteEmployeeTrainingFailed(message));
    }
}

function* getCurrentEmployeeTraining({ payload: id }) {
    const options = {
        method: 'GET',
    };
    try {
        const url = `${apiUrl}/${id}`;
        const response = yield call(fetchJSON, url, options);
        
        if(response.status === 'OK') {
            yield put(getCurrentEmployeeTrainingSuccess(response.data));
        }
        else {
            yield put(getCurrentEmployeeTrainingFailed(response.message));
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(getCurrentEmployeeTrainingFailed(message));
    }
}


export function* watchGetAllEmployeeTrainings() {
    yield takeEvery(GET_EMPLOYEE_TRAININGS, getAllEmployeeTrainings);
}
export function* watchSubmitEmployeeTrainingForm() {
    yield takeEvery(SUBMIT_EMPLOYEE_TRAINING_FORM, submitEmployeeTraining);
}
export function* watchDeleteEmployeeTraining() {
    yield takeEvery(DELETE_EMPLOYEE_TRAINING, deleteEmployeeTraining);
}
export function* watchGetCurrentEmployeeTraining() {
    yield takeEvery(GET_CURRENT_EMPLOYEE_TRAINING, getCurrentEmployeeTraining);
}

function* trainingAttendanceSaga() {
    yield all([
        fork(watchGetAllEmployeeTrainings),
        fork(watchSubmitEmployeeTrainingForm),
        fork(watchDeleteEmployeeTraining),
        fork(watchGetCurrentEmployeeTraining),
    ]);
}

export default trainingAttendanceSaga;