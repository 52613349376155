import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { fetchJSON, apiRootEndpoint } from '../../helpers';
import {
    GET_STUDENTS, 
    SUBMIT_STUDENT_FORM,
    DELETE_STUDENT,
    GET_CURRENT_STUDENT
} from '../../constants/actionTypes';


import {
    getAllStudentsSuccess,
    getAllStudentsFailed,

    submitStudentFormSuccess,
    submitStudentFormFailed,

    deleteStudentSuccess,
    deleteStudentFailed,

    getCurrentStudentSuccess,
    getCurrentStudentFailed
} from './actions';

const apiUrl = `${apiRootEndpoint}/api/v1/students`;

function* getAllStudents({ payload: { offset, limit, filter } }) {
    const options = {
        method: 'GET',
    };

    try {
        let url = `${apiUrl}?offset=${offset}&limit=${limit}`;
        if(filter.query) {
            url = `${url}&query=${filter.query}`
        }
        const {total, data} = yield call(fetchJSON, url, options);
        yield put(getAllStudentsSuccess({total, data}));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(getAllStudentsFailed(message));
    }
}

function* submitStudent({ payload: student }) {
    const options = {
        body: JSON.stringify(student),
        method: student.id ? 'PUT' :  'POST',
        headers: { 'Content-Type': 'application/json' }
    };

    try {
        let url = `${apiUrl}`;
        if(student.id) {
            url = `${apiUrl}/${student.id}`;
        }
        const response = yield call(fetchJSON, url, options);
        if(response.status === 'OK') {
            yield put(submitStudentFormSuccess(response.data));
        }
        else {
            yield put(submitStudentFormFailed(response.message));
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(submitStudentFormFailed(message));
    }
}

function* deleteStudent({ payload: id }) {
    const options = {
        method: 'DELETE',
    };
    try {
        const url = `${apiUrl}/${id}`;
        const response = yield call(fetchJSON, url, options);
        if(response.status === 'OK') {
            yield put(deleteStudentSuccess(id));
        }
        else {
            yield put(deleteStudentFailed(response.message));
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(deleteStudentFailed(message));
    }
}

function* getCurrentStudent({ payload: id }) {
    const options = {
        method: 'GET',
    };
    
    try {
        let url = `${apiUrl}/${id}`;
       
        const record = yield call(fetchJSON, url, options);
        yield put(getCurrentStudentSuccess(record.data));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(getCurrentStudentFailed(message));
    }
}




export function* watchGetAllStudents() {
    yield takeEvery(GET_STUDENTS, getAllStudents);
}
export function* watchSubmitStudentForm() {
    yield takeEvery(SUBMIT_STUDENT_FORM, submitStudent);
}
export function* watchDeleteStudent() {
    yield takeEvery(DELETE_STUDENT, deleteStudent);
}
export function* watchGetCurrentStudent() {
    yield takeEvery(GET_CURRENT_STUDENT, getCurrentStudent);
}

function* studentSaga() {
    yield all([
        fork(watchGetAllStudents),
        fork(watchSubmitStudentForm),
        fork(watchDeleteStudent),
        fork(watchGetCurrentStudent),
    ]);
}

export default studentSaga;