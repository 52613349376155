import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import cogoToast from 'cogo-toast';
import { fetchJSON, apiRootEndpoint } from '../../helpers';
import {
    GET_CREDIT_NOTES, 
    SUBMIT_CREDIT_NOTE_FORM,
    DELETE_CREDIT_NOTE,
    VERIFY_CREDIT_NOTE_STUDENT,
    SUBMIT_CREDIT_NOTE_BANK_DETAILS_FORM,
    SEND_CREDIT_NOTE_EMAIL,
    EXPORT_CREDIT_NOTES,
    DOWNLOAD_CREDIT_NOTE,
    DOWNLOAD_SELECTED_CREDIT_NOTES
} from '../../constants/actionTypes';


import {
    getAllCreditNotesSuccess,
    getAllCreditNotesFailed,

    submitCreditNoteFormSuccess,
    submitCreditNoteFormFailed,

    deleteCreditNoteSuccess,
    deleteCreditNoteFailed,

    verifyCreditNoteStudentSuccess,
    verifyCreditNoteStudentFailed,

    submitCreditNoteBankDetailsSuccess,
    submitCreditNoteBankDetailsFailed,

    sendCreditNoteEmailSuccess,
    sendCreditNoteEmailFailed,

    exportCreditNotesSuccess,
    exportCreditNotesFailed,

    downloadCreditNoteSuccess,
    downloadCreditNoteFailed,

    downloadSelectedCreditNotesSuccess,
    downloadSelectedCreditNotesFailed
} from './actions';

const apiUrl = `${apiRootEndpoint}/api/v1/credit-notes`;

function* getAllCreditNotes({ payload: { offset, limit, filter } }) {
    const options = {
        method: 'GET',
    };

    try {
        let url = `${apiUrl}?offset=${offset}&limit=${limit}`;
        if(filter.query) {
            url = `${url}&query=${filter.query}`;
        }
        if(filter.isApproved) {
            url = `${url}&isApproved=${filter.isApproved}`;
        }
        if(filter.isPaid) {
            url = `${url}&isPaid=${filter.isPaid}`;
        }
        if(filter.intakeMonth) {
            url = `${url}&intakeMonth=${filter.intakeMonth}`;
        }
        if(filter.intakeYear) {
            url = `${url}&intakeYear=${filter.intakeYear}`;
        }
        if(filter.branchId) {
            url = `${url}&branchId=${filter.branchId}`;
        }
        if(filter.instituteId) {
            url = `${url}&instituteId=${filter.instituteId}`;
        }
        if(filter.isApproved === false && filter.isPaid === false) {
            url = `${url}&isApproved=${filter.isApproved}&isPaid=${filter.isPaid}`;
        }
        if(filter.isArchived) {
            url = `${url}&isArchived=${filter.isArchived}`;
        }
        if(filter.noBankDetails) {
            url = `${url}&noBankDetails=${filter.noBankDetails}`;
        }
        const {total, data, message} = yield call(fetchJSON, url, options);
        if(!message) {
            yield put(getAllCreditNotesSuccess({total, data}));
        }
        else {
            yield put(getAllCreditNotesFailed(message));
            cogoToast.error(message);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(getAllCreditNotesFailed(message));
        cogoToast.error(message);
    }
}

function* submitCreditNote({ payload: creditNote }) {
    const options = {
        body: JSON.stringify(creditNote),
        method: creditNote.id ? 'PUT' :  'POST',
        headers: { 'Content-Type': 'application/json' }
    };

    try {
        let url = `${apiUrl}`;
        if(creditNote.id) {
            url = `${apiUrl}/${creditNote.id}`;
        }
        const response = yield call(fetchJSON, url, options);
        if(response.status === 'OK') {
            yield put(submitCreditNoteFormSuccess(response.data));
        }
        else {
            yield put(submitCreditNoteFormFailed(response.message));
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(submitCreditNoteFormFailed(message));
    }
}

function* deleteCreditNote({ payload: id }) {
    const options = {
        method: 'DELETE',
    };
    try {
        const url = `${apiUrl}/${id}`;
        const response = yield call(fetchJSON, url, options);
        if(response.status === 'OK') {
            yield put(deleteCreditNoteSuccess(id));
        }
        else {
            yield put(deleteCreditNoteFailed(response.message));
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(deleteCreditNoteFailed(message));
    }
}

function* verifyCreditNoteStudent({ payload: data }) {
    const options = {
        body: JSON.stringify(data),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' }
    };
    try {
        const url = `${apiUrl}/${data.id}/verify`;
        const response = yield call(fetchJSON, url, options);
        if(response.status === 'OK') {
            yield put(verifyCreditNoteStudentSuccess(response.data));
            cogoToast.success('We have verified your Student ID successfully!');
        }
        else {
            yield put(verifyCreditNoteStudentFailed(response.message));
            cogoToast.error(response.message);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(verifyCreditNoteStudentFailed(message));
        cogoToast.error(message);
    }
}


function* submitCreditNoteBankDetails({ payload: creditNote }) {
    const options = {
        body: JSON.stringify(creditNote),
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' }
    };

    try {
        let url = `${apiUrl}`;
        if(creditNote.id) {
            url = `${apiUrl}/${creditNote.id}/bank-details`;
        }
        const response = yield call(fetchJSON, url, options);
        if(response.status === 'OK') {
            yield put(submitCreditNoteBankDetailsSuccess(response.data));
            cogoToast.success('Submitted your details successfully!');
        }
        else {
            yield put(submitCreditNoteBankDetailsFailed(response.message));
            cogoToast.error(response.message);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(submitCreditNoteBankDetailsFailed(message));
        cogoToast.error(message);
    }
}

function* sendCreditNoteEmail({ payload: id }) {
    const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' }
    };

    try {
        let url = `${apiUrl}`;
        if(id) {
            url = `${apiUrl}/${id}/send-email`;
        }
        const response = yield call(fetchJSON, url, options);
        if(response.status === 'OK') {
            yield put(sendCreditNoteEmailSuccess(response.data));
            cogoToast.success('Email sent successfully!');
        }
        else {
            yield put(sendCreditNoteEmailFailed(response.message));
            cogoToast.error(response.message);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(sendCreditNoteEmailFailed(message));
        cogoToast.error(message);
    }
}

function* exportCreditNotes({ payload: data }) {
    const options = {
        body: JSON.stringify(data),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' }
    };
    try {
        const url = `${apiUrl}/export`;
        const response = yield call(fetchJSON, url, options);
        
        if(response.status === "OK") {
            if(response.url) {
                window.open(response.url);
            }
            yield put(exportCreditNotesSuccess());
        }
        else {
            yield put(exportCreditNotesFailed(response.message));
        }
    } catch (error) {
       
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(exportCreditNotesFailed(message));
    }
}

function* downloadCreditNote({ payload: id }) {
    const options = {
        method: 'GET',
    };
    try {
        const url = `${apiUrl}/${id}/download`;
        const response = yield call(fetchJSON, url, options);
        
        if(response.status === "OK") {
            if(response.url) {
                window.open(response.url);
            }
            yield put(downloadCreditNoteSuccess());
        }
        else {
            yield put(downloadCreditNoteFailed(response.message));
        }
    } catch (error) {
       
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(downloadCreditNoteFailed(message));
    }
}

function* downloadSelectedCreditNotes({ payload: creditNotes }) {
    const options = {
        body: JSON.stringify(creditNotes),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' }
    };
    try {
        const url = `${apiUrl}/download`;
        const response = yield call(fetchJSON, url, options);
        
        if(response.status === "OK") {
            if(response.url) {
                window.open(response.url);
            }
            yield put(downloadSelectedCreditNotesSuccess());
        }
        else {
            cogoToast.error(response.message);
            yield put(downloadSelectedCreditNotesFailed(response.message));
        }
    } catch (error) {
       
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(downloadSelectedCreditNotesFailed(message));
    }
}


export function* watchGetAllCreditNotes() {
    yield takeEvery(GET_CREDIT_NOTES, getAllCreditNotes);
}
export function* watchSubmitCreditNoteForm() {
    yield takeEvery(SUBMIT_CREDIT_NOTE_FORM, submitCreditNote);
}
export function* watchDeleteCreditNote() {
    yield takeEvery(DELETE_CREDIT_NOTE, deleteCreditNote);
}
export function* watchVerifyCreditNoteStudent() {
    yield takeEvery(VERIFY_CREDIT_NOTE_STUDENT, verifyCreditNoteStudent);
}
export function* watchSubmitCreditNoteBankDetailsForm() {
    yield takeEvery(SUBMIT_CREDIT_NOTE_BANK_DETAILS_FORM, submitCreditNoteBankDetails);
}
export function* watchSendCreditNoteEmail() {
    yield takeEvery(SEND_CREDIT_NOTE_EMAIL, sendCreditNoteEmail);
}
export function* watchExportCreditNotes() {
    yield takeEvery(EXPORT_CREDIT_NOTES, exportCreditNotes);
}
export function* watchDownloadCreditNote() {
    yield takeEvery(DOWNLOAD_CREDIT_NOTE, downloadCreditNote);
}
export function* watchDownloadSelectedCreditNotes() {
    yield takeEvery(DOWNLOAD_SELECTED_CREDIT_NOTES, downloadSelectedCreditNotes);
}


function* creditNoteSaga() {
    yield all([
        fork(watchGetAllCreditNotes),
        fork(watchSubmitCreditNoteForm),
        fork(watchDeleteCreditNote),
        fork(watchVerifyCreditNoteStudent),
        fork(watchSubmitCreditNoteBankDetailsForm),
        fork(watchSendCreditNoteEmail),
        fork(watchExportCreditNotes),
        fork(watchDownloadCreditNote),
        fork(watchDownloadSelectedCreditNotes),
    ]);
}

export default creditNoteSaga;