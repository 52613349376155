import {
    SUBMIT_OCR_FORM,
    SUBMIT_OCR_FORM_SUCCESS,
    SUBMIT_OCR_FORM_FAILED,

    SUBMIT_CRM_CLIENT_FORM,
    SUBMIT_CRM_CLIENT_FORM_SUCCESS,
    SUBMIT_CRM_CLIENT_FORM_FAILED
} from '../../constants/actionTypes';

const INIT_STATE = {
    loading: false,
    data: [],
    submittingForm: false,
    error: null,
    client: null
};

const BranchReducer = (state = INIT_STATE, action) => {
    switch (action.type) {

        case SUBMIT_OCR_FORM:
            return { ...state, error: null, data:null, fileUrl: null, type: null, submittingForm: true };
        case SUBMIT_OCR_FORM_SUCCESS:
            return { ...state,  data: action.payload.ocrData, type: action.payload.type, fileUrl: action.payload.fileUrl, profileUrl: action.payload.profileUrl, submittingForm: false };
        case SUBMIT_OCR_FORM_FAILED:
            return { ...state, error: action.payload, submittingForm: false };

        case SUBMIT_CRM_CLIENT_FORM:
            return { ...state, error: null, submittingForm: true };
        case SUBMIT_CRM_CLIENT_FORM_SUCCESS:
            return { ...state,  client: action.payload.data, submittingForm: false };
        case SUBMIT_CRM_CLIENT_FORM_FAILED:
            return { ...state, error: action.payload, submittingForm: false };

        default: return { ...state };
    }
}

export default BranchReducer;