import {
    GET_TRAINING_PROGRAMS,
    GET_TRAINING_PROGRAMS_SUCCESS,
    GET_TRAINING_PROGRAMS_FAILED,

    SUBMIT_TRAINING_PROGRAM_FORM,
    SUBMIT_TRAINING_PROGRAM_FORM_SUCCESS,
    SUBMIT_TRAINING_PROGRAM_FORM_FAILED,

    DELETE_TRAINING_PROGRAM,
    DELETE_TRAINING_PROGRAM_SUCCESS,
    DELETE_TRAINING_PROGRAM_FAILED,
} from '../../constants/actionTypes';

const INIT_STATE = {
    loading: false,
    trainingPrograms: [],
    submittingForm: false,
    error: null,
    deleting: false
};

const TrainingProgramReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_TRAINING_PROGRAMS:
            return { ...state, loading: true };
        case GET_TRAINING_PROGRAMS_SUCCESS:
            return { ...state, trainingPrograms: action.payload.data, total: action.payload.total, loading: false, error: null };
        case GET_TRAINING_PROGRAMS_FAILED:
            return { ...state, error: action.payload, loading: false };

        case SUBMIT_TRAINING_PROGRAM_FORM:
            return { ...state, error: null, submittingForm: true };
        case SUBMIT_TRAINING_PROGRAM_FORM_SUCCESS:
            const alreadyExists = state.trainingPrograms.find((item) => item.id === action.payload.id);
            let trainingPrograms;
            let total = state.total;
            if (!alreadyExists) {
                trainingPrograms = [action.payload, ...state.trainingPrograms];
                total += 1;
            } else {
                trainingPrograms = state.trainingPrograms.map((item) => {
                if (item.id === action.payload.id) {
                  return action.payload;
                }
                return item;
              });
            }
            return { ...state, total,  trainingPrograms, submittingForm: false };
        case SUBMIT_TRAINING_PROGRAM_FORM_FAILED:
            return { ...state, error: action.payload, submittingForm: false };

        case DELETE_TRAINING_PROGRAM:
            return { ...state, error: null, deleting: true };
        case DELETE_TRAINING_PROGRAM_SUCCESS:
            return { ...state, total: state.total - 1, trainingPrograms: state.trainingPrograms.filter(item => item.id !== action.payload) , deleting: false };
        case DELETE_TRAINING_PROGRAM_FAILED:
            return { ...state, error: action.payload, deleting: false };
        default: return { ...state };
    }
}

export default TrainingProgramReducer;