import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { fetchJSON, apiRootEndpoint } from '../../helpers';
import {
    GET_TRAINING_PROVIDERS, 
    SUBMIT_TRAINING_PROVIDER_FORM,
    DELETE_TRAINING_PROVIDER
} from '../../constants/actionTypes';


import {
    getAllTrainingProvidersSuccess,
    getAllTrainingProvidersFailed,

    submitTrainingProviderFormSuccess,
    submitTrainingProviderFormFailed,

    deleteTrainingProviderSuccess,
    deleteTrainingProviderFailed,
} from './actions';

const apiUrl = `${apiRootEndpoint}/api/v1/training-providers`;

function* getAllTrainingProviders({ payload: { offset, limit } }) {
    const options = {
        method: 'GET',
    };

    try {
        const url = `${apiUrl}?offset=${offset}&limit=${limit}`;
        const {total, data} = yield call(fetchJSON, url, options);
        yield put(getAllTrainingProvidersSuccess({total, data}));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(getAllTrainingProvidersFailed(message));
    }
}

function* submitTrainingProvider({ payload: item }) {
    const options = {
        body: JSON.stringify(item),
        method: item.id ? 'PUT' :  'POST',
        headers: { 'Content-Type': 'application/json' }
    };

    try {
        let url = `${apiUrl}`;
        if(item.id) {
            url = `${apiUrl}/${item.id}`;
        }
        const response = yield call(fetchJSON, url, options);
        if(response.status === 'OK') {
            yield put(submitTrainingProviderFormSuccess(response.data));
        }
        else {
            yield put(submitTrainingProviderFormFailed(response.message));
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(submitTrainingProviderFormFailed(message));
    }
}

function* deleteTrainingProvider({ payload: id }) {
    const options = {
        method: 'DELETE',
    };
    try {
        const url = `${apiUrl}/${id}`;
        const response = yield call(fetchJSON, url, options);
        if(response.status === 'OK') {
            yield put(deleteTrainingProviderSuccess(id));
        }
        else {
            yield put(deleteTrainingProviderFailed(response.message));
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(deleteTrainingProviderFailed(message));
    }
}


export function* watchGetAllTrainingProviders() {
    yield takeEvery(GET_TRAINING_PROVIDERS, getAllTrainingProviders);
}
export function* watchSubmitTrainingProviderForm() {
    yield takeEvery(SUBMIT_TRAINING_PROVIDER_FORM, submitTrainingProvider);
}
export function* watchDeleteBTrainingProvider() {
    yield takeEvery(DELETE_TRAINING_PROVIDER, deleteTrainingProvider);
}

function* trainingProviderSaga() {
    yield all([
        fork(watchGetAllTrainingProviders),
        fork(watchSubmitTrainingProviderForm),
        fork(watchDeleteBTrainingProvider),
    ]);
}

export default trainingProviderSaga;