import {
    GET_APPRAISALS,
    GET_APPRAISALS_SUCCESS,
    GET_APPRAISALS_FAILED,

    SUBMIT_APPRAISAL_FORM,
    SUBMIT_APPRAISAL_FORM_SUCCESS,
    SUBMIT_APPRAISAL_FORM_FAILED,

    DELETE_APPRAISAL,
    DELETE_APPRAISAL_SUCCESS,
    DELETE_APPRAISAL_FAILED,

    GET_CURRENT_APPRAISAL,
    GET_CURRENT_APPRAISAL_SUCCESS,
    GET_CURRENT_APPRAISAL_FAILED,

    DOWNLOAD_APPRAISAL,
    DOWNLOAD_APPRAISAL_SUCCESS,
    DOWNLOAD_APPRAISAL_FAILED
} from '../../constants/actionTypes'; 


/** GET ALL */
export const getAllAppraisals = ({offset, limit, filter}) => ({
    type: GET_APPRAISALS,
    payload: { offset, limit, filter }
});

export const getAllAppraisalsSuccess = ({total, data}) => ({
    type: GET_APPRAISALS_SUCCESS,
    payload: {total, data}
});

export const getAllAppraisalsFailed = (error) => ({
    type: GET_APPRAISALS_FAILED,
    payload: error
});

/** SUBMIT FORM */
export const submitAppraisalForm = (item) => ({
    type: SUBMIT_APPRAISAL_FORM,
    payload: item
});

export const submitAppraisalFormSuccess = (item) => ({
    type: SUBMIT_APPRAISAL_FORM_SUCCESS,
    payload: item
});

export const submitAppraisalFormFailed = (error) => ({
    type: SUBMIT_APPRAISAL_FORM_FAILED,
    payload: error
});

/** DELETE FORM */
export const deleteAppraisal = (id) => ({
    type: DELETE_APPRAISAL,
    payload: id
});

export const deleteAppraisalSuccess = (id) => ({
    type: DELETE_APPRAISAL_SUCCESS,
    payload: id
});

export const deleteAppraisalFailed = (error) => ({
    type: DELETE_APPRAISAL_FAILED,
    payload: error
});

/** GET CURRENT */
export const getCurrentAppraisal = (id) => ({
    type: GET_CURRENT_APPRAISAL,
    payload: id
});

export const getCurrentAppraisalSuccess = (appraisal) => ({
    type: GET_CURRENT_APPRAISAL_SUCCESS,
    payload: appraisal
});

export const getCurrentAppraisalFailed = (error) => ({
    type: GET_CURRENT_APPRAISAL_FAILED,
    payload: error
});

/** DOWNLOAD */
export const downloadAppraisal = ({id, type}) => ({
    type: DOWNLOAD_APPRAISAL,
    payload: {id, type}
});

export const downloadAppraisalSuccess = () => ({
    type: DOWNLOAD_APPRAISAL_SUCCESS
});

export const downloadAppraisalFailed = (error) => ({
    type: DOWNLOAD_APPRAISAL_FAILED,
    payload: error
});