import {
    GET_EMPLOYEES,
    GET_EMPLOYEES_SUCCESS,
    GET_EMPLOYEES_FAILED,

    GET_CURRENT_EMPLOYEE,
    GET_CURRENT_EMPLOYEE_SUCCESS,
    GET_CURRENT_EMPLOYEE_FAILED
} from '../../constants/actionTypes'; 


/** GET ALL */
export const getAllEmployees = ({offset, limit, filter}) => ({
    type: GET_EMPLOYEES,
    payload: { offset, limit, filter }
});

export const getAllEmployeesSuccess = ({total, data}) => ({
    type: GET_EMPLOYEES_SUCCESS,
    payload: {total, data}
});

export const getAllEmployeesFailed = (error) => ({
    type: GET_EMPLOYEES_FAILED,
    payload: error
});


/** GET CURRENT */
export const getCurrentEmployee = (id) => ({
    type: GET_CURRENT_EMPLOYEE,
    payload: id
});

export const getCurrentEmployeeSuccess = (employee) => ({
    type: GET_CURRENT_EMPLOYEE_SUCCESS,
    payload: employee
});

export const getCurrentEmployeeFailed = (error) => ({
    type: GET_CURRENT_EMPLOYEE_FAILED,
    payload: error
});