import {
    GET_TASKS,
    GET_TASKS_SUCCESS,
    GET_TASKS_FAILED,

    SUBMIT_TASK_FORM,
    SUBMIT_TASK_FORM_SUCCESS,
    SUBMIT_TASK_FORM_FAILED,

    DELETE_TASK,
    DELETE_TASK_SUCCESS,
    DELETE_TASK_FAILED,
} from '../../constants/actionTypes';

const INIT_STATE = {
    loading: false,
    tasks: [],
    submittingForm: false,
    error: null,
    deleting: false
};

const TaskReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_TASKS:
            return { ...state, loading: true };
        case GET_TASKS_SUCCESS:
            return { ...state, tasks: action.payload.data, total: action.payload.total, loading: false, error: null };
        case GET_TASKS_FAILED:
            return { ...state, error: action.payload, loading: false };

        case SUBMIT_TASK_FORM:
            return { ...state, error: null, submittingForm: true };
        case SUBMIT_TASK_FORM_SUCCESS:
            const alreadyExists = state.tasks.find((task) => task.id === action.payload.id);
            let tasks;
            let total = state.total;
            if (!alreadyExists) {
                tasks = [action.payload, ...state.tasks];
                total += 1;
            } else {
                tasks = state.tasks.map((task) => {
                if (task.id === action.payload.id) {
                  return action.payload;
                }
                return task;
              });
            }
            return { ...state, total,  tasks, submittingForm: false };
        case SUBMIT_TASK_FORM_FAILED:
            return { ...state, error: action.payload, submittingForm: false };

        case DELETE_TASK:
            return { ...state, error: null, deleting: true };
        case DELETE_TASK_SUCCESS:
            return { ...state, total: state.total - 1, tasks: state.tasks.filter(task => task.id !== action.payload) , deleting: false };
        case DELETE_TASK_FAILED:
            return { ...state, error: action.payload, deleting: false };
        default: return { ...state };
    }
}

export default TaskReducer;