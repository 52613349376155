import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { fetchJSON, apiRootEndpoint } from '../../helpers';
import {
    GET_EMPLOYMENT_CONTRACTS, 
    SUBMIT_EMPLOYMENT_CONTRACT_FORM,
    DELETE_EMPLOYMENT_CONTRACT,
    GET_CURRENT_EMPLOYMENT_CONTRACT
} from '../../constants/actionTypes';


import {
    getAllEmploymentContractsSuccess,
    getAllEmploymentContractsFailed,

    submitEmploymentContractFormSuccess,
    submitEmploymentContractFormFailed,

    deleteEmploymentContractSuccess,
    deleteEmploymentContractFailed,

    getCurrentEmploymentContractSuccess,
    getCurrentEmploymentContractFailed
} from './actions';

const apiUrl = `${apiRootEndpoint}/api/v1/employment-contracts`;

function* getAllEmploymentContracts({ payload: { offset, limit, filter } }) {
    const options = {
        method: 'GET',
    };

    try {
        let url = `${apiUrl}?offset=${offset}&limit=${limit}`;
        if(filter.query) {
            url = `${url}&query=${filter.query}`
        }
        const {total, data} = yield call(fetchJSON, url, options);
        if(data && data.length) {
            yield put(getAllEmploymentContractsSuccess({total, data}));
        }
        else {
            yield put(getAllEmploymentContractsFailed('No data found'));
        }
    } catch (error) {
        
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(getAllEmploymentContractsFailed(message));
        
    }
}

function* submitEmploymentContract({ payload: item }) {
    const options = {
        body: JSON.stringify(item),
        method: item.id ? 'PUT' :  'POST',
        headers: { 'Content-Type': 'application/json' }
    };

    try {
        let url = `${apiUrl}`;
        if(item.id) {
            url = `${apiUrl}/${item.id}`;
        }
        const response = yield call(fetchJSON, url, options);
        if(response.status === 'OK') {
            yield put(submitEmploymentContractFormSuccess(response.data));
        }
        else {
            yield put(submitEmploymentContractFormFailed(response.message));
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(submitEmploymentContractFormFailed(message));
        
    }
}

function* deleteEmploymentContract({ payload: id }) {
    const options = {
        method: 'DELETE',
    };
    try {
        const url = `${apiUrl}/${id}`;
        const response = yield call(fetchJSON, url, options);
        
        if(response.status === "OK") {
            yield put(deleteEmploymentContractSuccess(id));
        }
        else {
            yield put(deleteEmploymentContractFailed(response.message));
        }
    } catch (error) {
       
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(deleteEmploymentContractFailed(message));
    }
}



function* getCurrentEmploymentContract({ payload: id }) {
    const options = {
        method: 'GET',
    };
    
    try {
        let url = `${apiUrl}/${id}`;
       
        const result = yield call(fetchJSON, url, options);
        yield put(getCurrentEmploymentContractSuccess(result.data));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(getCurrentEmploymentContractFailed(message));
    }
}



export function* watchGetAllAEmploymentContracts() {
    yield takeEvery(GET_EMPLOYMENT_CONTRACTS, getAllEmploymentContracts);
}
export function* watchSubmitEmploymentContractForm() {
    yield takeEvery(SUBMIT_EMPLOYMENT_CONTRACT_FORM, submitEmploymentContract);
}
export function* watchDeleteEmploymentContract() {
    yield takeEvery(DELETE_EMPLOYMENT_CONTRACT, deleteEmploymentContract);
}
export function* watchGetCurrentEmploymentContract() {
    yield takeEvery(GET_CURRENT_EMPLOYMENT_CONTRACT, getCurrentEmploymentContract);
}

function* employmentContractSaga() {
    yield all([
        fork(watchGetAllAEmploymentContracts),
        fork(watchSubmitEmploymentContractForm),
        fork(watchDeleteEmploymentContract),
        fork(watchGetCurrentEmploymentContract),
    ]);
}

export default employmentContractSaga;