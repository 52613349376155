import {
    GET_TIMESHEETS,
    GET_TIMESHEETS_SUCCESS,
    GET_TIMESHEETS_FAILED,

    SUBMIT_TIMESHEET_FORM,
    SUBMIT_TIMESHEET_FORM_SUCCESS,
    SUBMIT_TIMESHEET_FORM_FAILED,

    DELETE_TIMESHEET,
    DELETE_TIMESHEET_SUCCESS,
    DELETE_TIMESHEET_FAILED,

    EXPORT_TIMESHEET,
    EXPORT_TIMESHEET_SUCCESS,
    EXPORT_TIMESHEET_FAILED,
} from '../../constants/actionTypes'; 


/** GET ALL */
export const getAllTimesheets = ({offset, limit, filter}) => ({
    type: GET_TIMESHEETS,
    payload: { offset, limit, filter }
});

export const getAllTimesheetsSuccess = ({total, data}) => ({
    type: GET_TIMESHEETS_SUCCESS,
    payload: {total, data}
});

export const getAllTimesheetsFailed = (error) => ({
    type: GET_TIMESHEETS_FAILED,
    payload: error
});

/** SUBMIT FORM */
export const submitTimesheetForm = (timesheet) => ({
    type: SUBMIT_TIMESHEET_FORM,
    payload: timesheet
});

export const submitTimesheetFormSuccess = (user) => ({
    type: SUBMIT_TIMESHEET_FORM_SUCCESS,
    payload: user
});

export const submitTimesheetFormFailed = (error) => ({
    type: SUBMIT_TIMESHEET_FORM_FAILED,
    payload: error
});

/** DELETE FORM */
export const deleteTimesheet = (id) => ({
    type: DELETE_TIMESHEET,
    payload: id
});

export const deleteTimesheetSuccess = (id) => ({
    type: DELETE_TIMESHEET_SUCCESS,
    payload: id
});

export const deleteTimesheetFailed = (error) => ({
    type: DELETE_TIMESHEET_FAILED,
    payload: error
});

/** EXPORT */
export const exportTimesheet = (data) => ({
    type: EXPORT_TIMESHEET,
    payload: data
});

export const exportTimesheetSuccess = () => ({
    type: EXPORT_TIMESHEET_SUCCESS
});

export const exportTimesheetFailed = (error) => ({
    type: EXPORT_TIMESHEET_FAILED,
    payload: error
});