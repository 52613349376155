import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { fetchJSON, apiRootEndpoint } from '../../helpers';
import {
    GET_USERS, 
    SUBMIT_USER_FORM,
    DELETE_USER,
    GET_CURRENT_USER,
} from '../../constants/actionTypes';


import {
    getAllUsersSuccess,
    getAllUsersFailed,

    submitUserFormSuccess,
    submitUserFormFailed,

    deleteUserSuccess,
    deleteUserFailed,

    getCurrentUserSuccess,
    getCurrentUserFailed
} from './actions';

const apiUrl = `${apiRootEndpoint}/api/v1/users`;

function* getAllUsers({ payload: { offset, limit, filter } }) {
    const options = {
        method: 'GET',
    };

    try {
        let url = `${apiUrl}?offset=${offset}&limit=${limit}`;
        if(filter.query) {
            url = `${url}&query=${filter.query}`
        }
        if(filter.branchId) {
            url = `${url}&branchId=${filter.branchId}`
        }
        const {total, data} = yield call(fetchJSON, url, options);
        yield put(getAllUsersSuccess({total, data}));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(getAllUsersFailed(message));
    }
}

const updateCurrentUser = (user) => {
    const currentUser = localStorage.getItem("user");
    const currentUserObject =  currentUser ? (typeof(currentUser) == 'object'? currentUser: JSON.parse(currentUser)) : null;
    if(currentUserObject.id === user.id) {
        localStorage.setItem("user", JSON.stringify(
            {
                ...currentUserObject, 
                firstName: user.firstName,
                lastName: user.lastName,
                email: user.email,
                phone: user.phone,
                nickName: user.nickName || '',
                extension: user.extension || ''
            }), { path: "/" });
        
        window.location.reload();
        
    }
}

function* submitUser({ payload: user }) {
    const options = {
        body: JSON.stringify(user),
        method: user.id ? 'PUT' :  'POST',
        headers: { 'Content-Type': 'application/json' }
    };

    try {
        let url = `${apiUrl}`;
        if(user.id) {
            url = `${apiUrl}/${user.id}`;
        }
        const response = yield call(fetchJSON, url, options);
        updateCurrentUser(response.data);
        if(response.status === 'OK') {
            yield put(submitUserFormSuccess(response.data));
        }
        else {
            yield put(submitUserFormFailed(response.message));
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(submitUserFormFailed(message));
    }
}

function* deleteUser({ payload: id }) {
    const options = {
        method: 'DELETE',
    };
    try {
        const url = `${apiUrl}/${id}`;
        const response = yield call(fetchJSON, url, options);
        if(response.status === 'OK') {
            yield put(deleteUserSuccess(id));
        }
        else {
            yield put(deleteUserFailed(response.message));
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(deleteUserFailed(message));
    }
}

function* getCurrentUser({ payload: id }) {
    const options = {
        method: 'GET',
    };
    
    try {
        let url = `${apiUrl}/${id}`;
       
        const user = yield call(fetchJSON, url, options);
        yield put(getCurrentUserSuccess(user.data));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(getCurrentUserFailed(message));
    }
}


export function* watchGetAllUsers() {
    yield takeEvery(GET_USERS, getAllUsers);
}
export function* watchSubmitUserForm() {
    yield takeEvery(SUBMIT_USER_FORM, submitUser);
}
export function* watchDeleteUser() {
    yield takeEvery(DELETE_USER, deleteUser);
}
export function* watchGetCurrentUser() {
    yield takeEvery(GET_CURRENT_USER, getCurrentUser);
}

function* userSaga() {
    yield all([
        fork(watchGetAllUsers),
        fork(watchSubmitUserForm),
        fork(watchDeleteUser),
        fork(watchGetCurrentUser),
    ]);
}

export default userSaga;