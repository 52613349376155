import {
    GET_COUNTRY,
    GET_COUNTRY_SUCCESS,
    GET_COUNTRY_FAILED,

    SUBMIT_COUNTRY_FORM,
    SUBMIT_COUNTRY_FORM_SUCCESS,
    SUBMIT_COUNTRY_FORM_FAILED,

    DELETE_COUNTRY,
    DELETE_COUNTRY_SUCCESS,
    DELETE_COUNTRY_FAILED,
} from '../../constants/actionTypes';


/** GET ALL */
export const getAllCountries = ({offset, limit}) => ({
    type: GET_COUNTRY,
    payload: { offset, limit }
});

export const getAllCountriesSuccess = ({total, data}) => ({
    type: GET_COUNTRY_SUCCESS,
    payload: {total, data}
});

export const getAllCountriesFailed = (error) => ({
    type: GET_COUNTRY_FAILED,
    payload: error
});

/** SUBMIT FORM */
export const submitCountryForm = (country) => ({
    type: SUBMIT_COUNTRY_FORM,
    payload: country
});

export const submitCountryFormSuccess = (country) => ({
    type: SUBMIT_COUNTRY_FORM_SUCCESS,
    payload: country
});

export const submitCountryFormFailed = (error) => ({
    type: SUBMIT_COUNTRY_FORM_FAILED,
    payload: error
});

/** DELETE FORM */
export const deleteCountry = (id) => ({
    type: DELETE_COUNTRY,
    payload: id
});

export const deleteCountrySuccess = (id) => ({
    type: DELETE_COUNTRY_SUCCESS,
    payload: id
});

export const deleteCountryFailed = (error) => ({
    type: DELETE_COUNTRY_FAILED,
    payload: error
});