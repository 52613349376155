import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { fetchJSON, apiRootEndpoint } from '../../helpers';

import {
    LOGIN_USER,
    LOGOUT_USER,
    REGISTER_USER,
    FORGET_PASSWORD,
    RESET_PASSWORD
} from '../../constants/actionTypes';


import {
    loginUserSuccess,
    loginUserFailed,

    registerUserSuccess,
    registerUserFailed,

    forgetPasswordSuccess,
    forgetPasswordFailed,

    resetPasswordSuccess,
    resetPasswordFailed
} from './actions';

const apiUrl = `${apiRootEndpoint}/api/v1/auth`;


/**
 * Sets the session
 * @param {*} user 
 */
const setSession = (user) => {
    
    if (user)
        localStorage.setItem("user", JSON.stringify(user), { path: "/" });
    else
        localStorage.removeItem("user");
};

function* login({ payload: { email, password } }) {
    const options = {
        body: JSON.stringify({ email, password }),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' }
    };

    try {
        const response = yield call(fetchJSON, `${apiUrl}/login`, options);
        if(response.message) {
            yield put(loginUserFailed(response.message));
            setSession(null);
        }
        else {
            setSession(response);
            yield put(loginUserSuccess(response));
        }
        
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            case 401: message = 'Invalid credentials'; break;
            default: message = error;
        }
        yield put(loginUserFailed(message));
        setSession(null);
    }
}


/**
 * Logout the user
 * @param {*} param0 
 */
function* logout({ payload: { history } }) {
    try {
        setSession(null);
        yield call(() => {
            history.push("/login");
        });
    } catch (error) { }
}

/**
 * Register the user
 */
function* register({ payload: { fullname, email, password } }) {
    const options = {
        body: JSON.stringify({ fullname, email, password }),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' }
    };

    try {
        const response = yield call(fetchJSON, '/users/register', options);
        yield put(registerUserSuccess(response));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            case 401: message = 'Invalid credentials'; break;
            default: message = error;
        }
        yield put(registerUserFailed(message));
    }
}

/**
 * forget password
 */
function* forgetPassword({ payload: { email } }) {
    const options = {
        body: JSON.stringify({ email }),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' }
    };

    try {
        const response = yield call(fetchJSON, `${apiUrl}/forget-password`, options);
       
        if(response.message) {
           
            yield put(forgetPasswordFailed(response.message));
        }
        else {
            yield put(forgetPasswordSuccess());
        }
        
    } catch (error) {
       
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            case 401: message = 'Invalid credentials'; break;
            default: message = error;
        }
        yield put(forgetPasswordFailed(message));
    }
}

/**
 * forget password
 */
function* resetPassword({ payload: {email, password, token } }) {
    const options = {
        body: JSON.stringify({ email, password, token }),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' }
    };

    try {
        const response = yield call(fetchJSON, `${apiUrl}/reset-password`, options);
       
        if(response.message) {
           
            yield put(resetPasswordFailed(response.message));
        }
        else {
            yield put(resetPasswordSuccess());
        }
        
    } catch (error) {
       
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            case 401: message = 'Invalid credentials'; break;
            default: message = error;
        }
        yield put(resetPasswordFailed(message));
    }
}


export function* watchLoginUser() {
    yield takeEvery(LOGIN_USER, login);
}

export function* watchLogoutUser() {
    yield takeEvery(LOGOUT_USER, logout);
}

export function* watchRegisterUser() {
    yield takeEvery(REGISTER_USER, register);
}

export function* watchForgetPassword() {
    yield takeEvery(FORGET_PASSWORD, forgetPassword);
}

export function* watchResetPassword() {
    yield takeEvery(RESET_PASSWORD, resetPassword);
}

function* authSaga() {
    yield all([
        fork(watchLoginUser),
        fork(watchLogoutUser),
        fork(watchRegisterUser),
        fork(watchForgetPassword),
        fork(watchResetPassword),
    ]);
}

export default authSaga;