import {
    GET_EMPLOYEE_TRAININGS,
    GET_EMPLOYEE_TRAININGS_SUCCESS,
    GET_EMPLOYEE_TRAININGS_FAILED,

    GET_CURRENT_EMPLOYEE_TRAINING,
    GET_CURRENT_EMPLOYEE_TRAINING_SUCCESS,
    GET_CURRENT_EMPLOYEE_TRAINING_FAILED,

    SUBMIT_EMPLOYEE_TRAINING_FORM,
    SUBMIT_EMPLOYEE_TRAINING_FORM_SUCCESS,
    SUBMIT_EMPLOYEE_TRAINING_FORM_FAILED,

    DELETE_EMPLOYEE_TRAINING,
    DELETE_EMPLOYEE_TRAINING_SUCCESS,
    DELETE_EMPLOYEE_TRAINING_FAILED,
} from '../../constants/actionTypes';

/** GET ALL */
export const getAllEmployeeTrainings = ({offset, limit, filter}) => ({
    type: GET_EMPLOYEE_TRAININGS,
    payload: { offset, limit, filter }
});

export const getAllEmployeeTrainingsSuccess = ({total, data}) => ({
    type: GET_EMPLOYEE_TRAININGS_SUCCESS,
    payload: {total, data}
});

export const getAllEmployeeTrainingsFailed = (error) => ({
    type: GET_EMPLOYEE_TRAININGS_FAILED,
    payload: error
});

/** SUBMIT FORM */
export const submitEmployeeTrainingForm = (item) => ({
    type: SUBMIT_EMPLOYEE_TRAINING_FORM,
    payload: item
});

export const submitEmployeeTrainingFormSuccess = (item) => ({
    type: SUBMIT_EMPLOYEE_TRAINING_FORM_SUCCESS,
    payload: item
});

export const submitEmployeeTrainingFormFailed = (error) => ({
    type: SUBMIT_EMPLOYEE_TRAINING_FORM_FAILED,
    payload: error
});

/** DELETE FORM */
export const deleteEmployeeTraining = (id) => ({
    type: DELETE_EMPLOYEE_TRAINING,
    payload: id
});

export const deleteEmployeeTrainingSuccess = (id) => ({
    type: DELETE_EMPLOYEE_TRAINING_SUCCESS,
    payload: id
});

export const deleteEmployeeTrainingFailed = (error) => ({
    type: DELETE_EMPLOYEE_TRAINING_FAILED,
    payload: error
});


/** GET CURRENT FORM */
export const getCurrentEmployeeTraining = (id) => ({
    type: GET_CURRENT_EMPLOYEE_TRAINING,
    payload: id
});

export const getCurrentEmployeeTrainingSuccess = (item) => ({
    type: GET_CURRENT_EMPLOYEE_TRAINING_SUCCESS,
    payload: item
});

export const getCurrentEmployeeTrainingFailed = (error) => ({
    type: GET_CURRENT_EMPLOYEE_TRAINING_FAILED,
    payload: error
});