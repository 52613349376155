import {
    GET_TARGETS,
    GET_TARGETS_SUCCESS,
    GET_TARGETS_FAILED,

    SUBMIT_TARGET_FORM,
    SUBMIT_TARGET_FORM_SUCCESS,
    SUBMIT_TARGET_FORM_FAILED,

    DELETE_TARGET,
    DELETE_TARGET_SUCCESS,
    DELETE_TARGET_FAILED,
} from '../../constants/actionTypes';

const INIT_STATE = {
    loading: false,
    targets: [],
    submittingForm: false,
    deleting: false,
    error: null,
};

const reducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_TARGETS:
            return { ...state, loading: true };
        case GET_TARGETS_SUCCESS:
            return { ...state, targets: action.payload.data, total: action.payload.total, currentRole: null, loading: false, error: null };
        case GET_TARGETS_FAILED:
            return { ...state, error: action.payload, loading: false };

        
        case SUBMIT_TARGET_FORM:
            return { ...state, error: null, submittingForm: true };
        case SUBMIT_TARGET_FORM_SUCCESS:
            const alreadyExists = state.targets.find((item) => item.id === action.payload.id);
            let targets;
            let total = state.total;
            if (!alreadyExists) {
                targets = [action.payload, ...state.targets];
                total += 1;
            } else {
                targets = state.targets.map((item) => {
                if (item.id === action.payload.id) {
                    return action.payload;
                }
                return item;
                });
            }
            return { ...state, total,  targets, submittingForm: false };
        case SUBMIT_TARGET_FORM_FAILED:
            return { ...state, error: action.payload, submittingForm: false };

        case DELETE_TARGET:
            return { ...state, error: null, deleting: true };
        case DELETE_TARGET_SUCCESS:
            return { ...state, total: state.total - 1, targets: state.targets.filter(item => item.id !== action.payload) , deleting: false };
        case DELETE_TARGET_FAILED:
            return { ...state, error: action.payload, deleting: false };
    

        default: return { ...state };
    }
}

export default reducer;