import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { fetchJSON, apiRootEndpoint } from '../../helpers';
import {
    GET_APPRAISAL_FACTORS, 
    SUBMIT_APPRAISAL_FACTOR_FORM,
    DELETE_APPRAISAL_FACTOR
} from '../../constants/actionTypes';


import {
    getAllAppraisalFactorsSuccess,
    getAllAppraisalFactorsFailed,

    submitAppraisalFactorFormSuccess,
    submitAppraisalFactorFormFailed,

    deleteAppraisalFactorSuccess,
    deleteAppraisalFactorFailed,
} from './actions';

const apiUrl = `${apiRootEndpoint}/api/v1/appraisal-factors`;

function* getAllAppraisalFactors({ payload: { offset, limit, filter } }) {
    const options = {
        method: 'GET',
    };

    try {
        let url = `${apiUrl}?offset=${offset}&limit=${limit}`;
        if(filter.query) {
            url = `${url}&query=${filter.query}`
        }
        const {total, data} = yield call(fetchJSON, url, options);
        yield put(getAllAppraisalFactorsSuccess({total, data}));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(getAllAppraisalFactorsFailed(message));
    }
}

function* submitAppraisalFactor({ payload: item }) {
    const options = {
        body: JSON.stringify(item),
        method: item.id ? 'PUT' :  'POST',
        headers: { 'Content-Type': 'application/json' }
    };

    try {
        let url = `${apiUrl}`;
        if(item.id) {
            url = `${apiUrl}/${item.id}`;
        }
        const response = yield call(fetchJSON, url, options);
        if(response.status === 'OK') {
            yield put(submitAppraisalFactorFormSuccess(response.data));
        }
        else {
            yield put(submitAppraisalFactorFormFailed(response.message));
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(submitAppraisalFactorFormFailed(message));
    }
}

function* deleteAppraisalFactor({ payload: id }) {
    const options = {
        method: 'DELETE',
    };
    try {
        const url = `${apiUrl}/${id}`;
        const response = yield call(fetchJSON, url, options);
        
        if(response.status === "OK") {
            yield put(deleteAppraisalFactorSuccess(id));
        }
        else {
            yield put(deleteAppraisalFactorFailed(response.message));
        }
    } catch (error) {
       
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(deleteAppraisalFactorFailed(message));
    }
}


export function* watchGetAllAppraisalFactors() {
    yield takeEvery(GET_APPRAISAL_FACTORS, getAllAppraisalFactors);
}
export function* watchSubmitAppraisalFactorForm() {
    yield takeEvery(SUBMIT_APPRAISAL_FACTOR_FORM, submitAppraisalFactor);
}
export function* watchDeleteAppraisalFactor() {
    yield takeEvery(DELETE_APPRAISAL_FACTOR, deleteAppraisalFactor);
}

function* appraisalFactorSaga() {
    yield all([
        fork(watchGetAllAppraisalFactors),
        fork(watchSubmitAppraisalFactorForm),
        fork(watchDeleteAppraisalFactor),
    ]);
}

export default appraisalFactorSaga;