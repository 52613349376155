import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { fetchJSON, apiRootEndpoint } from '../../helpers';
import {
    SUBMIT_CHANGE_PASSWORD_FORM, 
} from '../../constants/actionTypes';


import {
    submitChangePasswordFormSuccess,
    submitChangePasswordFormFailed,
} from './actions';

const apiUrl = `${apiRootEndpoint}/api/v1/current-user`;

function* submitChangePassword({ payload: user }) {
    console.log(user);
    const options = {
        body: JSON.stringify(user),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' }
    };

    try {
        let url = `${apiUrl}/change-password`;
        const response = yield call(fetchJSON, url, options);
        if(response.status === 'OK') {
            yield put(submitChangePasswordFormSuccess(response.data));
        }
        else {
            yield put(submitChangePasswordFormFailed(response.message));
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(submitChangePasswordFormFailed(message));
    }
}

export function* watchSubmitChangePasswordForm() {
    yield takeEvery(SUBMIT_CHANGE_PASSWORD_FORM, submitChangePassword);
}


function* currentUserSaga() {
    yield all([
        fork(watchSubmitChangePasswordForm),
    ]);
}

export default currentUserSaga;