import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { fetchJSON, apiRootEndpoint } from '../../helpers';
import {
    GET_LEAVE_REQUESTS, 
    SUBMIT_LEAVE_REQUEST_FORM,
    DELETE_LEAVE_REQUEST,
    EXPORT_LEAVE_REQUESTS
} from '../../constants/actionTypes';


import {
    getAllLeaveRequestsSuccess,
    getAllLeaveRequestsFailed,

    submitLeaveRequestFormSuccess,
    submitLeaveRequestFormFailed,

    deleteLeaveRequestSuccess,
    deleteLeaveRequestFailed,

    exportLeaveRequestsSuccess,
    exportLeaveRequestsFailed
} from './actions';

const apiUrl = `${apiRootEndpoint}/api/v1/leave-requests`; 

function* getAllLeaveRequests({ payload: { offset, limit, filter } }) {
    const options = {
        method: 'GET',
    };

    try {
        let url = `${apiUrl}?offset=${offset}&limit=${limit}`;
        if(filter.branchId) {
            url = `${url}&branchId=${filter.branchId}`
        }
        if(filter.startDate && filter.endDate ) {
            url = `${url}&startDate=${filter.startDate}&endDate=${filter.endDate}`
        }
        if(filter.userId) {
            url = `${url}&userId=${filter.userId}`
        }
        if(filter.query) {
            url = `${url}&query=${filter.query}`
        }
        const {total, data} = yield call(fetchJSON, url, options);
        
        yield put(getAllLeaveRequestsSuccess({total, data}));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(getAllLeaveRequestsFailed(message));
    }
}

function* submitLeaveRequest({ payload: leaveRequest }) {
    const options = {
        body: JSON.stringify(leaveRequest),
        method: leaveRequest.id ? 'PUT' :  'POST',
        headers: { 'Content-Type': 'application/json' }
    };

    try {
        let url = `${apiUrl}`;
        if(leaveRequest.id) {
            url = `${apiUrl}/${leaveRequest.id}`;
        }
        const response = yield call(fetchJSON, url, options);
        if(response.status === 'OK') {
            yield put(submitLeaveRequestFormSuccess(response.data));
        }
        else {
            yield put(submitLeaveRequestFormFailed(response.message));
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(submitLeaveRequestFormFailed(message));
    }
}

function* deleteLeaveRequest({ payload: id }) {
    const options = {
        method: 'DELETE',
    };
    try {
        const url = `${apiUrl}/${id}`;
        const response = yield call(fetchJSON, url, options);
        if(response.status === 'OK') {
            yield put(deleteLeaveRequestSuccess(id));
        }
        else {
            yield put(deleteLeaveRequestFailed(response.message));
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(deleteLeaveRequestFailed(message));
    }
}



function* exportLeaveRequests({ payload: data }) {
    const options = {
        body: JSON.stringify(data),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' }
    };
    try {
        const url = `${apiUrl}/export`;
        const response = yield call(fetchJSON, url, options);
        
        if(response.status === "OK") {
            if(response.url) {
                window.open(response.url);
            }
            yield put(exportLeaveRequestsSuccess());
        }
        else {
            yield put(exportLeaveRequestsFailed(response.message));
        }
    } catch (error) {
       
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(exportLeaveRequestsFailed(message));
    }
}

export function* watchGetAllLeaveRequests() {
    yield takeEvery(GET_LEAVE_REQUESTS, getAllLeaveRequests);
}
export function* watchSubmitLeaveRequestForm() {
    yield takeEvery(SUBMIT_LEAVE_REQUEST_FORM, submitLeaveRequest);
}
export function* watchDeleteLeaveRequest() {
    yield takeEvery(DELETE_LEAVE_REQUEST, deleteLeaveRequest);
}
export function* watchLeaveRequestsRequest() {
    yield takeEvery(EXPORT_LEAVE_REQUESTS, exportLeaveRequests);
}

function* leaveRequestSaga() {
    yield all([
        fork(watchGetAllLeaveRequests),
        fork(watchSubmitLeaveRequestForm),
        fork(watchDeleteLeaveRequest),
        fork(watchLeaveRequestsRequest),
    ]);
}

export default leaveRequestSaga;