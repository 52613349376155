import {
    GET_EVENT_PARTICIPANTS,
    GET_EVENT_PARTICIPANTS_SUCCESS,
    GET_EVENT_PARTICIPANTS_FAILED,

    SUBMIT_EVENT_PARTICIPANT_FORM,
    SUBMIT_EVENT_PARTICIPANT_FORM_SUCCESS,
    SUBMIT_EVENT_PARTICIPANT_FORM_FAILED,

    DELETE_EVENT_PARTICIPANT,
    DELETE_EVENT_PARTICIPANT_SUCCESS,
    DELETE_EVENT_PARTICIPANT_FAILED,
} from '../../constants/actionTypes';


/** GET ALL */
export const getAllEventParticipants = ({offset, limit, filter}) => ({
    type: GET_EVENT_PARTICIPANTS,
    payload: { offset, limit, filter }
});

export const getAllEventParticipantsSuccess = ({total, data}) => ({
    type: GET_EVENT_PARTICIPANTS_SUCCESS,
    payload: {total, data}
});

export const getAllEventParticipantsFailed = (error) => ({
    type: GET_EVENT_PARTICIPANTS_FAILED,
    payload: error
});

/** SUBMIT FORM */
export const submitEventParticipantForm = (item) => ({
    type: SUBMIT_EVENT_PARTICIPANT_FORM,
    payload: item
});

export const submitEventParticipantFormSuccess = (item) => ({
    type: SUBMIT_EVENT_PARTICIPANT_FORM_SUCCESS,
    payload: item
});

export const submitEventParticipantFormFailed = (error) => ({
    type: SUBMIT_EVENT_PARTICIPANT_FORM_FAILED,
    payload: error
});

/** DELETE FORM */
export const deleteEventParticipant = (id) => ({
    type: DELETE_EVENT_PARTICIPANT,
    payload: id
});

export const deleteEventParticipantSuccess = (id) => ({
    type: DELETE_EVENT_PARTICIPANT_SUCCESS,
    payload: id
});

export const deleteEventParticipantFailed = (error) => ({
    type: DELETE_EVENT_PARTICIPANT_FAILED,
    payload: error
});