import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { fetchJSON, apiRootEndpoint } from '../../helpers';
import {
    GET_TASKS, 
    SUBMIT_TASK_FORM,
    DELETE_TASK
} from '../../constants/actionTypes';


import {
    getAllTasksSuccess,
    getAllTasksFailed,

    submitTaskFormSuccess,
    submitTaskFormFailed,

    deleteTaskSuccess,
    deleteTaskFailed,
} from './actions';

const apiUrl = `${apiRootEndpoint}/api/v1/tasks`;

function* getAllTasks({ payload: { offset, limit, filter } }) {
    const options = {
        method: 'GET',
    };

    try {
        let url = `${apiUrl}?offset=${offset}&limit=${limit}`;
        if(filter.timesheetId) {
            url = `${url}&timesheetId=${filter.timesheetId}`
        }
        const {total, data} = yield call(fetchJSON, url, options);
        yield put(getAllTasksSuccess({total, data}));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(getAllTasksFailed(message));
    }
}

function* submitTask({ payload: task }) {
    const options = {
        body: JSON.stringify(task),
        method: task.id ? 'PUT' :  'POST',
        headers: { 'Content-Type': 'application/json' }
    };

    try {
        let url = `${apiUrl}`;
        if(task.id) {
            url = `${apiUrl}/${task.id}`;
        }
        const response = yield call(fetchJSON, url, options);
        if(response.status === 'OK') {
            yield put(submitTaskFormSuccess(response.data));
        }
        else {
            yield put(submitTaskFormFailed(response.message));
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(submitTaskFormFailed(message));
    }
}

function* deleteTask({ payload: id }) {
    const options = {
        method: 'DELETE',
    };
    try {
        const url = `${apiUrl}/${id}`;
        const response = yield call(fetchJSON, url, options);
        
        if(response.status === "OK") {
            yield put(deleteTaskSuccess(id));
        }
        else {
            yield put(deleteTaskFailed(response.message));
        }
    } catch (error) {
       
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(deleteTaskFailed(message));
    }
}


export function* watchGetAllTasks() {
    yield takeEvery(GET_TASKS, getAllTasks);
}
export function* watchSubmitTaskForm() {
    yield takeEvery(SUBMIT_TASK_FORM, submitTask);
}
export function* watchDeleteTask() {
    yield takeEvery(DELETE_TASK, deleteTask);
}

function* taskSaga() {
    yield all([
        fork(watchGetAllTasks),
        fork(watchSubmitTaskForm),
        fork(watchDeleteTask),
    ]);
}

export default taskSaga;