import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { fetchJSON, apiRootEndpoint } from '../../helpers';
import {
    GET_SUPPORT_FUND_ORGANIZATIONS, 
    SUBMIT_SUPPORT_FUND_ORGANIZATION_FORM,
    DELETE_SUPPORT_FUND_ORGANIZATION
} from '../../constants/actionTypes';


import {
    getAllSupportFundOrganizationsSuccess,
    getAllSupportFundOrganizationsFailed,

    submitSupportFundOrganizationFormSuccess,
    submitSupportFundOrganizationFormFailed,

    deleteSupportFundOrganizationSuccess,
    deleteSupportFundOrganizationFailed,
} from './actions';

const apiUrl = `${apiRootEndpoint}/api/v1/support-fund-organizations`;

function* getAllSupportFundOrganizations({ payload: { offset, limit } }) {
    const options = {
        method: 'GET',
    };

    try {
        const url = `${apiUrl}?offset=${offset}&limit=${limit}`;
        const {total, data} = yield call(fetchJSON, url, options);
        yield put(getAllSupportFundOrganizationsSuccess({total, data}));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(getAllSupportFundOrganizationsFailed(message));
    }
}

function* submitSupportFundOrganization({ payload: organization }) {
    const options = {
        body: JSON.stringify(organization),
        method: organization.id ? 'PUT' :  'POST',
        headers: { 'Content-Type': 'application/json' }
    };

    try {
        let url = `${apiUrl}`;
        if(organization.id) {
            url = `${apiUrl}/${organization.id}`;
        }
        const response = yield call(fetchJSON, url, options);
        if(response.status === 'OK') {
            yield put(submitSupportFundOrganizationFormSuccess(response.data));
        }
        else {
            yield put(submitSupportFundOrganizationFormFailed(response.message));
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(submitSupportFundOrganizationFormFailed(message));
    }
}

function* deleteSupportFundOrganization({ payload: id }) {
    const options = {
        method: 'DELETE',
    };
    try {
        const url = `${apiUrl}/${id}`;
        const response = yield call(fetchJSON, url, options);
        if(response.status === 'OK') {
            yield put(deleteSupportFundOrganizationSuccess(id));
        }
        else {
            yield put(deleteSupportFundOrganizationFailed(response.message));
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(deleteSupportFundOrganizationFailed(message));
    }
}


export function* watchGetAllSupportFundOrganizations() {
    yield takeEvery(GET_SUPPORT_FUND_ORGANIZATIONS, getAllSupportFundOrganizations);
}
export function* watchSubmitSupportFundOrganizationForm() {
    yield takeEvery(SUBMIT_SUPPORT_FUND_ORGANIZATION_FORM, submitSupportFundOrganization);
}
export function* watchDeleteSupportFundOrganization() {
    yield takeEvery(DELETE_SUPPORT_FUND_ORGANIZATION, deleteSupportFundOrganization);
}

function* supportFundOrganizationSaga() {
    yield all([
        fork(watchGetAllSupportFundOrganizations),
        fork(watchSubmitSupportFundOrganizationForm),
        fork(watchDeleteSupportFundOrganization),
    ]);
}

export default supportFundOrganizationSaga;