import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import cogoToast from 'cogo-toast';
import { fetchJSON, apiRootEndpoint } from '../../helpers';
import {
    GET_COES, 
    SUBMIT_COES_FORM,
    DELETE_COES,
    FETCH_COES,
    EXPORT_COES,
    MATCH_COES
} from '../../constants/actionTypes';


import {
    getAllCoesSuccess,
    getAllCoesFailed,

    submitCoeFormSuccess,
    submitCoeFormFailed,

    deleteCoeSuccess,
    deleteCoeFailed,

    fetchCoesFailed,
    fetchCoesSuccess,

    exportCoesSuccess,
    exportCoesFailed,

    matchCoesSuccess,
    matchCoesFailed
} from './actions';

const apiUrl = `${apiRootEndpoint}/api/v1/coes`; 

function* getAllCoes({ payload: { offset, limit, filter } }) {
    const options = {
        method: 'GET',
    };

    try {
        let url = `${apiUrl}?offset=${offset}&limit=${limit}`;
        if(filter.query) {
            url = `${url}&query=${filter.query}`
        }
        if(filter.isApproved === true || filter.isApproved === false) {
            url = `${url}&isApproved=${filter.isApproved}`
        }
        if(filter.isMatched === true || filter.isMatched === false) {
            url = `${url}&isMatched=${filter.isMatched}`
        }
        if(filter.branchId) {
            url = `${url}&branchId=${filter.branchId}`;
        }
        const {total, data} = yield call(fetchJSON, url, options);
        
        yield put(getAllCoesSuccess({total, data}));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(getAllCoesFailed(message));
    }
}

function* fetchCoes() {
    const options = {
        method: 'GET',
    };

    try {
        const url = `${apiUrl}/fetch`;
        const response = yield call(fetchJSON, url, options);
        const {total, data, status, message} = response;
        if(status === 'OK') {
            yield put(fetchCoesSuccess({total, data}));
        }
        else {
            cogoToast.error(message);
            yield put(fetchCoesFailed(message));
        }
        
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(fetchCoesFailed(message));
    }
}

function* matchCoes() {
    const options = {
        method: 'GET',
    };

    try {
        const url = `${apiUrl}/match`;
        const response = yield call(fetchJSON, url, options);
        const {status, message} = response;
        if(status === 'OK') {
            yield put(matchCoesSuccess());
        }
        else {
            cogoToast.error(message);
            yield put(matchCoesFailed(message));
        }
        
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(matchCoesFailed(message));
    }
}

function* submitCoe({ payload: item }) {
    const options = {
        body: JSON.stringify(item),
        method: item.id ? 'PUT' :  'POST',
        headers: { 'Content-Type': 'application/json' }
    };

    try {
        let url = `${apiUrl}`;
        if(item.id) {
            url = `${apiUrl}/${item.id}`;
        }
        const response = yield call(fetchJSON, url, options);
        if(response.status === 'OK') {
            yield put(submitCoeFormSuccess(response.data));
        }
        else {
            yield put(submitCoeFormFailed(response.message));
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(submitCoeFormSuccess(message));
    }
}

function* deleteCoe({ payload: id }) {
    const options = {
        method: 'DELETE',
    };
    try {
        const url = `${apiUrl}/${id}`;
        const response = yield call(fetchJSON, url, options);
        if(response.status === 'OK') {
            yield put(deleteCoeSuccess(id));
        }
        else {
            yield put(deleteCoeFailed(response.message));
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(deleteCoeFailed(message));
    }
}

function* exportCoes({ payload: data }) {
    const options = {
        body: JSON.stringify(data),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' }
    };
    try {
        const url = `${apiUrl}/export`;
        const response = yield call(fetchJSON, url, options);
        
        if(response.status === "OK") {
            if(response.url) {
                window.open(response.url);
            }
            yield put(exportCoesSuccess());
        }
        else {
            yield put(exportCoesFailed(response.message));
        }
    } catch (error) {
       
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(exportCoesFailed(message));
    }
}


export function* watchGetAllCoes() {
    yield takeEvery(GET_COES, getAllCoes);
}
export function* watchSubmitCoeForm() {
    yield takeEvery(SUBMIT_COES_FORM, submitCoe);
}
export function* watchDeleteCoe() {
    yield takeEvery(DELETE_COES, deleteCoe);
}
export function* watchFetchCoes() {
    yield takeEvery(FETCH_COES, fetchCoes);
}
export function* watchExportCoes() {
    yield takeEvery(EXPORT_COES, exportCoes);
}
export function* watchMatchCoes() {
    yield takeEvery(MATCH_COES, matchCoes);
}

function* coeSaga() {
    yield all([
        fork(watchGetAllCoes),
        fork(watchSubmitCoeForm),
        fork(watchDeleteCoe),
        fork(watchFetchCoes),
        fork(watchExportCoes),
        fork(watchMatchCoes),
    ]);
}

export default coeSaga;