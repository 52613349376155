import {
    GET_INSTITUTES,
    GET_INSTITUTES_SUCCESS,
    GET_INSTITUTES_FAILED,

    SUBMIT_INSTITUTE_FORM,
    SUBMIT_INSTITUTE_FORM_SUCCESS,
    SUBMIT_INSTITUTE_FORM_FAILED,

    DELETE_INSTITUTE,
    DELETE_INSTITUTE_SUCCESS,
    DELETE_INSTITUTE_FAILED,
} from '../../constants/actionTypes';


/** GET ALL */
export const getAllInstitutes = ({offset, limit, filter}) => ({
    type: GET_INSTITUTES,
    payload: { offset, limit, filter }
});

export const getAllInstitutesSuccess = ({total, data}) => ({
    type: GET_INSTITUTES_SUCCESS,
    payload: {total, data}
});

export const getAllInstitutesFailed = (error) => ({
    type: GET_INSTITUTES_FAILED,
    payload: error
});

/** SUBMIT FORM */
export const submitInstituteForm = (institute) => ({
    type: SUBMIT_INSTITUTE_FORM,
    payload: institute
});

export const submitInstituteFormSuccess = (institute) => ({
    type: SUBMIT_INSTITUTE_FORM_SUCCESS,
    payload: institute
});

export const submitInstituteFormFailed = (error) => ({
    type: SUBMIT_INSTITUTE_FORM_FAILED,
    payload: error
});

/** DELETE FORM */
export const deleteInstitute = (id) => ({
    type: DELETE_INSTITUTE,
    payload: id
});

export const deleteInstituteSuccess = (id) => ({
    type: DELETE_INSTITUTE_SUCCESS,
    payload: id
});

export const deleteInstituteFailed = (error) => ({
    type: DELETE_INSTITUTE_FAILED,
    payload: error
});