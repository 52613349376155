import React from 'react';
import { Redirect } from "react-router-dom";
import { Route } from 'react-router-dom';

import { isUserAuthenticated, getLoggedInUser } from './helpers/authUtils';
import { NonAuthLayout   } from './Layouts';

// lazy load all the views
const Dashboard = React.lazy(() => import('./pages/dashboards/'));
const Countries = React.lazy(() => import('./pages/countries/'));
const Departments = React.lazy(() => import('./pages/departments/'));
const Roles = React.lazy(() => import('./pages/roles/'));
const RoleDetails = React.lazy(() => import('./pages/roleDetails/'));
const Branches = React.lazy(() => import('./pages/branches/'));
const Students = React.lazy(() => import('./pages/students/'));
const StudentDetails = React.lazy(() => import('./pages/studentDetails/'));
const Institutes = React.lazy(() => import('./pages/institutes/'));
const Courses = React.lazy(() => import('./pages/courses/'));
const CreditNotes = React.lazy(() => import('./pages/creditNotes/'));
const Users = React.lazy(() => import('./pages/users/'));
const Timesheets = React.lazy(() => import('./pages/timesheets/'));
const SupportFundStudents = React.lazy(() => import('./pages/supportFundStudents/'));
const SupportFundOrganizations = React.lazy(() => import('./pages/supportFundOrganizaitons/'));
const EmployeeOnboardings = React.lazy(() => import('./pages/employeeOnboardings/'));
const EmployeeOnboardingForm = React.lazy(() => import('./pages/employeeOnboardingForm/'));
const EmploymentContracts = React.lazy(() => import('./pages/employmentContracts/'));
const EmploymentContractForm = React.lazy(() => import('./pages/employmentContractForm/'));
const Employees = React.lazy(() => import('./pages/employees/'));
const EmployeeDetails = React.lazy(() => import('./pages/employeeDetails/'));

const CoeEmails = React.lazy(() => import('./pages/coeEmails/'));
const Coes = React.lazy(() => import('./pages/coes/'));
const CoesTelegramBot = React.lazy(() => import('./pages/coesTelegramBot/'));

const AppraisalFactors = React.lazy(() => import('./pages/appraisalFactors/'));
const AppraisalSheets = React.lazy(() => import('./pages/appraisalSheets/'));
const AppraisalSheetDetails = React.lazy(() => import('./pages/appraisalSheetDetails/'));
const Appraisals = React.lazy(() => import('./pages/appraisals/'));
const AppraisalDetails = React.lazy(() => import('./pages/appraisalDetails/'));
const AppraisalFeedback = React.lazy(() => import('./pages/appraisalFeedback/'));

const Profile = React.lazy(() => import('./pages/profile/'));
const ChangePassword = React.lazy(() => import('./pages/changePassword/'));

// auth
const Landing = React.lazy(() => import('./pages/landing'));
const Login = React.lazy(() => import('./pages/auth/Login'));
const Logout = React.lazy(() => import('./pages/auth/Logout'));
const ForgetPassword = React.lazy(() => import('./pages/account/ForgetPassword'));
const ResetPassword = React.lazy(() => import('./pages/account/ResetPassword'));

const Covid19Assistance = React.lazy(() => import('./pages/covid19Assistance'));
const VerifyCreditNote = React.lazy(() => import('./pages/verifyCreditNote'));

const OCR = React.lazy(() => import('./pages/ocr'));

const Events = React.lazy(() => import('./pages/events/'));
const TrainingProviders = React.lazy(() => import('./pages/trainingProviders/'));
const TrainingPrograms = React.lazy(() => import('./pages/trainingPrograms/'));
const EmployeeTrainings = React.lazy(() => import('./pages/employeeTrainings/'));
const EventParticipants = React.lazy(() => import('./pages/eventParticipants/'));
const RegisterEventParticipation = React.lazy(() => import('./pages/registerEventParticipation/'));
const EventRaffle = React.lazy(() => import('./pages/eventRaffle/'));
const ConfirmEmployeeTraining = React.lazy(() => import('./pages/confirmEmployeeTraining/'));

const LeaveRequests = React.lazy(() => import('./pages/leaveRequests/'));
const TelegramGroups = React.lazy(() => import('./pages/telegramGroups/'));
const Targets = React.lazy(() => import('./pages/targets/'));

// handle auth and authorization

const PrivateRoute = ({ component: Component, permission, ...rest }) => (
  <Route {...rest} render={props => {
    const isAuthTokenValid = isUserAuthenticated();
    if (!isAuthTokenValid) {
      // not logged in so redirect to login page with the return url
      return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
    }

    const loggedInUser = getLoggedInUser();

    // check if route is restricted by permission
    if (permission && (loggedInUser.permissions[permission] === false)) {
      // role not authorised so redirect to home page
      return <Redirect to={{ pathname: '/dashboard' }} />
    }

    // authorised so return component
    return <Component {...props} />
  }} />
)

const routes = [
  // auth and account
  { path: '/landing', name: 'Landing', layout: NonAuthLayout, component: Landing, route: Route },
  { path: '/login', name: 'Login', component: Login, route: Route },
  { path: '/logout', name: 'Logout', component: Logout, route: Route },
  { path: '/forget-password', name: 'Forget Password', component: ForgetPassword, route: Route },
  { path: '/reset-password', name: 'Reset Password', component: ResetPassword, route: Route },
  { path: '/covid19-assistance', name: 'Covid19Assistance', component: Covid19Assistance, route: Route },
    { path: '/verify-credit-note', name: 'VerifyCreditNote', component: VerifyCreditNote, route: Route },

  // other pages
  { path: '/dashboard', name: 'Dashboard', component: Dashboard, route: PrivateRoute, permission: 'timesheets_view_all' },
  { path: '/users', name: 'Users', component: Users, route: PrivateRoute, permission: 'users_view_all' },
  { path: '/timesheets', name: 'Timesheets', component: Timesheets, route: PrivateRoute, permission: 'timesheets_view_all' },

  { path: '/leave-requests', name: 'LeaveRequests', component: LeaveRequests, route: PrivateRoute, permission: 'leave_requests_view_all' },


  { path: '/profile', name: 'Profile', component: Profile, route: PrivateRoute },
  { path: '/change-password', name: 'ChangePassword', component: ChangePassword, route: PrivateRoute},
  { path: '/countries', name: 'Countries', component: Countries, route: PrivateRoute, permission: 'countries_view_all'},
  { path: '/departments', name: 'Departments', component: Departments, route: PrivateRoute, permission: 'departments_view_all'},
  { path: '/roles', exact: true, name: 'Roles', component: Roles, route: PrivateRoute,  permission: 'roles_and_permissions_view_all'},
  { path: '/roles/:id', exact: true,   name: 'RoleDetails', component: RoleDetails, route: PrivateRoute, permission: 'roles_and_permissions_view_all'},
  { path: '/branches', name: 'Branches', component: Branches, route: PrivateRoute, permission: 'branches_view_all'},
  { path: '/students', exact: true, name: 'Students', component: Students, route: PrivateRoute, permission: 'students_view_all'},
  { path: '/students/:id', exact: true, name: 'StudentDetails', component: StudentDetails, route: PrivateRoute, permission: 'student_details' },
  { path: '/institutes', name: 'Institutes', component: Institutes, route: PrivateRoute, permission: 'institutes_view_all'},
  { path: '/courses', name: 'Courses', component: Courses, route: PrivateRoute, permission: 'courses_view_all'},
  { path: '/credit-notes', name: 'CreditNotes', component: CreditNotes, route: PrivateRoute,  permission: 'credit_notes_view_all'},
  { path: '/support-fund-students', name: 'SupportFundStudents', component: SupportFundStudents, permission: 'support_fund_is_available', route: PrivateRoute },
  { path: '/support-fund-organizations', name: 'SupportFundOrganizations', component: SupportFundOrganizations, permission: 'support_fund_is_available', route: PrivateRoute },

  { path: '/appraisal-factors', name: 'AppraisalFactors', component: AppraisalFactors, route: PrivateRoute,   permission: 'appraisal_factors_view_all'},
  { path: '/appraisal-sheets', exact: true, name: 'AppraisalSheets', component: AppraisalSheets, route: PrivateRoute, permission: 'appraisal_sheets_view_all'},
  { path: '/appraisal-sheets/:id', exact: true, name: 'AppraisalSheetDetails', component: AppraisalSheetDetails, route: PrivateRoute, permission: 'appraisal_sheets_view_all'},
  { path: '/appraisals', exact: true, name: 'Appraisals', component: Appraisals, route: PrivateRoute, permission: 'appraisals_view_all' },
  { path: '/appraisals/:id', exact: true, name: 'AppraisalDetails', component: AppraisalDetails, route: PrivateRoute},
  { path: '/appraisal-feedback/:id', exact: true, layout: NonAuthLayout, name: 'AppraisalFeedback', component: AppraisalFeedback, route: Route },
  { path: '/employee-onboardings', exact: true, name: 'EmployeeOnboardings', component: EmployeeOnboardings, route: PrivateRoute, permission: 'employee_onboarding_view_all' },
  { path: '/employee-onboardings/:id', exact: true, name: 'EmployeeOnboardingForm', layout: NonAuthLayout, component: EmployeeOnboardingForm, route: Route },
  { path: '/employment-contracts', exact: true, name: 'EmploymentContracts', component: EmploymentContracts, route: PrivateRoute, permission: 'employee_onboarding_view_all' },
  { path: '/employment-contracts/:id', exact: true, name: 'EmploymentContractForm', layout: NonAuthLayout, component: EmploymentContractForm, route: Route },
  { path: '/employees', exact: true, name: 'Employees', component: Employees, route: PrivateRoute, permission: 'employees_all' },
  { path: '/employees/:id', exact: true, name: 'EmployeeDetails', component: EmployeeDetails, route: PrivateRoute, permission: 'employee_details' },
  { path: '/coe-emails', exact: true, name: 'CoeEmails', component: CoeEmails, route: PrivateRoute, permission: 'coe_emails_view_all' },
  { path: '/coes', exact: true, name: 'Coes', component: Coes, route: PrivateRoute, permission: 'coes_view_all' },
  { path: '/coes-telegram-bot', exact: true, name: 'Coes Telegram BOt', component: CoesTelegramBot, route: PrivateRoute, permission: 'coes_telegram' },
  { path: '/telegram-groups', exact: true, name: 'Telegram Groups', component: TelegramGroups, route: PrivateRoute, permission: 'telegram_groups_view_all' },
  { path: '/targets', exact: true, name: 'Targets', component: Targets, route: PrivateRoute, permission: 'target_view_all' },

  { path: '/ocr', exact: true, name: 'OCR', component: OCR, route: PrivateRoute, permission: 'lab_is_available' },
  { path: '/events', exact: true, name: 'Events', component: Events, route: PrivateRoute, permission: 'events_view_all'},
  { path: '/event-participants', exact: true, name: 'EventParticipants', component: EventParticipants, route: PrivateRoute, permission: 'events_view_all'},
  { path: '/events/:id/register', name: 'RegisterEventParticipation', component: RegisterEventParticipation, layout: NonAuthLayout, route: Route},
  { path: '/events/:id/raffle', name: 'EventRaffle', component: EventRaffle, layout: NonAuthLayout, route: Route},
  { path: '/learning-development/training-providers', exact: true, name: 'TrainingProviders', component: TrainingProviders, route: PrivateRoute, permission: 'training_providers_view_all'},
  { path: '/learning-development/training-programs', exact: true, name: 'TrainingPrograms', component: TrainingPrograms, route: PrivateRoute, permission: 'training_programs_view_all'},
  { path: '/learning-development/employee-trainings', exact: true, name: 'EmployeeTrainings', component: EmployeeTrainings, route: PrivateRoute, permission: 'employee_trainings_view_all'},
  { path: '/confirm-employee-trainings/:id', name: 'ConfirmEmployeeTraining', component: ConfirmEmployeeTraining, layout: NonAuthLayout, route: Route},
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/dashboard" />,
    route: PrivateRoute
  },
  
]

export { routes, PrivateRoute };