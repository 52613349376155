import {
    GET_APPRAISAL_FEEDBACKS,
    GET_APPRAISAL_FEEDBACKS_SUCCESS,
    GET_APPRAISAL_FEEDBACKS_FAILED,


    SUBMIT_APPRAISAL_FEEDBACK_FORM,
    SUBMIT_APPRAISAL_FEEDBACK_FORM_SUCCESS,
    SUBMIT_APPRAISAL_FEEDBACK_FORM_FAILED,
} from '../../constants/actionTypes';

const INIT_STATE = {
    submittingForm: false,
    error: null,
    appraisalFeedbacks: [],
    total: 0,
    loading: false,
};

const AppraisalFeedbackReducer = (state = INIT_STATE, action) => {
    switch (action.type) {

        case GET_APPRAISAL_FEEDBACKS:
            return { ...state, loading: true };
        case GET_APPRAISAL_FEEDBACKS_SUCCESS:
            return { ...state, appraisalFeedbacks: action.payload.data, total: action.payload.total, loading: false, error: null };
        case GET_APPRAISAL_FEEDBACKS_FAILED:
            return { ...state, error: action.payload, loading: false };
       
        case SUBMIT_APPRAISAL_FEEDBACK_FORM:
            return { ...state, error: null, submittingForm: true };
        case SUBMIT_APPRAISAL_FEEDBACK_FORM_SUCCESS:
            return { ...state, submittingForm: false };
        case SUBMIT_APPRAISAL_FEEDBACK_FORM_FAILED:
            return { ...state, error: action.payload, submittingForm: false };
        default: return { ...state };
    }
}

export default AppraisalFeedbackReducer;