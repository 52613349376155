import {
    GET_LEAVE_REQUESTS,
    GET_LEAVE_REQUESTS_SUCCESS,
    GET_LEAVE_REQUESTS_FAILED,

    SUBMIT_LEAVE_REQUEST_FORM,
    SUBMIT_LEAVE_REQUEST_FORM_SUCCESS,
    SUBMIT_LEAVE_REQUEST_FORM_FAILED,

    DELETE_LEAVE_REQUEST,
    DELETE_LEAVE_REQUEST_SUCCESS,
    DELETE_LEAVE_REQUEST_FAILED,

    EXPORT_LEAVE_REQUESTS,
    EXPORT_LEAVE_REQUESTS_SUCCESS,
    EXPORT_LEAVE_REQUESTS_FAILED,
} from '../../constants/actionTypes';

const INIT_STATE = {
    loading: false,
    leaveRequests: [],
    submittingForm: false,
    error: null,
    deleting: false,
    exporting: false
};

const CountryReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_LEAVE_REQUESTS:
            return { ...state, loading: true };
        case GET_LEAVE_REQUESTS_SUCCESS:
            
            return { ...state, leaveRequests: action.payload.data, total: action.payload.total, loading: false, error: null };
        case GET_LEAVE_REQUESTS_FAILED:
            return { ...state, error: action.payload, loading: false };

        case SUBMIT_LEAVE_REQUEST_FORM:
            return { ...state, error: null, submittingForm: true };
        case SUBMIT_LEAVE_REQUEST_FORM_SUCCESS:
            const alreadyExists = state.leaveRequests.find((item) => item.id === action.payload.id);
            let leaveRequests;
            let total = state.total;
            if (!alreadyExists) {
                leaveRequests = [action.payload, ...state.leaveRequests];
                total += 1;
            } else {
                leaveRequests = state.leaveRequests.map((item) => {
                if (item.id === action.payload.id) {
                  return action.payload;
                }
                return item;
              });
            }
            return { ...state, total,  leaveRequests, submittingForm: false };
        case SUBMIT_LEAVE_REQUEST_FORM_FAILED:
            return { ...state, error: action.payload, submittingForm: false };

        case DELETE_LEAVE_REQUEST:
            return { ...state, error: null, deleting: true };
        case DELETE_LEAVE_REQUEST_SUCCESS:
            return { ...state, total: state.total - 1, leaveRequests: state.leaveRequests.filter(item => item.id !== action.payload) , deleting: false };
        case DELETE_LEAVE_REQUEST_FAILED:
            return { ...state, error: action.payload, deleting: false };

        case EXPORT_LEAVE_REQUESTS:
            return { ...state, error: null, exporting: true };
        case EXPORT_LEAVE_REQUESTS_SUCCESS:
            return { ...state, exporting: false };
        case EXPORT_LEAVE_REQUESTS_FAILED:
            return { ...state, error: action.payload, exporting: false };
        default: return { ...state };
    }
}

export default CountryReducer;