import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { fetchJSON, apiRootEndpoint } from '../../helpers';
import {
    GET_COE_EMAILS, 
    SUBMIT_COE_EMAIL_FORM,
    DELETE_COE_EMAIL,
    IMPORT_COE_EMAILS
} from '../../constants/actionTypes';


import {
    getAllCoeEmailsSuccess,
    getAllCoeEmailsFailed,

    submitCoeEmailFormSuccess,
    submitCoeEmailFormFailed,

    deleteCoeEmailSuccess,
    deleteCoeEmailFailed,

    importCoeEmailsSuccess,
    importCoeEmailsFailed
} from './actions';

const apiUrl = `${apiRootEndpoint}/api/v1/coe-emails`; 

function* getAllCoeEmails({ payload: { offset, limit, filter } }) {
    const options = {
        method: 'GET',
    };

    try {
        let url = `${apiUrl}?offset=${offset}&limit=${limit}`;
        if(filter.query) {
            url = `${url}&query=${filter.query}`
        }
        if(filter.branchId) {
            url = `${url}&branchId=${filter.branchId}`
        }
        const {total, data} = yield call(fetchJSON, url, options);
        
        yield put(getAllCoeEmailsSuccess({total, data}));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(getAllCoeEmailsFailed(message));
    }
}

function* submitCoeEmail({ payload: coeEmail }) {
    const options = {
        body: JSON.stringify(coeEmail),
        method: coeEmail.id ? 'PUT' :  'POST',
        headers: { 'Content-Type': 'application/json' }
    };

    try {
        let url = `${apiUrl}`;
        if(coeEmail.id) {
            url = `${apiUrl}/${coeEmail.id}`;
        }
        const response = yield call(fetchJSON, url, options);
        if(response.status === 'OK') {
            window.location.href = '/coe-emails';
            yield put(submitCoeEmailFormSuccess(response.data));
        }
        else {
            yield put(submitCoeEmailFormFailed(response.message));
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(submitCoeEmailFormFailed(message));
    }
}

function* deleteCoeEmail({ payload: id }) {
    const options = {
        method: 'DELETE',
    };
    try {
        const url = `${apiUrl}/${id}`;
        const response = yield call(fetchJSON, url, options);
        if(response.status === 'OK') {
            yield put(deleteCoeEmailSuccess(id));
        }
        else {
            yield put(deleteCoeEmailFailed(response.message));
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(deleteCoeEmailFailed(message));
    }
}

function* importCoeEmails({ payload: file }) {
    const options = {
        body: JSON.stringify({file}),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' }
    };

    try {
        let url = `${apiUrl}/import`;
        const {status, total, data, message} = yield call(fetchJSON, url, options);
        if(status === 'OK') {
            yield put(importCoeEmailsSuccess({total, data}));
        }
        else {
            yield put(importCoeEmailsFailed(message));
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(importCoeEmailsFailed(message));
    }
}


export function* watchGetAllCoeEmails() {
    yield takeEvery(GET_COE_EMAILS, getAllCoeEmails);
}
export function* watchSubmitCoeEmailForm() {
    yield takeEvery(SUBMIT_COE_EMAIL_FORM, submitCoeEmail);
}
export function* watchDeleteCoeEmail() {
    yield takeEvery(DELETE_COE_EMAIL, deleteCoeEmail);
}

export function* watchImportCoeEmail() {
    yield takeEvery(IMPORT_COE_EMAILS, importCoeEmails);
}


function* coeEmailSaga() {
    yield all([
        fork(watchGetAllCoeEmails),
        fork(watchSubmitCoeEmailForm),
        fork(watchDeleteCoeEmail),
        fork(watchImportCoeEmail),
    ]);
}

export default coeEmailSaga;