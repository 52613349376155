import {
    GET_APPRAISAL_SHEETS,
    GET_APPRAISAL_SHEETS_SUCCESS,
    GET_APPRAISAL_SHEETS_FAILED,

    SUBMIT_APPRAISAL_SHEET_FORM,
    SUBMIT_APPRAISAL_SHEET_FORM_SUCCESS,
    SUBMIT_APPRAISAL_SHEET_FORM_FAILED,

    DELETE_APPRAISAL_SHEET,
    DELETE_APPRAISAL_SHEET_SUCCESS,
    DELETE_APPRAISAL_SHEET_FAILED,

    GET_CURRENT_APPRAISAL_SHEET,
    GET_CURRENT_APPRAISAL_SHEET_SUCCESS,
    GET_CURRENT_APPRAISAL_SHEET_FAILED,

    DOWNLOAD_APPRAISAL_SHEET,
    DOWNLOAD_APPRAISAL_SHEET_SUCCESS,
    DOWNLOAD_APPRAISAL_SHEET_FAILED
} from '../../constants/actionTypes';

const INIT_STATE = {
    loading: false,
    appraisalSheets: [],
    submittingForm: false,
    error: null,
    deleting: false,
    currentAppraisalSheet: null,
    downloading: false,
};

const AppraisalSheetReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_APPRAISAL_SHEETS:
            return { ...state, loading: true };
        case GET_APPRAISAL_SHEETS_SUCCESS:
            return { ...state, appraisalSheets: action.payload.data, total: action.payload.total, loading: false, error: null };
        case GET_APPRAISAL_SHEETS_FAILED:
            return { ...state, error: action.payload, loading: false };

        case SUBMIT_APPRAISAL_SHEET_FORM:
            return { ...state, error: null, submittingForm: true };
        case SUBMIT_APPRAISAL_SHEET_FORM_SUCCESS:
            const alreadyExists = state.appraisalSheets.find((item) => item.id === action.payload.id);
            let appraisalSheets;
            let total = state.total;
            if (!alreadyExists) {
                appraisalSheets = [action.payload, ...state.appraisalSheets];
                total += 1;
            } else {
                appraisalSheets = state.appraisalSheets.map((item) => {
                if (item.id === action.payload.id) {
                  return action.payload;
                }
                return item;
              });
            }
            return { ...state, total,  appraisalSheets, submittingForm: false };
        case SUBMIT_APPRAISAL_SHEET_FORM_FAILED:
            return { ...state, error: action.payload, submittingForm: false };

        case DELETE_APPRAISAL_SHEET:
            return { ...state, error: null, deleting: true };
        case DELETE_APPRAISAL_SHEET_SUCCESS:
            return { ...state, total: state.total - 1, appraisalSheets: state.appraisalSheets.filter(item => item.id !== action.payload) , deleting: false };
        case DELETE_APPRAISAL_SHEET_FAILED:
            return { ...state, error: action.payload, deleting: false };

        case GET_CURRENT_APPRAISAL_SHEET:
            return { ...state, loading: true };
        case GET_CURRENT_APPRAISAL_SHEET_SUCCESS:
            return { ...state, currentAppraisalSheet: action.payload, loading: false, error: null };
        case GET_CURRENT_APPRAISAL_SHEET_FAILED:
            return { ...state, error: action.payload, loading: false };
                
        case DOWNLOAD_APPRAISAL_SHEET:
            return { ...state, error: null, downloading: true };
        case DOWNLOAD_APPRAISAL_SHEET_SUCCESS:
            return { ...state, downloading: false };
        case DOWNLOAD_APPRAISAL_SHEET_FAILED:
            return { ...state, error: action.payload, downloading: false };
    
        

        default: return { ...state };
    }
}

export default AppraisalSheetReducer;