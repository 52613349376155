import {
    GET_STUDENTS,
    GET_STUDENTS_SUCCESS,
    GET_STUDENTS_FAILED,

    SUBMIT_STUDENT_FORM,
    SUBMIT_STUDENT_FORM_SUCCESS,
    SUBMIT_STUDENT_FORM_FAILED,

    DELETE_STUDENT,
    DELETE_STUDENT_SUCCESS,
    DELETE_STUDENT_FAILED,

    GET_CURRENT_STUDENT,
    GET_CURRENT_STUDENT_SUCCESS,
    GET_CURRENT_STUDENT_FAILED
} from '../../constants/actionTypes';


/** GET ALL */
export const getAllStudents = ({offset, limit, filter}) => ({
    type: GET_STUDENTS,
    payload: { offset, limit, filter }
});

export const getAllStudentsSuccess = ({total, data}) => ({
    type: GET_STUDENTS_SUCCESS,
    payload: {total, data}
});

export const getAllStudentsFailed = (error) => ({
    type: GET_STUDENTS_FAILED,
    payload: error
});

/** SUBMIT FORM */
export const submitStudentForm = (student) => ({
    type: SUBMIT_STUDENT_FORM,
    payload: student
});

export const submitStudentFormSuccess = (student) => ({
    type: SUBMIT_STUDENT_FORM_SUCCESS,
    payload: student
});

export const submitStudentFormFailed = (error) => ({
    type: SUBMIT_STUDENT_FORM_FAILED,
    payload: error
});

/** DELETE FORM */
export const deleteStudent = (id) => ({
    type: DELETE_STUDENT,
    payload: id
});

export const deleteStudentSuccess = (id) => ({
    type: DELETE_STUDENT_SUCCESS,
    payload: id
});

export const deleteStudentFailed = (error) => ({
    type: DELETE_STUDENT_FAILED,
    payload: error
});


/** GET CURRENT */
export const getCurrentStudent = (id) => ({
    type: GET_CURRENT_STUDENT,
    payload: id
});

export const getCurrentStudentSuccess = (student) => ({
    type: GET_CURRENT_STUDENT_SUCCESS,
    payload: student
});

export const getCurrentStudentFailed = (error) => ({
    type: GET_CURRENT_STUDENT_FAILED,
    payload: error
});