import {
    SUBMIT_CHANGE_PASSWORD_FORM,
    SUBMIT_CHANGE_PASSWORD_FORM_SUCCESS,
    SUBMIT_CHANGE_PASSWORD_FORM_FAILED,
} from '../../constants/actionTypes';

const INIT_STATE = {
    submittingChangePasswordForm: false,
    error: null,
};

const UserReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case SUBMIT_CHANGE_PASSWORD_FORM:
            return { ...state, error: null, submittingChangePasswordForm: true };
        case SUBMIT_CHANGE_PASSWORD_FORM_SUCCESS:
            return { ...state, error: null, submittingChangePasswordForm: false };
        case SUBMIT_CHANGE_PASSWORD_FORM_FAILED:
            return { ...state, error: action.payload, submittingChangePasswordForm: false };
            
        default: return { ...state };
    }
}

export default UserReducer;