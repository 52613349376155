import {
    GET_EMPLOYEE_ONBOARDINGS,
    GET_EMPLOYEE_ONBOARDINGS_SUCCESS,
    GET_EMPLOYEE_ONBOARDINGS_FAILED,

    SUBMIT_EMPLOYEE_ONBOARDING_FORM,
    SUBMIT_EMPLOYEE_ONBOARDING_FORM_SUCCESS,
    SUBMIT_EMPLOYEE_ONBOARDING_FORM_FAILED,

    DELETE_EMPLOYEE_ONBOARDING,
    DELETE_EMPLOYEE_ONBOARDING_SUCCESS,
    DELETE_EMPLOYEE_ONBOARDING_FAILED,

    GET_CURRENT_EMPLOYEE_ONBOARDING,
    GET_CURRENT_EMPLOYEE_ONBOARDING_SUCCESS,
    GET_CURRENT_EMPLOYEE_ONBOARDING_FAILED,

    DOWNLOAD_EMPLOYEE_ONBOARDING,
    DOWNLOAD_EMPLOYEE_ONBOARDING_SUCCESS,
    DOWNLOAD_EMPLOYEE_ONBOARDING_FAILED
} from '../../constants/actionTypes'; 


/** GET ALL */
export const getAllEmployeeOnboardings = ({offset, limit, filter}) => ({
    type: GET_EMPLOYEE_ONBOARDINGS,
    payload: { offset, limit, filter }
});

export const getAllEmployeeOnboardingsSuccess = ({total, data}) => ({
    type: GET_EMPLOYEE_ONBOARDINGS_SUCCESS,
    payload: {total, data}
});

export const getAllEmployeeOnboardingsFailed = (error) => ({
    type: GET_EMPLOYEE_ONBOARDINGS_FAILED,
    payload: error
});

/** SUBMIT FORM */
export const submitEmployeeOnboardingForm = (item) => ({
    type: SUBMIT_EMPLOYEE_ONBOARDING_FORM,
    payload: item
});

export const submitEmployeeOnboardingFormSuccess = (item) => ({
    type: SUBMIT_EMPLOYEE_ONBOARDING_FORM_SUCCESS,
    payload: item
});

export const submitEmployeeOnboardingFormFailed = (error) => ({
    type: SUBMIT_EMPLOYEE_ONBOARDING_FORM_FAILED,
    payload: error
});

/** DELETE FORM */
export const deleteEmployeeOnboarding = (id) => ({
    type: DELETE_EMPLOYEE_ONBOARDING,
    payload: id
});

export const deleteEmployeeOnboardingSuccess = (id) => ({
    type: DELETE_EMPLOYEE_ONBOARDING_SUCCESS,
    payload: id
});

export const deleteEmployeeOnboardingFailed = (error) => ({
    type: DELETE_EMPLOYEE_ONBOARDING_FAILED,
    payload: error
});

/** GET CURRENT */
export const getCurrentEmployeeOnboarding = (id) => ({
    type: GET_CURRENT_EMPLOYEE_ONBOARDING,
    payload: id
});

export const getCurrentEmployeeOnboardingSuccess = (employeeOnboarding) => ({
    type: GET_CURRENT_EMPLOYEE_ONBOARDING_SUCCESS,
    payload: employeeOnboarding
});

export const getCurrentEmployeeOnboardingFailed = (error) => ({
    type: GET_CURRENT_EMPLOYEE_ONBOARDING_FAILED,
    payload: error
});

/** DOWNLOAD */
export const downloadEmployeeOnboarding = (id) => ({
    type: DOWNLOAD_EMPLOYEE_ONBOARDING,
    payload: id
});

export const downloadEmployeeOnboardingSuccess = () => ({
    type: DOWNLOAD_EMPLOYEE_ONBOARDING_SUCCESS
});

export const downloadEmployeeOnboardingFailed = (error) => ({
    type: DOWNLOAD_EMPLOYEE_ONBOARDING_FAILED,
    payload: error
});