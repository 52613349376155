import {
    GET_TRAINING_PROVIDERS,
    GET_TRAINING_PROVIDERS_SUCCESS,
    GET_TRAINING_PROVIDERS_FAILED,

    SUBMIT_TRAINING_PROVIDER_FORM,
    SUBMIT_TRAINING_PROVIDER_FORM_SUCCESS,
    SUBMIT_TRAINING_PROVIDER_FORM_FAILED,

    DELETE_TRAINING_PROVIDER,
    DELETE_TRAINING_PROVIDER_SUCCESS,
    DELETE_TRAINING_PROVIDER_FAILED,
} from '../../constants/actionTypes';


/** GET ALL */
export const getAllTrainingProviders = ({offset, limit}) => ({
    type: GET_TRAINING_PROVIDERS,
    payload: { offset, limit }
});

export const getAllTrainingProvidersSuccess = ({total, data}) => ({
    type: GET_TRAINING_PROVIDERS_SUCCESS,
    payload: {total, data}
});

export const getAllTrainingProvidersFailed = (error) => ({
    type: GET_TRAINING_PROVIDERS_FAILED,
    payload: error
});

/** SUBMIT FORM */
export const submitTrainingProviderForm = (item) => ({
    type: SUBMIT_TRAINING_PROVIDER_FORM,
    payload: item
});

export const submitTrainingProviderFormSuccess = (item) => ({
    type: SUBMIT_TRAINING_PROVIDER_FORM_SUCCESS,
    payload: item
});

export const submitTrainingProviderFormFailed = (error) => ({
    type: SUBMIT_TRAINING_PROVIDER_FORM_FAILED,
    payload: error
});

/** DELETE FORM */
export const deleteTrainingProvider = (id) => ({
    type: DELETE_TRAINING_PROVIDER,
    payload: id
});

export const deleteTrainingProviderSuccess = (id) => ({
    type: DELETE_TRAINING_PROVIDER_SUCCESS,
    payload: id
});

export const deleteTrainingProviderFailed = (error) => ({
    type: DELETE_TRAINING_PROVIDER_FAILED,
    payload: error
});