import {
    GET_APPRAISAL_FACTORS,
    GET_APPRAISAL_FACTORS_SUCCESS,
    GET_APPRAISAL_FACTORS_FAILED,

    SUBMIT_APPRAISAL_FACTOR_FORM,
    SUBMIT_APPRAISAL_FACTOR_FORM_SUCCESS,
    SUBMIT_APPRAISAL_FACTOR_FORM_FAILED,

    DELETE_APPRAISAL_FACTOR,
    DELETE_APPRAISAL_FACTOR_SUCCESS,
    DELETE_APPRAISAL_FACTOR_FAILED,
} from '../../constants/actionTypes'; 


/** GET ALL */
export const getAllAppraisalFactors = ({offset, limit, filter}) => ({
    type: GET_APPRAISAL_FACTORS,
    payload: { offset, limit, filter }
});

export const getAllAppraisalFactorsSuccess = ({total, data}) => ({
    type: GET_APPRAISAL_FACTORS_SUCCESS,
    payload: {total, data}
});

export const getAllAppraisalFactorsFailed = (error) => ({
    type: GET_APPRAISAL_FACTORS_FAILED,
    payload: error
});

/** SUBMIT FORM */
export const submitAppraisalFactorForm = (item) => ({
    type: SUBMIT_APPRAISAL_FACTOR_FORM,
    payload: item
});

export const submitAppraisalFactorFormSuccess = (item) => ({
    type: SUBMIT_APPRAISAL_FACTOR_FORM_SUCCESS,
    payload: item
});

export const submitAppraisalFactorFormFailed = (error) => ({
    type: SUBMIT_APPRAISAL_FACTOR_FORM_FAILED,
    payload: error
});

/** DELETE FORM */
export const deleteAppraisalFactor = (id) => ({
    type: DELETE_APPRAISAL_FACTOR,
    payload: id
});

export const deleteAppraisalFactorSuccess = (id) => ({
    type: DELETE_APPRAISAL_FACTOR_SUCCESS,
    payload: id
});

export const deleteAppraisalFactorFailed = (error) => ({
    type: DELETE_APPRAISAL_FACTOR_FAILED,
    payload: error
});