import {
    GET_ROLES,
    GET_ROLES_SUCCESS,
    GET_ROLES_FAILED,

    SUBMIT_ROLE_FORM,
    SUBMIT_ROLE_FORM_SUCCESS,
    SUBMIT_ROLE_FORM_FAILED,

    DELETE_ROLE,
    DELETE_ROLE_SUCCESS,
    DELETE_ROLE_FAILED,

    GET_CURRENT_ROLE,
    GET_CURRENT_ROLE_SUCCESS,
    GET_CURRENT_ROLE_FAILED
} from '../../constants/actionTypes';


/** GET ALL */
export const getAllRoles = ({offset, limit}) => ({
    type: GET_ROLES,
    payload: { offset, limit }
});

export const getAllRolesSuccess = ({total, data}) => ({
    type: GET_ROLES_SUCCESS,
    payload: {total, data}
});

export const getAllRolesFailed = (error) => ({
    type: GET_ROLES_FAILED,
    payload: error
});

/** SUBMIT FORM */
export const submitRoleForm = (country) => ({
    type: SUBMIT_ROLE_FORM,
    payload: country
});

export const submitRoleFormSuccess = (role) => ({
    type: SUBMIT_ROLE_FORM_SUCCESS,
    payload: role
});

export const submitRoleFormFailed = (error) => ({
    type: SUBMIT_ROLE_FORM_FAILED,
    payload: error
});

/** DELETE FORM */
export const deleteRole = (id) => ({
    type: DELETE_ROLE,
    payload: id
});

export const deleteRoleSuccess = (id) => ({
    type: DELETE_ROLE_SUCCESS,
    payload: id
});

export const deleteRoleFailed = (error) => ({
    type: DELETE_ROLE_FAILED,
    payload: error
});

/** GET CURRENT */
export const getCurrentRole = (id) => ({
    type: GET_CURRENT_ROLE,
    payload: id
});

export const getCurrentRoleSuccess = (role) => ({
    type: GET_CURRENT_ROLE_SUCCESS,
    payload: role
});

export const getCurrentRoleFailed = (error) => ({
    type: GET_CURRENT_ROLE_FAILED,
    payload: error
});