import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { fetchJSON, apiRootEndpoint } from '../../helpers';
import {
    GET_TELEGRAM_GROUPS,
    SUBMIT_TELEGRAM_GROUP_FORM,
    DELETE_TELEGRAM_GROUP
} from '../../constants/actionTypes';


import {
    getAllTelegramGroupsSuccess,
    getAllTelegramGroupsFailed,

    submitTelegramGroupFormSuccess,
    submitTelegramGroupFormFailed,

    deleteTelegramGroupSuccess,
    deleteTelegramGroupFailed,
} from './actions';

const apiUrl = `${apiRootEndpoint}/api/v1/telegram-groups`; 

function* getAllTelegramGroups({ payload: { offset, limit, filter } }) {
    const options = {
        method: 'GET',
    };

    try {
        let url = `${apiUrl}?offset=${offset}&limit=${limit}`;
    
        if(filter.branchId) {
            url = `${url}&branchId=${filter.branchId}`;
        }
        const {total, data} = yield call(fetchJSON, url, options);
        
        yield put(getAllTelegramGroupsSuccess({total, data}));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(getAllTelegramGroupsFailed(message));
    }
}



function* submitTelegramGroup({ payload: item }) {
    const options = {
        body: JSON.stringify(item),
        method: item.id ? 'PUT' :  'POST',
        headers: { 'Content-Type': 'application/json' }
    };

    try {
        let url = `${apiUrl}`;
        if(item.id) {
            url = `${apiUrl}/${item.id}`;
        }
        const response = yield call(fetchJSON, url, options);
        if(response.status === 'OK') {
            yield put(submitTelegramGroupFormSuccess(response.data));
        }
        else {
            yield put(submitTelegramGroupFormFailed(response.message));
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(submitTelegramGroupFormFailed(message));
    }
}

function* deleteTelegramGroup({ payload: id }) {
    const options = {
        method: 'DELETE',
    };
    try {
        const url = `${apiUrl}/${id}`;
        const response = yield call(fetchJSON, url, options);
        if(response.status === 'OK') {
            yield put(deleteTelegramGroupSuccess(id));
        }
        else {
            yield put(deleteTelegramGroupFailed(response.message));
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(deleteTelegramGroupFailed(message));
    }
}


export function* watchGetAllTelegramGroups() {
    yield takeEvery(GET_TELEGRAM_GROUPS, getAllTelegramGroups);
}
export function* watchSubmitTelegramGroupForm() {
    yield takeEvery(SUBMIT_TELEGRAM_GROUP_FORM, submitTelegramGroup);
}
export function* watchDeleteTelegramGroup() {
    yield takeEvery(DELETE_TELEGRAM_GROUP, deleteTelegramGroup);
}


function* telegramGroupSaga() {
    yield all([
        fork(watchGetAllTelegramGroups),
        fork(watchSubmitTelegramGroupForm),
        fork(watchDeleteTelegramGroup)
    ]);
}

export default telegramGroupSaga;