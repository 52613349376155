import {
    GET_CREDIT_NOTES,
    GET_CREDIT_NOTES_SUCCESS,
    GET_CREDIT_NOTES_FAILED,

    SUBMIT_CREDIT_NOTE_FORM,
    SUBMIT_CREDIT_NOTE_FORM_SUCCESS,
    SUBMIT_CREDIT_NOTE_FORM_FAILED,

    DELETE_CREDIT_NOTE,
    DELETE_CREDIT_NOTE_SUCCESS,
    DELETE_CREDIT_NOTE_FAILED,

    VERIFY_CREDIT_NOTE_STUDENT,
    VERIFY_CREDIT_NOTE_STUDENT_SUCCESS,
    VERIFY_CREDIT_NOTE_STUDENT_FAILED,

    SUBMIT_CREDIT_NOTE_BANK_DETAILS_FORM,
    VERIFY_CREDIT_NOTE_BANK_DETAILS_SUCCESS,
    VERIFY_CREDIT_NOTE_BANK_DETAILS_FAILED,


    SEND_CREDIT_NOTE_EMAIL,
    SEND_CREDIT_NOTE_EMAIL_SUCCESS,
    SEND_CREDIT_NOTE_EMAIL_FAILED,

    EXPORT_CREDIT_NOTES,
    EXPORT_CREDIT_NOTES_SUCCESS,
    EXPORT_CREDIT_NOTES_FAILED,

    DOWNLOAD_CREDIT_NOTE,
    DOWNLOAD_CREDIT_NOTE_SUCCESS,
    DOWNLOAD_CREDIT_NOTE_FAILED,

    
    DOWNLOAD_SELECTED_CREDIT_NOTES,
    DOWNLOAD_SELECTED_CREDIT_NOTES_SUCCESS,
    DOWNLOAD_SELECTED_CREDIT_NOTE_FAILED,

    TOGGLE_ALL_CREDIT_NOTES,

    TOGGLE_CREDIT_NOTE,
} from '../../constants/actionTypes';

const INIT_STATE = {
    loading: false,
    creditNotes: [],
    submittingForm: false,
    error: null,
    deleting: false,
    currentCreditNote: null,
    submittingBankDetails: false,
    sendingEmail: false,
    exporting: false,
    downloading: false,
    checkedAll: false,
};

const CreditNotesReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_CREDIT_NOTES:
            return { ...state, loading: true };
        case GET_CREDIT_NOTES_SUCCESS:
            return { ...state, creditNotes: action.payload.data, total: action.payload.total, loading: false, error: null };
        case GET_CREDIT_NOTES_FAILED:
            return { ...state, error: action.payload, loading: false };

        case SUBMIT_CREDIT_NOTE_FORM:
            return { ...state, error: null, submittingForm: true };
        case SUBMIT_CREDIT_NOTE_FORM_SUCCESS:
            const alreadyExists = state.creditNotes.find((creditNote) => creditNote.id === action.payload.id);
            let creditNotes;
            let total = state.total;
            if (!alreadyExists) {
                creditNotes = [action.payload, ...state.creditNotes];
                total += 1;
            } else {
                creditNotes = state.creditNotes.map((creditNote) => {
                if (creditNote.id === action.payload.id) {
                  return action.payload;
                }
                return creditNote;
              });
            }
            return { ...state, total,  creditNotes, submittingForm: false };
        case SUBMIT_CREDIT_NOTE_FORM_FAILED:
            return { ...state, error: action.payload, submittingForm: false };

        case DELETE_CREDIT_NOTE:
            return { ...state, error: null, deleting: true };
        case DELETE_CREDIT_NOTE_SUCCESS:
            return { ...state, total: state.total - 1, creditNotes: state.creditNotes.filter(creditNote => creditNote.id !== action.payload) , deleting: false };
        case DELETE_CREDIT_NOTE_FAILED:
            return { ...state, error: action.payload, deleting: false };

        case VERIFY_CREDIT_NOTE_STUDENT:
            return { ...state, error: null, submittingForm: true };
        case VERIFY_CREDIT_NOTE_STUDENT_SUCCESS:
            return { ...state, currentCreditNote: action.payload, submittingForm: false };
        case VERIFY_CREDIT_NOTE_STUDENT_FAILED:
            return { ...state, error: action.payload, submittingForm: false };

        case SUBMIT_CREDIT_NOTE_BANK_DETAILS_FORM:
            return { ...state, error: null, submittingBankDetails: true };
        case VERIFY_CREDIT_NOTE_BANK_DETAILS_SUCCESS:
            return { ...state, submittingBankDetails: false };
        case VERIFY_CREDIT_NOTE_BANK_DETAILS_FAILED:
            return { ...state, error: action.payload, submittingBankDetails: false };

        case SEND_CREDIT_NOTE_EMAIL:
            return { ...state, error: null, sendingEmail: true };
        case SEND_CREDIT_NOTE_EMAIL_SUCCESS:
            const newItems = state.creditNotes.map(creditNote => {
                    if(creditNote.id === action.payload.id) {
                        return action.payload;
                    }
                    else {
                        return creditNote;
                    }
                });
            return { ...state, sendingEmail: false, creditNotes: newItems  };
        case SEND_CREDIT_NOTE_EMAIL_FAILED:
            return { ...state, error: action.payload, sendingEmail: false };

        case EXPORT_CREDIT_NOTES:
            return { ...state, error: null, exporting: true };
        case EXPORT_CREDIT_NOTES_SUCCESS:
            return { ...state, exporting: false };
        case EXPORT_CREDIT_NOTES_FAILED:
            return { ...state, error: action.payload, exporting: false };

        case DOWNLOAD_CREDIT_NOTE:
            return { ...state, error: null, downloading: true };
        case DOWNLOAD_CREDIT_NOTE_SUCCESS:
            return { ...state, downloading: false };
        case DOWNLOAD_CREDIT_NOTE_FAILED:
            return { ...state, error: action.payload, downloading: false };


        case DOWNLOAD_SELECTED_CREDIT_NOTES:
            return { ...state, error: null, downloading: true };
        case DOWNLOAD_SELECTED_CREDIT_NOTES_SUCCESS:
            return { ...state, downloading: false };
        case DOWNLOAD_SELECTED_CREDIT_NOTE_FAILED:
            return { ...state, error: action.payload, downloading: false };

        case TOGGLE_ALL_CREDIT_NOTES:
            return { ...state, checkedAll: action.payload, creditNotes: state.creditNotes.map(creditNote => ({...creditNote, checked: action.payload})) };

        case TOGGLE_CREDIT_NOTE:
            return { ...state, checkedAll: false, creditNotes: state.creditNotes.map(creditNote => {
                if(creditNote.id === action.payload.id) {
                    return ({...creditNote, checked: action.payload.checked});
                }
                return creditNote;
            }) };

        default: return { ...state };
    }
}

export default CreditNotesReducer;