import {
    GET_BRANCHES,
    GET_BRANCHES_SUCCESS,
    GET_BRANCHES_FAILED,

    SUBMIT_BRANCH_FORM,
    SUBMIT_BRANCH_FORM_SUCCESS,
    SUBMIT_BRANCH_FORM_FAILED,

    DELETE_BRANCH,
    DELETE_BRANCH_SUCCESS,
    DELETE_BRANCH_FAILED,
} from '../../constants/actionTypes';


/** GET ALL */
export const getAllBranches = ({offset, limit}) => ({
    type: GET_BRANCHES,
    payload: { offset, limit }
});

export const getAllBranchesSuccess = ({total, data}) => ({
    type: GET_BRANCHES_SUCCESS,
    payload: {total, data}
});

export const getAllBranchesFailed = (error) => ({
    type: GET_BRANCHES_FAILED,
    payload: error
});

/** SUBMIT FORM */
export const submitBranchForm = (branch) => ({
    type: SUBMIT_BRANCH_FORM,
    payload: branch
});

export const submitBranchFormSuccess = (branch) => ({
    type: SUBMIT_BRANCH_FORM_SUCCESS,
    payload: branch
});

export const submitBranchFormFailed = (error) => ({
    type: SUBMIT_BRANCH_FORM_FAILED,
    payload: error
});

/** DELETE FORM */
export const deleteBranch = (id) => ({
    type: DELETE_BRANCH,
    payload: id
});

export const deleteBranchSuccess = (id) => ({
    type: DELETE_BRANCH_SUCCESS,
    payload: id
});

export const deleteBranchFailed = (error) => ({
    type: DELETE_BRANCH_FAILED,
    payload: error
});