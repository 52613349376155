import {
    GET_TELEGRAM_GROUPS,
    GET_TELEGRAM_GROUPS_SUCCESS,
    GET_TELEGRAM_GROUPS_FAILED,

    SUBMIT_TELEGRAM_GROUP_FORM,
    SUBMIT_TELEGRAM_GROUP_FORM_SUCCESS,
    SUBMIT_TELEGRAM_GROUP_FORM_FAILED,

    DELETE_TELEGRAM_GROUP,
    DELETE_TELEGRAM_GROUP_SUCCESS,
    DELETE_TELEGRAM_GROUP_FAILED,
} from '../../constants/actionTypes';


/** GET ALL TELEGRAM GROUPS */
export const getAllTelegramGroups = ({offset, limit, filter}) => ({
    type: GET_TELEGRAM_GROUPS,
    payload: { offset, limit, filter }
});

export const getAllTelegramGroupsSuccess = ({total, data}) => ({
    type: GET_TELEGRAM_GROUPS_SUCCESS,
    payload: {total, data}
});

export const getAllTelegramGroupsFailed = (error) => ({
    type: GET_TELEGRAM_GROUPS_FAILED,
    payload: error
});




/** SUBMIT FORM */
export const submitTelegramGroupForm = (timesheet) => ({
    type: SUBMIT_TELEGRAM_GROUP_FORM,
    payload: timesheet
});

export const submitTelegramGroupFormSuccess = (user) => ({
    type: SUBMIT_TELEGRAM_GROUP_FORM_SUCCESS,
    payload: user
});

export const submitTelegramGroupFormFailed = (error) => ({
    type: SUBMIT_TELEGRAM_GROUP_FORM_FAILED,
    payload: error
});

/** DELETE ITEM */
export const deleteTelegramGroup = (id) => ({
    type: DELETE_TELEGRAM_GROUP,
    payload: id
});

export const deleteTelegramGroupSuccess = (id) => ({
    type: DELETE_TELEGRAM_GROUP_SUCCESS,
    payload: id
});

export const deleteTelegramGroupFailed = (error) => ({
    type: DELETE_TELEGRAM_GROUP_FAILED,
    payload: error
});