import {
    GET_TELEGRAM_COES,
    GET_TELEGRAM_COES_SUCCESS,
    GET_TELEGRAM_COES_FAILED,

    GET_TELEGRAM_USERS,
    GET_TELEGRAM_USERS_SUCCESS,
    GET_TELEGRAM_USERS_FAILED,

    GET_TELEGRAM_COLLEGES,
    GET_TELEGRAM_COLLEGES_SUCCESS,
    GET_TELEGRAM_COLLEGES_FAILED,

    GET_TELEGRAM_REPORT,
    GET_TELEGRAM_REPORT_SUCCESS,
    GET_TELEGRAM_REPORT_FAILED,

    EXPORT_TELEGRAM_COES,
    EXPORT_TELEGRAM_COES_SUCCESS,
    EXPORT_TELEGRAM_COES_FAILED,

    SUBMIT_TELEGRAM_COE_FORM,
    SUBMIT_TELEGRAM_COE_FORM_SUCCESS,
    SUBMIT_TELEGRAM_COE_FORM_FAILED
} from '../../constants/actionTypes';


/** GET ALL COES */
export const getAllTelegramCoes = ({offset, limit, filter}) => ({
    type: GET_TELEGRAM_COES,
    payload: { offset, limit, filter }
});

export const getAllTelegramCoesSuccess = ({total, data}) => ({
    type: GET_TELEGRAM_COES_SUCCESS,
    payload: {total, data}
});

export const getAllTelegramCoesFailed = (error) => ({
    type: GET_TELEGRAM_COES_FAILED,
    payload: error
});

/** GET ALL USERS */
export const getAllTelegramUsers = () => ({
    type: GET_TELEGRAM_USERS
});

export const getAllTelegramUsersSuccess = ({total, data}) => ({
    type: GET_TELEGRAM_USERS_SUCCESS,
    payload: {total, data}
});

export const getAllTelegramUsersFailed = (error) => ({
    type: GET_TELEGRAM_USERS_FAILED,
    payload: error
});

/** GET ALL COLLEGES */
export const getAllTelegramColleges = () => ({
    type: GET_TELEGRAM_COLLEGES
});

export const getAllTelegramCollegesSuccess = ({total, data}) => ({
    type: GET_TELEGRAM_COLLEGES_SUCCESS,
    payload: {total, data}
});

export const getAllTelegramCollegesFailed = (error) => ({
    type: GET_TELEGRAM_COLLEGES_FAILED,
    payload: error
});



/** GET REPORT */
export const getTelegramReport = (filter) => ({
    type: GET_TELEGRAM_REPORT,
    payload: filter
});

export const getTelegramReportSuccess = ({data}) => ({
    type: GET_TELEGRAM_REPORT_SUCCESS,
    payload: {data}
});

export const getTelegramReportFailed = (error) => ({
    type: GET_TELEGRAM_REPORT_FAILED,
    payload: error
});



/** GET REPORT */
export const exportTelegramCoes = (filter) => ({
    type: EXPORT_TELEGRAM_COES,
    payload: filter
});

export const exportTelegramCoesSuccess = () => ({
    type: EXPORT_TELEGRAM_COES_SUCCESS
});

export const exportTelegramCoesFailed = (error) => ({
    type: EXPORT_TELEGRAM_COES_FAILED,
    payload: error
});



/** SUBMIT FORM */
export const submitTelegramCoeForm = (coe) => ({
    type: SUBMIT_TELEGRAM_COE_FORM,
    payload: coe
});

export const submitTelegramCoeFormSuccess = (coe) => ({
    type: SUBMIT_TELEGRAM_COE_FORM_SUCCESS,
    payload: coe
});

export const submitTelegramCoeFormFailed = (error) => ({
    type: SUBMIT_TELEGRAM_COE_FORM_FAILED,
    payload: error
});
