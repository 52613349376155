import {
    GET_COURSES,
    GET_COURSES_SUCCESS,
    GET_COURSES_FAILED,

    SUBMIT_COURSE_FORM,
    SUBMIT_COURSE_FORM_SUCCESS,
    SUBMIT_COURSE_FORM_FAILED,

    DELETE_COURSE,
    DELETE_COURSE_SUCCESS,
    DELETE_COURSE_FAILED,
} from '../../constants/actionTypes';

const INIT_STATE = {
    loading: false,
    courses: [],
    submittingForm: false,
    error: null,
    deleting: false
};

const CourseReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_COURSES:
            return { ...state, loading: true };
        case GET_COURSES_SUCCESS:
            return { ...state, courses: action.payload.data, total: action.payload.total, loading: false, error: null };
        case GET_COURSES_FAILED:
            return { ...state, error: action.payload, loading: false };

        case SUBMIT_COURSE_FORM:
            return { ...state, error: null, submittingForm: true };
        case SUBMIT_COURSE_FORM_SUCCESS:
            const alreadyExists = state.courses.find((course) => course.id === action.payload.id);
            let courses;
            let total = state.total;
            if (!alreadyExists) {
                courses = [action.payload, ...state.courses];
                total += 1;
            } else {
                courses = state.courses.map((course) => {
                if (course.id === action.payload.id) {
                  return action.payload;
                }
                return course;
              });
            }
            return { ...state, total,  courses, submittingForm: false };
        case SUBMIT_COURSE_FORM_FAILED:
            return { ...state, error: action.payload, submittingForm: false };

        case DELETE_COURSE:
            return { ...state, error: null, deleting: true };
        case DELETE_COURSE_SUCCESS:
            return { ...state, total: state.total - 1, courses: state.courses.filter(course => course.id !== action.payload) , deleting: false };
        case DELETE_COURSE_FAILED:
            return { ...state, error: action.payload, deleting: false };
        default: return { ...state };
    }
}

export default CourseReducer;