import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { fetchJSON, apiRootEndpoint } from '../../helpers';
import {
    GET_APPRAISAL_SHEETS, 
    SUBMIT_APPRAISAL_SHEET_FORM,
    DELETE_APPRAISAL_SHEET,
    GET_CURRENT_APPRAISAL_SHEET,
    DOWNLOAD_APPRAISAL_SHEET
} from '../../constants/actionTypes';


import {
    getAllAppraisalSheetsSuccess,
    getAllAppraisalSheetsFailed,

    submitAppraisalSheetFormSuccess,
    submitAppraisalSheetFormFailed,

    deleteAppraisalSheetSuccess,
    deleteAppraisalSheetFailed,

    getCurrentAppraisalSheetSuccess,
    getCurrentAppraisalSheetFailed,

    downloadAppraisalSheetSuccess,
    downloadAppraisalSheetFailed
} from './actions';

const apiUrl = `${apiRootEndpoint}/api/v1/appraisal-sheets`;

function* getAllAppraisalSheets({ payload: { offset, limit, filter } }) {
    const options = {
        method: 'GET',
    };

    try {
        let url = `${apiUrl}?offset=${offset}&limit=${limit}`;
        if(filter.query) {
            url = `${url}&query=${filter.query}`
        }
        const {total, data} = yield call(fetchJSON, url, options);
        yield put(getAllAppraisalSheetsSuccess({total, data}));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(getAllAppraisalSheetsFailed(message));
    }
}

function* submitAppraisalSheet({ payload: item }) {
    const options = {
        body: JSON.stringify(item),
        method: item.id ? 'PUT' :  'POST',
        headers: { 'Content-Type': 'application/json' }
    };

    try {
        let url = `${apiUrl}`;
        if(item.id) {
            url = `${apiUrl}/${item.id}`;
        }
        const response = yield call(fetchJSON, url, options);
        if(response.status === 'OK') {
            yield put(submitAppraisalSheetFormSuccess(response.data));
        }
        else {
            yield put(submitAppraisalSheetFormFailed(response.message));
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(submitAppraisalSheetFormFailed(message));
    }
}

function* deleteAppraisalSheet({ payload: id }) {
    const options = {
        method: 'DELETE',
    };
    try {
        const url = `${apiUrl}/${id}`;
        const response = yield call(fetchJSON, url, options);
        
        if(response.status === "OK") {
            yield put(deleteAppraisalSheetSuccess(id));
        }
        else {
            yield put(deleteAppraisalSheetFailed(response.message));
        }
    } catch (error) {
       
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(deleteAppraisalSheetFailed(message));
    }
}


function* getCurrentAppraisalSheet({ payload: id }) {
    const options = {
        method: 'GET',
    };
    
    try {
        let url = `${apiUrl}/${id}`;
       
        const item = yield call(fetchJSON, url, options);
        yield put(getCurrentAppraisalSheetSuccess(item.data));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(getCurrentAppraisalSheetFailed(message));
    }
}



function* downloadAappraisalSheet({ payload: id }) {
    const options = {
        method: 'GET',
    };
    try {
        const url = `${apiUrl}/${id}/download`;
        const response = yield call(fetchJSON, url, options);
        
        if(response.status === "OK") {
            if(response.url) {
                window.open(response.url);
            }
            yield put(downloadAppraisalSheetSuccess());
        }
        else {
            yield put(downloadAppraisalSheetFailed(response.message));
        }
    } catch (error) {
       
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(downloadAppraisalSheetFailed(message));
    }
}




export function* watchGetAllAppraisalSheets() {
    yield takeEvery(GET_APPRAISAL_SHEETS, getAllAppraisalSheets);
}
export function* watchSubmitAppraisalSheetForm() {
    yield takeEvery(SUBMIT_APPRAISAL_SHEET_FORM, submitAppraisalSheet);
}
export function* watchDeleteAppraisalSheet() {
    yield takeEvery(DELETE_APPRAISAL_SHEET, deleteAppraisalSheet);
}
export function* watchGetCurrentAppraisalSheet() {
    yield takeEvery(GET_CURRENT_APPRAISAL_SHEET, getCurrentAppraisalSheet);
}
export function* watchDownloadAppraisalSheet() {
    yield takeEvery(DOWNLOAD_APPRAISAL_SHEET, downloadAappraisalSheet);
}

function* appraisalSheetSaga() {
    yield all([
        fork(watchGetAllAppraisalSheets),
        fork(watchSubmitAppraisalSheetForm),
        fork(watchDeleteAppraisalSheet),
        fork(watchGetCurrentAppraisalSheet),
        fork(watchDownloadAppraisalSheet),
    ]);
}

export default appraisalSheetSaga;