import {
    SUBMIT_OCR_FORM,
    SUBMIT_OCR_FORM_SUCCESS,
    SUBMIT_OCR_FORM_FAILED,

    SUBMIT_CRM_CLIENT_FORM,
    SUBMIT_CRM_CLIENT_FORM_SUCCESS,
    SUBMIT_CRM_CLIENT_FORM_FAILED
} from '../../constants/actionTypes';

/** SUBMIT FORM */
export const submitOCRForm = (branch) => ({
    type: SUBMIT_OCR_FORM,
    payload: branch
});

export const submitOCRFormSuccess = (branch) => ({
    type: SUBMIT_OCR_FORM_SUCCESS,
    payload: branch
});

export const submitOCRFormFailed = (error) => ({
    type: SUBMIT_OCR_FORM_FAILED,
    payload: error
});


/** SUBMIT CRM FORM */
export const submitCRMClientForm = (data) => ({
    type: SUBMIT_CRM_CLIENT_FORM,
    payload: data
});

export const submitCRMClientFormSuccess = (data) => ({
    type: SUBMIT_CRM_CLIENT_FORM_SUCCESS,
    payload: data
});

export const submitCRMClientFormFailed = (error) => ({
    type: SUBMIT_CRM_CLIENT_FORM_FAILED,
    payload: error
});
