import { combineReducers } from 'redux';
import Auth from './auth/reducers';
import User from './user/reducers';
import Timesheet from './timesheet/reducers';
import Task from './task/reducers';
import AuthUser from './authUser/reducers';
import Country from './country/reducers';
import Branch from './branch/reducers';
import Student from './student/reducers';
import Institute from './institute/reducers';
import Course from './course/reducers';
import CreditNote from './creditNote/reducers';
import supportFundStudent from './supportFundStudent/reducers';
import supportFundOrganization from './supportFundOrganization/reducers';
import AppraisalFactor from './appraisalFactor/reducers';
import AppraisalSheet from './appraisalSheet/reducers';
import Appraisal from './appraisal/reducers';
import AppraisalFeedback from './appraisalFeedback/reducers';
import Department from './department/reducers';
import Role from './role/reducers';
import EmployeeOnboarding from './employeeOnboarding/reducers';
import Employee from './employee/reducers';
import CoeEmail from './coeEmail/reducers';
import Coe from './coe/reducers';
import OCR from './ocr/reducers';
import EmploymentContract from './employmentContract/reducers';
import Event from './event/reducers';
import EventParticipant from './eventParticipant/reducers';
import TrainingProvider from './trainingProvider/reducers';
import TrainingProgram from './trainingProgram/reducers';
import EmployeeTraining from './employeeTraining/reducers';
import LeaveRequest from './leaveRequest/reducers';
import TelegramCoe from './telegramCoe/reducers';
import TelegramGroup from './telegramGroup/reducers';
import Target from './target/reducers';

export default combineReducers({
    Auth,
    User,
    Timesheet,
    Task,
    AuthUser,
    Country,
    Branch,
    Student,
    Institute,
    Course,
    CreditNote,
    supportFundStudent,
    supportFundOrganization,
    AppraisalFactor,
    AppraisalSheet,
    Appraisal,
    AppraisalFeedback,
    Department,
    Role,
    EmployeeOnboarding,
    Employee,
    CoeEmail,
    Coe,
    OCR,
    EmploymentContract,
    Event,
    EventParticipant,
    TrainingProvider,
    TrainingProgram,
    EmployeeTraining,
    LeaveRequest,
    TelegramCoe,
    TelegramGroup,
    Target
});