import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { fetchJSON, apiRootEndpoint } from '../../helpers';
import {
    GET_EMPLOYEE_ONBOARDINGS, 
    SUBMIT_EMPLOYEE_ONBOARDING_FORM,
    DELETE_EMPLOYEE_ONBOARDING,
    GET_CURRENT_EMPLOYEE_ONBOARDING,
    DOWNLOAD_EMPLOYEE_ONBOARDING
} from '../../constants/actionTypes';


import {
    getAllEmployeeOnboardingsSuccess,
    getAllEmployeeOnboardingsFailed,

    submitEmployeeOnboardingFormSuccess,
    submitEmployeeOnboardingFormFailed,

    deleteEmployeeOnboardingSuccess,
    deleteEmployeeOnboardingFailed,

    getCurrentEmployeeOnboardingSuccess,
    getCurrentEmployeeOnboardingFailed,

    downloadEmployeeOnboardingSuccess,
    downloadEmployeeOnboardingFailed
} from './actions';

const apiUrl = `${apiRootEndpoint}/api/v1/employee-onboardings`;

function* getAllEmployeeOnboardings({ payload: { offset, limit, filter } }) {
    const options = {
        method: 'GET',
    };

    try {
        let url = `${apiUrl}?offset=${offset}&limit=${limit}`;
        if(filter.query) {
            url = `${url}&query=${filter.query}`;
        }
        if(filter.signedOfferLetterUrl) {
            url = `${url}&signedOfferLetterUrl=true`
        }
        const {total, data} = yield call(fetchJSON, url, options);
        if(data && data.length) {
            yield put(getAllEmployeeOnboardingsSuccess({total, data}));
        }
        else {
            yield put(getAllEmployeeOnboardingsFailed('No data found'));
        }
    } catch (error) {
        
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(getAllEmployeeOnboardingsFailed(message));
        
    }
}

function* submitEmployeeOnboarding({ payload: item }) {
    const options = {
        body: JSON.stringify(item),
        method: item.id ? 'PUT' :  'POST',
        headers: { 'Content-Type': 'application/json' }
    };

    try {
        let url = `${apiUrl}`;
        if(item.id) {
            url = `${apiUrl}/${item.id}`;
        }
        const response = yield call(fetchJSON, url, options);
        if(response.status === 'OK') {
            yield put(submitEmployeeOnboardingFormSuccess(response.data));
        }
        else {
            yield put(submitEmployeeOnboardingFormFailed(response.message));
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(submitEmployeeOnboardingFormFailed(message));
        
    }
}

function* deleteEmployeeOnboarding({ payload: id }) {
    const options = {
        method: 'DELETE',
    };
    try {
        const url = `${apiUrl}/${id}`;
        const response = yield call(fetchJSON, url, options);
        
        if(response.status === "OK") {
            yield put(deleteEmployeeOnboardingSuccess(id));
        }
        else {
            yield put(deleteEmployeeOnboardingFailed(response.message));
        }
    } catch (error) {
       
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(deleteEmployeeOnboardingFailed(message));
    }
}

function* getCurrentEmployeeOnboarding({ payload: id }) {
    const options = {
        method: 'GET',
    };
    
    try {
        let url = `${apiUrl}/${id}`;
       
        const employeeOnboarding = yield call(fetchJSON, url, options);
        yield put(getCurrentEmployeeOnboardingSuccess(employeeOnboarding.data));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(getCurrentEmployeeOnboardingFailed(message));
    }
}


function* downloadEmployeeOnboarding({payload : id}) {
    
    const options = {
        method: 'GET',
    };
    try {
        let url = `${apiUrl}/${id}/download`;
        
        const response = yield call(fetchJSON, url, options);
        
        if(response.status === "OK") {
            if(response.url) {
                window.open(response.url);
            }
            yield put(downloadEmployeeOnboardingSuccess());
        }
        else {
            yield put(downloadEmployeeOnboardingFailed(response.message));
        }
    } catch (error) {
       
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(downloadEmployeeOnboardingFailed(message));
    }
}



export function* watchGetAllAEmployeeOnboardings() {
    yield takeEvery(GET_EMPLOYEE_ONBOARDINGS, getAllEmployeeOnboardings);
}
export function* watchSubmitEmployeeOnboardingForm() {
    yield takeEvery(SUBMIT_EMPLOYEE_ONBOARDING_FORM, submitEmployeeOnboarding);
}
export function* watchDeleteEmployeeOnboarding() {
    yield takeEvery(DELETE_EMPLOYEE_ONBOARDING, deleteEmployeeOnboarding);
}
export function* watchGetCurrentEmployeeOnboarding() {
    yield takeEvery(GET_CURRENT_EMPLOYEE_ONBOARDING, getCurrentEmployeeOnboarding);
}
export function* watchDownloadEmployeeOnboarding() {
    yield takeEvery(DOWNLOAD_EMPLOYEE_ONBOARDING, downloadEmployeeOnboarding);
}

function* employeeOnboardingSaga() {
    yield all([
        fork(watchGetAllAEmployeeOnboardings),
        fork(watchSubmitEmployeeOnboardingForm),
        fork(watchDeleteEmployeeOnboarding),
        fork(watchGetCurrentEmployeeOnboarding),
        fork(watchDownloadEmployeeOnboarding),
    ]);
}

export default employeeOnboardingSaga;