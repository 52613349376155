import {
    GET_COURSES,
    GET_COURSES_SUCCESS,
    GET_COURSES_FAILED,

    SUBMIT_COURSE_FORM,
    SUBMIT_COURSE_FORM_SUCCESS,
    SUBMIT_COURSE_FORM_FAILED,

    DELETE_COURSE,
    DELETE_COURSE_SUCCESS,
    DELETE_COURSE_FAILED,
} from '../../constants/actionTypes';


/** GET ALL */
export const getAllCourses = ({offset, limit, filter}) => ({
    type: GET_COURSES,
    payload: { offset, limit, filter }
});

export const getAllCoursesSuccess = ({total, data}) => ({
    type: GET_COURSES_SUCCESS,
    payload: {total, data}
});

export const getAllCoursesFailed = (error) => ({
    type: GET_COURSES_FAILED,
    payload: error
});

/** SUBMIT FORM */
export const submitCourseForm = (course) => ({
    type: SUBMIT_COURSE_FORM,
    payload: course
});

export const submitCourseFormSuccess = (course) => ({
    type: SUBMIT_COURSE_FORM_SUCCESS,
    payload: course
});

export const submitCourseFormFailed = (error) => ({
    type: SUBMIT_COURSE_FORM_FAILED,
    payload: error
});

/** DELETE FORM */
export const deleteCourse = (id) => ({
    type: DELETE_COURSE,
    payload: id
});

export const deleteCourseSuccess = (id) => ({
    type: DELETE_COURSE_SUCCESS,
    payload: id
});

export const deleteCourseFailed = (error) => ({
    type: DELETE_COURSE_FAILED,
    payload: error
});