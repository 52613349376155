import {
    GET_TELEGRAM_COES,
    GET_TELEGRAM_COES_SUCCESS,
    GET_TELEGRAM_COES_FAILED,

    GET_TELEGRAM_USERS,
    GET_TELEGRAM_USERS_SUCCESS,
    GET_TELEGRAM_USERS_FAILED,

    GET_TELEGRAM_COLLEGES,
    GET_TELEGRAM_COLLEGES_SUCCESS,
    GET_TELEGRAM_COLLEGES_FAILED,

    GET_TELEGRAM_REPORT,
    GET_TELEGRAM_REPORT_SUCCESS,
    GET_TELEGRAM_REPORT_FAILED,

    EXPORT_TELEGRAM_COES,
    EXPORT_TELEGRAM_COES_SUCCESS,
    EXPORT_TELEGRAM_COES_FAILED,

    SUBMIT_TELEGRAM_COE_FORM,
    SUBMIT_TELEGRAM_COE_FORM_SUCCESS,
    SUBMIT_TELEGRAM_COE_FORM_FAILED
} from '../../constants/actionTypes';

const INIT_STATE = {
    loading: false,
    coes: [],
    users: [],
    colleges: [],
    submittingForm: false,
    error: null,
    report: []
};

const reducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_TELEGRAM_COES:
            return { ...state, loading: true };
        case GET_TELEGRAM_COES_SUCCESS:
            return { ...state, coes: action.payload.data, total: action.payload.total, currentRole: null, loading: false, error: null };
        case GET_TELEGRAM_COES_FAILED:
            return { ...state, error: action.payload, loading: false };

        case GET_TELEGRAM_USERS:
            return { ...state, loading: true };
        case GET_TELEGRAM_USERS_SUCCESS:
            return { ...state, users: action.payload.data,  loading: false, error: null };
        case GET_TELEGRAM_USERS_FAILED:
            return { ...state, error: action.payload, loading: false };

        case GET_TELEGRAM_COLLEGES:
            return { ...state, loading: true };
        case GET_TELEGRAM_COLLEGES_SUCCESS:
            return { ...state, colleges: action.payload.data,  loading: false, error: null };
        case GET_TELEGRAM_COLLEGES_FAILED:
            return { ...state, error: action.payload, loading: false };

        case GET_TELEGRAM_REPORT:
            return { ...state, loading: true };
        case GET_TELEGRAM_REPORT_SUCCESS:
           
            return { ...state, report: action.payload.data,  loading: false};
        case GET_TELEGRAM_REPORT_FAILED:
            return { ...state, error: action.payload, loading: false };

        case EXPORT_TELEGRAM_COES:
            return { ...state, exporting: true };
        case EXPORT_TELEGRAM_COES_SUCCESS:
            return { ...state,  exporting: false};
        case EXPORT_TELEGRAM_COES_FAILED:
            return { ...state, error: action.payload, exporting: false };

        case SUBMIT_TELEGRAM_COE_FORM:
            return { ...state, error: null, submittingForm: true };
        case SUBMIT_TELEGRAM_COE_FORM_SUCCESS:
            const alreadyExists = state.coes.find((item) => item.id === action.payload.id);
            let coes;
            let total = state.total;
            if (!alreadyExists) {
                coes = [action.payload, ...state.coes];
                total += 1;
            } else {
                coes = state.coes.map((item) => {
                if (item.id === action.payload.id) {
                    return action.payload;
                }
                return item;
                });
            }
            return { ...state, total,  coes, submittingForm: false };
        case SUBMIT_TELEGRAM_COE_FORM_FAILED:
            return { ...state, error: action.payload, submittingForm: false };

        default: return { ...state };
    }
}

export default reducer;