import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { fetchJSON, apiRootEndpoint } from '../../helpers';
import {
    GET_APPRAISAL_FEEDBACKS,
    SUBMIT_APPRAISAL_FEEDBACK_FORM
} from '../../constants/actionTypes';


import {
    getAllAppraisalFeedbacksSuccess,
    getAllAppraisalFeedbacksFailed,

    submitAppraisalFeedbackFormSuccess,
    submitAppraisalFeedbackFormFailed,
} from './actions';

const apiUrl = `${apiRootEndpoint}/api/v1/appraisal-feedbacks`;



function* getAllAppraisalFeedbacks({ payload: { offset, limit, filter } }) {
    const options = {
        method: 'GET',
    };

    try {
        let url = `${apiUrl}?offset=${offset}&limit=${limit}`;
        if(filter.appraisalId) {
            url = `${url}&appraisalId=${filter.appraisalId}`
        }
        const {total, data} = yield call(fetchJSON, url, options);
        yield put(getAllAppraisalFeedbacksSuccess({total, data}));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(getAllAppraisalFeedbacksFailed(message));
    }
}



function* submitAppraisalFeedback({ payload: item }) {
    const options = {
        body: JSON.stringify(item),
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' }
    };

    try {
        let url = `${apiUrl}`;
        if(item.id) {
            url = `${apiUrl}/${item.id}`;
        }
        const response = yield call(fetchJSON, url, options);
        if(response.status === 'OK') {
            yield put(submitAppraisalFeedbackFormSuccess(response.data));
        }
        else {
            yield put(submitAppraisalFeedbackFormFailed(response.message));
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(submitAppraisalFeedbackFormFailed(message));
    }
}

export function* watchGetAllAppraisalFeedbacks() {
    yield takeEvery(GET_APPRAISAL_FEEDBACKS, getAllAppraisalFeedbacks);
}

export function* watchSubmitAppraisalFeedbackForm() {
    yield takeEvery(SUBMIT_APPRAISAL_FEEDBACK_FORM, submitAppraisalFeedback);
}

function* appraisalFeedbackSaga() {
    yield all([
        fork(watchGetAllAppraisalFeedbacks),
        fork(watchSubmitAppraisalFeedbackForm),
    ]);
}

export default appraisalFeedbackSaga;