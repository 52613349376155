import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { fetchJSON, apiRootEndpoint } from '../../helpers';
import {
    GET_TARGETS,
    SUBMIT_TARGET_FORM,
    DELETE_TARGET
} from '../../constants/actionTypes';


import {
    getAllTargetsSuccess,
    getAllTargetsFailed,

    submitTargetFormSuccess,
    submitTargetFormFailed,

    deleteTargetSuccess,
    deleteTargetFailed,
} from './actions';

const apiUrl = `${apiRootEndpoint}/api/v1/targets`; 

function* getAllTargets({ payload: { offset, limit, filter } }) {
    const options = {
        method: 'GET',
    };

    try {
        let url = `${apiUrl}?offset=${offset}&limit=${limit}`;
    
        if(filter.branchId) {
            url = `${url}&branchId=${filter.branchId}`;
        }
        const {total, data} = yield call(fetchJSON, url, options);
        
        yield put(getAllTargetsSuccess({total, data}));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(getAllTargetsFailed(message));
    }
}



function* submitTarget({ payload: item }) {
    const options = {
        body: JSON.stringify(item),
        method: item.id ? 'PUT' :  'POST',
        headers: { 'Content-Type': 'application/json' }
    };

    try {
        let url = `${apiUrl}`;
        if(item.id) {
            url = `${apiUrl}/${item.id}`;
        }
        const response = yield call(fetchJSON, url, options);
        if(response.status === 'OK') {
            yield put(submitTargetFormSuccess(response.data));
        }
        else {
            yield put(submitTargetFormFailed(response.message));
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(submitTargetFormFailed(message));
    }
}

function* deleteTarget({ payload: id }) {
    const options = {
        method: 'DELETE',
    };
    try {
        const url = `${apiUrl}/${id}`;
        const response = yield call(fetchJSON, url, options);
        if(response.status === 'OK') {
            yield put(deleteTargetSuccess(id));
        }
        else {
            yield put(deleteTargetFailed(response.message));
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(deleteTargetFailed(message));
    }
}


export function* watchGetAllTargets() {
    yield takeEvery(GET_TARGETS, getAllTargets);
}
export function* watchSubmitTargetForm() {
    yield takeEvery(SUBMIT_TARGET_FORM, submitTarget);
}
export function* watchDeleteTarget() {
    yield takeEvery(DELETE_TARGET, deleteTarget);
}


function* telegramGroupSaga() {
    yield all([
        fork(watchGetAllTargets),
        fork(watchSubmitTargetForm),
        fork(watchDeleteTarget)
    ]);
}

export default telegramGroupSaga;