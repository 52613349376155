import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { fetchJSON, apiRootEndpoint } from '../../helpers';
import {
    GET_EVENT_PARTICIPANTS, 
    SUBMIT_EVENT_PARTICIPANT_FORM,
    DELETE_EVENT_PARTICIPANT
} from '../../constants/actionTypes';


import {
    getAllEventParticipantsSuccess,
    getAllEventParticipantsFailed,

    submitEventParticipantFormSuccess,
    submitEventParticipantFormFailed,

    deleteEventParticipantSuccess,
    deleteEventParticipantFailed,
} from './actions';

const apiUrl = `${apiRootEndpoint}/api/v1/event-participants`;

function* getAllEventParticipants({ payload: { offset, limit, filter = {} } }) {
    const options = {
        method: 'GET',
    };
    try {
        let url = `${apiUrl}?offset=${offset}&limit=${limit}`;
        if(filter.eventId) {
            url = `${url}&eventId=${filter.eventId}`;
        }
        const {total, data} = yield call(fetchJSON, url, options);
        yield put(getAllEventParticipantsSuccess({total, data}));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(getAllEventParticipantsFailed(message));
    }
}

function* submitEventParticipant({ payload: item }) {
    const options = {
        body: JSON.stringify(item),
        method: item.id ? 'PUT' :  'POST',
        headers: { 'Content-Type': 'application/json' }
    };

    try {
        let url = `${apiUrl}`;
        if(item.id) {
            url = `${apiUrl}/${item.id}`;
        }
        const response = yield call(fetchJSON, url, options);
        if(response.status === 'OK') {
            yield put(submitEventParticipantFormSuccess(response.data));
        }
        else {
            yield put(submitEventParticipantFormFailed(response.message));
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(submitEventParticipantFormFailed(message));
    }
}

function* deleteEventParticipant({ payload: id }) {
    const options = {
        method: 'DELETE',
    };
    try {
        const url = `${apiUrl}/${id}`;
        const response = yield call(fetchJSON, url, options);
        if(response.status === 'OK') {
            yield put(deleteEventParticipantSuccess(id));
        }
        else {
            yield put(deleteEventParticipantFailed(response.message));
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(deleteEventParticipantFailed(message));
    }
}


export function* watchGetAllEventParticipants() {
    yield takeEvery(GET_EVENT_PARTICIPANTS, getAllEventParticipants);
}
export function* watchSubmitEventParticipantForm() {
    yield takeEvery(SUBMIT_EVENT_PARTICIPANT_FORM, submitEventParticipant);
}
export function* watchDeleteEventParticipant() {
    yield takeEvery(DELETE_EVENT_PARTICIPANT, deleteEventParticipant);
}

function* eventParticipantSaga() {
    yield all([
        fork(watchGetAllEventParticipants),
        fork(watchSubmitEventParticipantForm),
        fork(watchDeleteEventParticipant),
    ]);
}

export default eventParticipantSaga;