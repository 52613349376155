import {
    GET_EMPLOYMENT_CONTRACTS,
    GET_EMPLOYMENT_CONTRACTS_SUCCESS,
    GET_EMPLOYMENT_CONTRACTS_FAILED,

    SUBMIT_EMPLOYMENT_CONTRACT_FORM,
    SUBMIT_EMPLOYMENT_CONTRACT_FORM_SUCCESS,
    SUBMIT_EMPLOYMENT_CONTRACT_FORM_FAILED,

    DELETE_EMPLOYMENT_CONTRACT,
    DELETE_EMPLOYMENT_CONTRACT_SUCCESS,
    DELETE_EMPLOYMENT_CONTRACT_FAILED,

    GET_CURRENT_EMPLOYMENT_CONTRACT,
    GET_CURRENT_EMPLOYMENT_CONTRACT_SUCCESS,
    GET_CURRENT_EMPLOYMENT_CONTRACT_FAILED
} from '../../constants/actionTypes'; 


/** GET ALL */
export const getAllEmploymentContracts = ({offset, limit, filter}) => ({
    type: GET_EMPLOYMENT_CONTRACTS,
    payload: { offset, limit, filter }
});

export const getAllEmploymentContractsSuccess = ({total, data}) => ({
    type: GET_EMPLOYMENT_CONTRACTS_SUCCESS,
    payload: {total, data}
});

export const getAllEmploymentContractsFailed = (error) => ({
    type: GET_EMPLOYMENT_CONTRACTS_FAILED,
    payload: error
});

/** SUBMIT FORM */
export const submitEmploymentContractForm = (item) => ({
    type: SUBMIT_EMPLOYMENT_CONTRACT_FORM,
    payload: item
});

export const submitEmploymentContractFormSuccess = (item) => ({
    type: SUBMIT_EMPLOYMENT_CONTRACT_FORM_SUCCESS,
    payload: item
});

export const submitEmploymentContractFormFailed = (error) => ({
    type: SUBMIT_EMPLOYMENT_CONTRACT_FORM_FAILED,
    payload: error
});

/** DELETE FORM */
export const deleteEmploymentContract = (id) => ({
    type: DELETE_EMPLOYMENT_CONTRACT,
    payload: id
});

export const deleteEmploymentContractSuccess = (id) => ({
    type: DELETE_EMPLOYMENT_CONTRACT_SUCCESS,
    payload: id
});

export const deleteEmploymentContractFailed = (error) => ({
    type: DELETE_EMPLOYMENT_CONTRACT_FAILED,
    payload: error
});


/** GET CURRENT */
export const getCurrentEmploymentContract = (id) => ({
    type: GET_CURRENT_EMPLOYMENT_CONTRACT,
    payload: id
});

export const getCurrentEmploymentContractSuccess = (item) => ({
    type: GET_CURRENT_EMPLOYMENT_CONTRACT_SUCCESS,
    payload: item
});

export const getCurrentEmploymentContractFailed = (error) => ({
    type: GET_CURRENT_EMPLOYMENT_CONTRACT_FAILED,
    payload: error
});