import {
    GET_TRAINING_PROGRAMS,
    GET_TRAINING_PROGRAMS_SUCCESS,
    GET_TRAINING_PROGRAMS_FAILED,

    SUBMIT_TRAINING_PROGRAM_FORM,
    SUBMIT_TRAINING_PROGRAM_FORM_SUCCESS,
    SUBMIT_TRAINING_PROGRAM_FORM_FAILED,

    DELETE_TRAINING_PROGRAM,
    DELETE_TRAINING_PROGRAM_SUCCESS,
    DELETE_TRAINING_PROGRAM_FAILED,
} from '../../constants/actionTypes';


/** GET ALL */
export const getAllTrainingPrograms = ({offset, limit}) => ({
    type: GET_TRAINING_PROGRAMS,
    payload: { offset, limit }
});

export const getAllTrainingProgramsSuccess = ({total, data}) => ({
    type: GET_TRAINING_PROGRAMS_SUCCESS,
    payload: {total, data}
});

export const getAllTrainingProgramsFailed = (error) => ({
    type: GET_TRAINING_PROGRAMS_FAILED,
    payload: error
});

/** SUBMIT FORM */
export const submitTrainingProgramForm = (item) => ({
    type: SUBMIT_TRAINING_PROGRAM_FORM,
    payload: item
});

export const submitTrainingProgramFormSuccess = (item) => ({
    type: SUBMIT_TRAINING_PROGRAM_FORM_SUCCESS,
    payload: item
});

export const submitTrainingProgramFormFailed = (error) => ({
    type: SUBMIT_TRAINING_PROGRAM_FORM_FAILED,
    payload: error
});

/** DELETE FORM */
export const deleteTrainingProgram = (id) => ({
    type: DELETE_TRAINING_PROGRAM,
    payload: id
});

export const deleteTrainingProgramSuccess = (id) => ({
    type: DELETE_TRAINING_PROGRAM_SUCCESS,
    payload: id
});

export const deleteTrainingProgramFailed = (error) => ({
    type: DELETE_TRAINING_PROGRAM_FAILED,
    payload: error
});