import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { fetchJSON, apiRootEndpoint } from '../../helpers';
import {
    GET_ROLES, 
    SUBMIT_ROLE_FORM,
    DELETE_ROLE,
    GET_CURRENT_ROLE
} from '../../constants/actionTypes';


import {
    getAllRolesSuccess,
    getAllRolesFailed,

    submitRoleFormSuccess,
    submitRoleFormFailed,

    deleteRoleSuccess,
    deleteRoleFailed,

    getCurrentRoleSuccess,
    getCurrentRoleFailed
} from './actions';

const apiUrl = `${apiRootEndpoint}/api/v1/roles`; 

function* getAllRoles({ payload: { offset, limit } }) {
    const options = {
        method: 'GET',
    };

    try {
        const url = `${apiUrl}?offset=${offset}&limit=${limit}`;
        const {total, data} = yield call(fetchJSON, url, options);
        
        yield put(getAllRolesSuccess({total, data}));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(getAllRolesFailed(message));
    }
}

function* submitRole({ payload: role }) {
    const options = {
        body: JSON.stringify(role),
        method: role.id ? 'PUT' :  'POST',
        headers: { 'Content-Type': 'application/json' }
    };

    try {
        let url = `${apiUrl}`;
        if(role.id) {
            url = `${apiUrl}/${role.id}`;
        }
        const response = yield call(fetchJSON, url, options);
        if(response.status === 'OK') {
            yield put(submitRoleFormSuccess(response.data));
        }
        else {
            yield put(submitRoleFormFailed(response.message));
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(submitRoleFormFailed(message));
    }
}

function* deleteRole({ payload: id }) {
    const options = {
        method: 'DELETE',
    };
    try {
        const url = `${apiUrl}/${id}`;
        const response = yield call(fetchJSON, url, options);
        if(response.status === 'OK') {
            yield put(deleteRoleSuccess(id));
        }
        else {
            yield put(deleteRoleFailed(response.message));
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(deleteRoleFailed(message));
    }
}

function* getCurrentRole({ payload: id }) {
    const options = {
        method: 'GET',
    };
    
    try {
        let url = `${apiUrl}/${id}`;
       
        const appraisal = yield call(fetchJSON, url, options);
        yield put(getCurrentRoleSuccess(appraisal.data));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(getCurrentRoleFailed(message));
    }
}



export function* watchGetAllRoles() {
    yield takeEvery(GET_ROLES, getAllRoles);
}
export function* watchSubmitRoleForm() {
    yield takeEvery(SUBMIT_ROLE_FORM, submitRole);
}
export function* watchDeleteRoles() {
    yield takeEvery(DELETE_ROLE, deleteRole);
}
export function* watchGetCurrentRole() {
    yield takeEvery(GET_CURRENT_ROLE, getCurrentRole);
}

function* roleSaga() {
    yield all([
        fork(watchGetAllRoles),
        fork(watchSubmitRoleForm),
        fork(watchDeleteRoles),
        fork(watchGetCurrentRole),
    ]);
}

export default roleSaga;