import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import cogoToast from 'cogo-toast';
import { fetchJSON, apiRootEndpoint } from '../../helpers';
import {
    GET_SUPPORT_FUND_STUDENTS, 
    SUBMIT_SUPPORT_FUND_STUDENT_FORM,
    DELETE_SUPPORT_FUND_STUDENT,
    EXPORT_SUPPORT_FUND_STUDENTS
} from '../../constants/actionTypes';


import {
    getAllSupportFundStudentsSuccess,
    getAllSupportFundStudentsFailed,

    submitSupportFundStudentFormSuccess,
    submitSupportFundStudentFormFailed,

    deleteSupportFundStudentSuccess,
    deleteSupportFundStudentFailed,

    exportSupportFundStudentsSuccess,
    exportSupportFundStudentsFailed
} from './actions';

const apiUrl = `${apiRootEndpoint}/api/v1/support-fund-students`;

function* getAllSupportFundStudents({ payload: { offset, limit, filter } }) {
    const options = {
        method: 'GET',
    };

    try {
        let url = `${apiUrl}?offset=${offset}&limit=${limit}`;
        if(filter.isArchived) {
            url = `${url}&isArchived=true`;
        }
        if(filter.status) {
            url = `${url}&status=${filter.status}`;
        }
        if(filter.state) {
            url = `${url}&state=${filter.state}`;
        }
        if(filter.branch) {
            url = `${url}&branch=${filter.branch}`;
        }
        if(filter.employmentStatus) {
            url = `${url}&employmentStatus=${filter.employmentStatus}`;
        }
        if(filter.arrivalDate) {
            url = `${url}&arrivalDate=${filter.arrivalDate}`;
        }
        if(filter.query) {
            url = `${url}&query=${filter.query}`;
        }
        const {total, data} = yield call(fetchJSON, url, options);
        yield put(getAllSupportFundStudentsSuccess({total, data}));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(getAllSupportFundStudentsFailed(message));
    }
}

function* submitSupportFundStudent({ payload: student }) {
    const options = {
        body: JSON.stringify(student),
        method: student.id ? 'PUT' :  'POST',
        headers: { 'Content-Type': 'application/json' }
    };

    try {
        let url = `${apiUrl}`;
        if(student.id) {
            url = `${apiUrl}/${student.id}`;
        }
        const response = yield call(fetchJSON, url, options);
        if(response.status === 'OK') {
            yield put(submitSupportFundStudentFormSuccess(response.data));
        }
        else {
            
            yield put(submitSupportFundStudentFormFailed(response.message));
            cogoToast.error(response.message);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(submitSupportFundStudentFormFailed(message));
        cogoToast.error(error.message);
    }
}

function* deleteSupportFundStudent({ payload: id }) {
    const options = {
        method: 'DELETE',
    };
    try {
        const url = `${apiUrl}/${id}`;
        const response = yield call(fetchJSON, url, options);
        if(response.status === 'OK') {
            yield put(deleteSupportFundStudentSuccess(id));
        }
        else {
            yield put(deleteSupportFundStudentFailed(response.message));
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(deleteSupportFundStudentFailed(message));
    }
}


function* exportSupportFundStudents({ payload: data }) {
    const options = {
        body: JSON.stringify(data),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' }
    };
    try {
        const url = `${apiUrl}/export`;
        const response = yield call(fetchJSON, url, options);
        
        if(response.status === "OK") {
            if(response.url) {
                window.open(response.url);
            }
            yield put(exportSupportFundStudentsSuccess());
        }
        else {
            yield put(exportSupportFundStudentsFailed(response.message));
        }
    } catch (error) {
       
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(exportSupportFundStudentsFailed(message));
    }
}


export function* watchGetAllSupportFundStudents() {
    yield takeEvery(GET_SUPPORT_FUND_STUDENTS, getAllSupportFundStudents);
}
export function* watchSubmitSupportFundStudentForm() {
    yield takeEvery(SUBMIT_SUPPORT_FUND_STUDENT_FORM, submitSupportFundStudent);
}
export function* watchDeleteSupportFundStudent() {
    yield takeEvery(DELETE_SUPPORT_FUND_STUDENT, deleteSupportFundStudent);
}
export function* watchExportSupportFundStudents() {
    yield takeEvery(EXPORT_SUPPORT_FUND_STUDENTS, exportSupportFundStudents);
}

function* supportFundStudentSaga() {
    yield all([
        fork(watchGetAllSupportFundStudents),
        fork(watchSubmitSupportFundStudentForm),
        fork(watchDeleteSupportFundStudent),
        fork(watchExportSupportFundStudents),
    ]);
}

export default supportFundStudentSaga;