import { getLoggedInUser } from './authUtils'; 


const fetchJSON = (url, options = {}) => {
    const user = getLoggedInUser();
    if(user) {
        options.headers =  {
            ...options.headers,
            'x-access-token': user.token
          };
    } 
    return fetch(url, options)
        .then(response => {
            if (!response.status === 200) {
                throw response.json();
            }
            return response.json();
        })
        .then(json => {
            return json;
        })
        .catch(error => { throw error });
}

const getApiEndPoint = () => {
  const { hostname } = window.location;
  let serverUrl;

  switch (hostname) {
    case 'localhost':
      serverUrl = 'http://localhost:5500';
      break;
    case 'eevs.co':
      serverUrl = 'https://api.eevs.co';
      break;
    case 'www.eevs.co':
        serverUrl = 'https://api.eevs.co';
        break;
    default:
      serverUrl = 'localhost:8181';
  }

  return serverUrl;
}

const apiRootEndpoint = getApiEndPoint();

export { fetchJSON, apiRootEndpoint  };