import {
    GET_EMPLOYEE_ONBOARDINGS,
    GET_EMPLOYEE_ONBOARDINGS_SUCCESS,
    GET_EMPLOYEE_ONBOARDINGS_FAILED,

    SUBMIT_EMPLOYEE_ONBOARDING_FORM,
    SUBMIT_EMPLOYEE_ONBOARDING_FORM_SUCCESS,
    SUBMIT_EMPLOYEE_ONBOARDING_FORM_FAILED,

    DELETE_EMPLOYEE_ONBOARDING,
    DELETE_EMPLOYEE_ONBOARDING_SUCCESS,
    DELETE_EMPLOYEE_ONBOARDING_FAILED,


    GET_CURRENT_EMPLOYEE_ONBOARDING,
    GET_CURRENT_EMPLOYEE_ONBOARDING_SUCCESS,
    GET_CURRENT_EMPLOYEE_ONBOARDING_FAILED,

    DOWNLOAD_EMPLOYEE_ONBOARDING,
    DOWNLOAD_EMPLOYEE_ONBOARDING_SUCCESS,
    DOWNLOAD_EMPLOYEE_ONBOARDING_FAILED
} from '../../constants/actionTypes';

const INIT_STATE = {
    loading: false,
    employeeOnboardings: [],
    submittingForm: false,
    error: null,
    deleting: false,
    currentEmployeeOnboarding: null,
    downloading: false
};

const AppraisalFactorReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_EMPLOYEE_ONBOARDINGS:
            return { ...state, loading: true };
        case GET_EMPLOYEE_ONBOARDINGS_SUCCESS:
            return { ...state, employeeOnboardings: action.payload.data, total: action.payload.total, loading: false, error: null };
        case GET_EMPLOYEE_ONBOARDINGS_FAILED:
            return { ...state, error: action.payload, loading: false };

        case SUBMIT_EMPLOYEE_ONBOARDING_FORM:
            return { ...state, error: null, submittingForm: true };
        case SUBMIT_EMPLOYEE_ONBOARDING_FORM_SUCCESS:
            const alreadyExists = state.employeeOnboardings.find((item) => item.id === action.payload.id);
            let employeeOnboardings;
            let total = state.total;
            if (!alreadyExists) {
                employeeOnboardings = [action.payload, ...state.employeeOnboardings];
                total += 1;
            } else {
                employeeOnboardings = state.employeeOnboardings.map((item) => {
                if (item.id === action.payload.id) {
                  return action.payload;
                }
                return item;
              });
            }
            return { ...state, total,  employeeOnboardings, currentEmployeeOnboarding: action.payload, submittingForm: false };
        case SUBMIT_EMPLOYEE_ONBOARDING_FORM_FAILED:
            return { ...state, error: action.payload, submittingForm: false };

        case DELETE_EMPLOYEE_ONBOARDING:
            return { ...state, error: null, deleting: true };
        case DELETE_EMPLOYEE_ONBOARDING_SUCCESS:
            return { ...state, total: state.total - 1, employeeOnboardings: state.employeeOnboardings.filter(item => item.id !== action.payload) , deleting: false };
        case DELETE_EMPLOYEE_ONBOARDING_FAILED:
            return { ...state, error: action.payload, deleting: false };

        case GET_CURRENT_EMPLOYEE_ONBOARDING:
            return { ...state, loading: true };
        case GET_CURRENT_EMPLOYEE_ONBOARDING_SUCCESS:
            return { ...state, currentEmployeeOnboarding: action.payload, loading: false, error: null };
        case GET_CURRENT_EMPLOYEE_ONBOARDING_FAILED:
            return { ...state, error: action.payload, loading: false };

        case DOWNLOAD_EMPLOYEE_ONBOARDING:
            return { ...state, error: null, downloading: true };
        case DOWNLOAD_EMPLOYEE_ONBOARDING_SUCCESS:
            return { ...state, downloading: false };
        case DOWNLOAD_EMPLOYEE_ONBOARDING_FAILED:
            return { ...state, error: action.payload, downloading: false };
        
        default: return { ...state };
    }
}

export default AppraisalFactorReducer;