import React from 'react';
import Loadable from 'react-loadable';

// Lazy loading and code splitting - 
// Derieved idea from https://blog.logrocket.com/lazy-loading-components-in-react-16-6-6cea535c0b52
const loading = () => <div></div>

// All layouts/containers
const NonAuthLayout = Loadable({
    loader: () => import('../components/NonAuthLayout'),
    render(loaded, props) {
      let Component = loaded.default;
      return <Component {...props} />;
    },
    loading
  });
  
  const AuthLayout = Loadable({
    loader: () => import('../components/AuthLayout'),
    render(loaded, props) {
      let Component = loaded.default;
      return <Component {...props} />;
    },
    loading
  });
  
export {
    NonAuthLayout,
    AuthLayout
}