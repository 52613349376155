import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { fetchJSON, apiRootEndpoint } from '../../helpers';
import {
    GET_TRAINING_PROGRAMS, 
    SUBMIT_TRAINING_PROGRAM_FORM,
    DELETE_TRAINING_PROGRAM
} from '../../constants/actionTypes';


import {
    getAllTrainingProgramsSuccess,
    getAllTrainingProgramsFailed,

    submitTrainingProgramFormSuccess,
    submitTrainingProgramFormFailed,

    deleteTrainingProgramSuccess,
    deleteTrainingProgramFailed,
} from './actions';

const apiUrl = `${apiRootEndpoint}/api/v1/training-programs`;

function* getAllTrainingPrograms({ payload: { offset, limit } }) {
    const options = {
        method: 'GET',
    };

    try {
        const url = `${apiUrl}?offset=${offset}&limit=${limit}`;
        const {total, data} = yield call(fetchJSON, url, options);
        yield put(getAllTrainingProgramsSuccess({total, data}));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(getAllTrainingProgramsFailed(message));
    }
}

function* submitTrainingProgram({ payload: item }) {
    const options = {
        body: JSON.stringify(item),
        method: item.id ? 'PUT' :  'POST',
        headers: { 'Content-Type': 'application/json' }
    };

    try {
        let url = `${apiUrl}`;
        if(item.id) {
            url = `${apiUrl}/${item.id}`;
        }
        const response = yield call(fetchJSON, url, options);
        if(response.status === 'OK') {
            yield put(submitTrainingProgramFormSuccess(response.data));
        }
        else {
            yield put(submitTrainingProgramFormFailed(response.message));
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(submitTrainingProgramFormFailed(message));
    }
}

function* deleteTrainingProgram({ payload: id }) {
    const options = {
        method: 'DELETE',
    };
    try {
        const url = `${apiUrl}/${id}`;
        const response = yield call(fetchJSON, url, options);
        if(response.status === 'OK') {
            yield put(deleteTrainingProgramSuccess(id));
        }
        else {
            yield put(deleteTrainingProgramFailed(response.message));
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(deleteTrainingProgramFailed(message));
    }
}


export function* watchGetAllTrainingPrograms() {
    yield takeEvery(GET_TRAINING_PROGRAMS, getAllTrainingPrograms);
}
export function* watchSubmitTrainingProgramForm() {
    yield takeEvery(SUBMIT_TRAINING_PROGRAM_FORM, submitTrainingProgram);
}
export function* watchDeleteBTrainingProgram() {
    yield takeEvery(DELETE_TRAINING_PROGRAM, deleteTrainingProgram);
}

function* trainingProgramSaga() {
    yield all([
        fork(watchGetAllTrainingPrograms),
        fork(watchSubmitTrainingProgramForm),
        fork(watchDeleteBTrainingProgram),
    ]);
}

export default trainingProgramSaga;