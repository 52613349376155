import {
    GET_EMPLOYEES,
    GET_EMPLOYEES_SUCCESS,
    GET_EMPLOYEES_FAILED,

    GET_CURRENT_EMPLOYEE,
    GET_CURRENT_EMPLOYEE_SUCCESS,
    GET_CURRENT_EMPLOYEE_FAILED
} from '../../constants/actionTypes';

const INIT_STATE = {
    loading: false,
    employees: [],
    submittingForm: false,
    error: null,
    deleting: false,
    currentEmployee: null
};

const EmployeeReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_EMPLOYEES:
            return { ...state, loading: true };
        case GET_EMPLOYEES_SUCCESS:
            return { ...state, employees: action.payload.data, total: action.payload.total, loading: false, error: null };
        case GET_EMPLOYEES_FAILED:
            return { ...state, error: action.payload, loading: false };

        case GET_CURRENT_EMPLOYEE:
            return { ...state, loading: true };
        case GET_CURRENT_EMPLOYEE_SUCCESS:
            return { ...state, currentEmployee: action.payload, loading: false, error: null };
        case GET_CURRENT_EMPLOYEE_FAILED:
            return { ...state, error: action.payload, loading: false };
        
        
        default: return { ...state };
    }
}

export default EmployeeReducer;