import { all } from 'redux-saga/effects';
import authSaga from './auth/saga';
import userSaga from './user/saga';
import timesheetSaga from './timesheet/saga';
import taskSaga from './task/saga';
import authUser from './authUser/saga';
import countrySaga from './country/saga';
import branchSaga from './branch/saga';
import studentSaga from './student/saga';
import instituteSaga from './institute/saga';
import courseSaga from './course/saga';
import creditNoteSaga from './creditNote/saga';
import supportFundStudentSaga from './supportFundStudent/saga';
import supportFundOrganizationSaga from './supportFundOrganization/saga';
import appraisalFactorSaga from './appraisalFactor/saga';
import appraisalSheetSaga from './appraisalSheet/saga';
import appraisalSaga from './appraisal/saga';
import appraisalFeedbackSaga from './appraisalFeedback/saga';
import departmentSaga from './department/saga';
import roleSaga from './role/saga';
import employeeOnboardingSaga from './employeeOnboarding/saga';
import employeeSaga from './employee/saga';
import coeEmailSaga from './coeEmail/saga';
import coeSaga from './coe/saga';
import ocrSaga from './ocr/saga';
import employmentContractSaga from './employmentContract/saga';
import eventSaga from './event/saga';
import eventParticipantSaga from './eventParticipant/saga';
import trainingProviderSaga from './trainingProvider/saga';
import trainingProgramSaga from './trainingProgram/saga';
import employeeTrainingSaga from './employeeTraining/saga';
import leaveRequestSaga from './leaveRequest/saga';
import telegramCoeSaga from './telegramCoe/saga';
import telegramGroupSaga from './telegramGroup/saga';
import targetSaga from './target/saga';


export default function* rootSaga(getState) {
    yield all([
        authSaga(),
        userSaga(),
        timesheetSaga(),
        taskSaga(),
        authUser(),
        countrySaga(),
        branchSaga(),
        studentSaga(),
        instituteSaga(),
        courseSaga(),
        creditNoteSaga(),
        supportFundStudentSaga(),
        supportFundOrganizationSaga(),
        appraisalFactorSaga(),
        appraisalSheetSaga(),
        appraisalSaga(),
        appraisalFeedbackSaga(),
        departmentSaga(),
        roleSaga(),
        employeeOnboardingSaga(),
        employeeSaga(),
        coeEmailSaga(),
        coeSaga(),
        ocrSaga(),
        employmentContractSaga(),
        eventSaga(),
        eventParticipantSaga(),
        trainingProviderSaga(),
        trainingProgramSaga(),
        employeeTrainingSaga(),
        leaveRequestSaga(),
        telegramCoeSaga(),
        telegramGroupSaga(),
        targetSaga()
    ]);
}
