import {
    GET_LEAVE_REQUESTS,
    GET_LEAVE_REQUESTS_SUCCESS,
    GET_LEAVE_REQUESTS_FAILED,

    SUBMIT_LEAVE_REQUEST_FORM,
    SUBMIT_LEAVE_REQUEST_FORM_SUCCESS,
    SUBMIT_LEAVE_REQUEST_FORM_FAILED,

    DELETE_LEAVE_REQUEST,
    DELETE_LEAVE_REQUEST_SUCCESS,
    DELETE_LEAVE_REQUEST_FAILED,

    EXPORT_LEAVE_REQUESTS,
    EXPORT_LEAVE_REQUESTS_SUCCESS,
    EXPORT_LEAVE_REQUESTS_FAILED,
} from '../../constants/actionTypes';


/** GET ALL */
export const getAllLeaveRequests = ({offset, limit, filter}) => ({
    type: GET_LEAVE_REQUESTS,
    payload: { offset, limit, filter }
});

export const getAllLeaveRequestsSuccess = ({total, data}) => ({
    type: GET_LEAVE_REQUESTS_SUCCESS,
    payload: {total, data}
});

export const getAllLeaveRequestsFailed = (error) => ({
    type: GET_LEAVE_REQUESTS_FAILED,
    payload: error
});

/** SUBMIT FORM */
export const submitLeaveRequestForm = (country) => ({
    type: SUBMIT_LEAVE_REQUEST_FORM,
    payload: country
});

export const submitLeaveRequestFormSuccess = (country) => ({
    type: SUBMIT_LEAVE_REQUEST_FORM_SUCCESS,
    payload: country
});

export const submitLeaveRequestFormFailed = (error) => ({
    type: SUBMIT_LEAVE_REQUEST_FORM_FAILED,
    payload: error
});

/** DELETE FORM */
export const deleteLeaveRequest = (id) => ({
    type: DELETE_LEAVE_REQUEST,
    payload: id
});

export const deleteLeaveRequestSuccess = (id) => ({
    type: DELETE_LEAVE_REQUEST_SUCCESS,
    payload: id
});

export const deleteLeaveRequestFailed = (error) => ({
    type: DELETE_LEAVE_REQUEST_FAILED,
    payload: error
});

/** EXPORT */
export const exportLeaveRequests = (payload) => ({
    type: EXPORT_LEAVE_REQUESTS,
    payload
});

export const exportLeaveRequestsSuccess = () => ({
    type: EXPORT_LEAVE_REQUESTS_SUCCESS
});

export const exportLeaveRequestsFailed = (error) => ({
    type: EXPORT_LEAVE_REQUESTS_FAILED,
    payload: error
});