import {
    GET_SUPPORT_FUND_STUDENTS,
    GET_SUPPORT_FUND_STUDENTS_SUCCESS,
    GET_SUPPORT_FUND_STUDENTS_NOTES_FAILED,

    SUBMIT_SUPPORT_FUND_STUDENT_FORM,
    SUBMIT_SUPPORT_FUND_STUDENT_FORM_SUCCESS,
    SUBMIT_SUPPORT_FUND_STUDENT_FORM_FAILED,

    DELETE_SUPPORT_FUND_STUDENT,
    DELETE_SUPPORT_FUND_STUDENT_SUCCESS,
    DELETE_SUPPORT_FUND_STUDENT_FAILED,

    EXPORT_SUPPORT_FUND_STUDENTS,
    EXPORT_SUPPORT_FUND_STUDENTS_SUCCESS,
    EXPORT_SUPPORT_FUND_STUDENTS_FAILED
} from '../../constants/actionTypes';


/** GET ALL */
export const getAllSupportFundStudents = ({offset, limit, filter}) => ({
    type: GET_SUPPORT_FUND_STUDENTS,
    payload: { offset, limit, filter }
});

export const getAllSupportFundStudentsSuccess = ({total, data}) => ({
    type: GET_SUPPORT_FUND_STUDENTS_SUCCESS,
    payload: {total, data}
});

export const getAllSupportFundStudentsFailed = (error) => ({
    type: GET_SUPPORT_FUND_STUDENTS_NOTES_FAILED,
    payload: error
});

/** SUBMIT FORM */
export const submitSupportFundStudentForm = (student) => ({
    type: SUBMIT_SUPPORT_FUND_STUDENT_FORM,
    payload: student
});

export const submitSupportFundStudentFormSuccess = (student) => ({
    type: SUBMIT_SUPPORT_FUND_STUDENT_FORM_SUCCESS,
    payload: student
});

export const submitSupportFundStudentFormFailed = (error) => ({
    type: SUBMIT_SUPPORT_FUND_STUDENT_FORM_FAILED,
    payload: error
});

/** DELETE FORM */
export const deleteSupportFundStudent= (id) => ({
    type: DELETE_SUPPORT_FUND_STUDENT,
    payload: id
});

export const deleteSupportFundStudentSuccess = (id) => ({
    type: DELETE_SUPPORT_FUND_STUDENT_SUCCESS,
    payload: id
});

export const deleteSupportFundStudentFailed = (error) => ({
    type: DELETE_SUPPORT_FUND_STUDENT_FAILED,
    payload: error
});

/** EXPORT */
export const exportSupportFundStudents = (data) => ({
    type: EXPORT_SUPPORT_FUND_STUDENTS,
    payload: data
});

export const exportSupportFundStudentsSuccess = () => ({
    type: EXPORT_SUPPORT_FUND_STUDENTS_SUCCESS
});

export const exportSupportFundStudentsFailed = (error) => ({
    type: EXPORT_SUPPORT_FUND_STUDENTS_FAILED,
    payload: error
});