import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { fetchJSON, apiRootEndpoint } from '../../helpers';
import {
    SUBMIT_OCR_FORM, 
    SUBMIT_CRM_CLIENT_FORM
} from '../../constants/actionTypes';

import {
    submitOCRFormSuccess,
    submitOCRFormFailed,

    submitCRMClientFormSuccess,
    submitCRMClientFormFailed
} from './actions';

const apiUrl = `${apiRootEndpoint}/api/v1/ocr`;

function* submitOCR({ payload: branch }) {
    const options = {
        body: JSON.stringify(branch),
        method: branch.id ? 'PUT' :  'POST',
        headers: { 'Content-Type': 'application/json' }
    };

    try {
        let url = `${apiUrl}`;
        if(branch.id) {
            url = `${apiUrl}/${branch.id}`;
        }
        const response = yield call(fetchJSON, url, options);
        if(response.status === 'OK') {
            yield put(submitOCRFormSuccess(response.data));
        }
        else {
            yield put(submitOCRFormFailed(response.message));
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(submitOCRFormFailed(message));
    }
}

function* submitCRMClient({ payload: data }) {
    const options = {
        body: JSON.stringify(data),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' }
    };

    try {
        let url = `${apiUrl}/crm/client`;
        const response = yield call(fetchJSON, url, options);
        if(response.status === 'OK') {
            yield put(submitCRMClientFormSuccess(response.data));
        }
        else {
            yield put(submitCRMClientFormFailed(response.message));
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(submitCRMClientFormFailed(message));
    }
}

export function* watchSubmitOCRForm() {
    yield takeEvery(SUBMIT_OCR_FORM, submitOCR);
}

export function* watchSubmitCRMClientForm() {
    yield takeEvery(SUBMIT_CRM_CLIENT_FORM, submitCRMClient);
}

function* ocrSaga() {
    yield all([
        fork(watchSubmitOCRForm),
        fork(watchSubmitCRMClientForm),
    ]);
}

export default ocrSaga;