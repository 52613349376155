import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { fetchJSON, apiRootEndpoint } from '../../helpers';
import {
    GET_TELEGRAM_COES,
    GET_TELEGRAM_USERS,
    GET_TELEGRAM_COLLEGES, 
    GET_TELEGRAM_REPORT,
    EXPORT_TELEGRAM_COES,
    SUBMIT_TELEGRAM_COE_FORM
} from '../../constants/actionTypes';


import {
    getAllTelegramCoesSuccess,
    getAllTelegramCoesFailed,

    getAllTelegramUsersSuccess,
    getAllTelegramUsersFailed,

    getAllTelegramCollegesSuccess,
    getAllTelegramCollegesFailed,

    getTelegramReportSuccess,
    getTelegramReportFailed,

    exportTelegramCoesSuccess,
    exportTelegramCoesFailed,

    submitTelegramCoeFormSuccess,
    submitTelegramCoeFormFailed
} from './actions';

const apiUrl = `${apiRootEndpoint}/api/v1/telegram-coes`; 

function* getAllTelegramCoes({ payload: { offset, limit, filter } }) {
    const options = {
        method: 'GET',
    };

    try {
        let url = `${apiUrl}?offset=${offset}&limit=${limit}`;
        if(filter.query) {
            url = `${url}&query=${filter.query}`
        }
        if(filter.telegramUserId) {
            url = `${url}&telegramUserId=${filter.telegramUserId}`
        }
        if(filter.collegeName) {
            url = `${url}&collegeName=${filter.collegeName}`
        }
        if(filter.startYear) {
            url = `${url}&startYear=${filter.startYear}`;
        }
        if(filter.intake) {
            url = `${url}&intake=${filter.intake}`;
        }
        if(filter.branchId) {
            url = `${url}&branchId=${filter.branchId}`;
        }
        if(filter.type) {
            url = `${url}&type=${filter.type}`;
        }
        if(filter.reportYear) {
            url = `${url}&reportYear=${filter.reportYear}`;
        }
        const {total, data} = yield call(fetchJSON, url, options);
        
        yield put(getAllTelegramCoesSuccess({total, data}));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(getAllTelegramCoesFailed(message));
    }
}


function* getAllTelegramUsers() {
    const options = {
        method: 'GET',
    };

    try {
        let url = `${apiUrl}/users`;

        const {total, data} = yield call(fetchJSON, url, options);
        
        yield put(getAllTelegramUsersSuccess({total, data}));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(getAllTelegramUsersFailed(message));
    }
}

function* getAllTelegramColleges() {
    const options = {
        method: 'GET',
    };

    try {
        let url = `${apiUrl}/colleges`;

        const {total, data} = yield call(fetchJSON, url, options);
        
        yield put(getAllTelegramCollegesSuccess({total, data}));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(getAllTelegramCollegesFailed(message));
    }
}

function* getTelegramReport({ payload: filter}) {
    const options = {
        method: 'GET',
    };
    
    try {
        let url = `${apiUrl}/report?filter1=${filter.filter1}&filter2=${filter.filter2}`;
        if(filter.startYear) {
            url = `${url}&startYear=${filter.startYear}`;
        }

        if(filter.intake) {
            url = `${url}&intake=${filter.intake}`;
        }

        if(filter.branchId) {
            url = `${url}&branchId=${filter.branchId}`;
        }

        if(filter.telegramUserId) {
            url = `${url}&telegramUserId=${filter.telegramUserId}`; 
        }

        if(filter.type) {
            url = `${url}&type=${filter.type}`; 
        }

        if(filter.collegeName) {
            url = `${url}&collegeName=${filter.collegeName}`;
        }

        if(filter.reportYear) {
            url = `${url}&reportYear=${filter.reportYear}`;
        }

        const {data} = yield call(fetchJSON, url, options);
        
        yield put(getTelegramReportSuccess({data}));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(getTelegramReportFailed(message));
    }
}


function* exportTelegramCoes({ payload: filter}) {
    const options = {
        method: 'GET',
    };

    try {
        let url = `${apiUrl}/export?${Object.entries(filter).map(e => e.join('=')).join('&')}`;

        const response = yield call(fetchJSON, url, options);
        
        if(response.status === "OK") {
            if(response.url) {
                window.open(response.url);
            }
            yield put(exportTelegramCoesSuccess());
        }
        else {
            yield put(exportTelegramCoesFailed(response.message));
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(exportTelegramCoesFailed(message));
    }
}



function* submitTelegramCoe({ payload: item }) {
    const options = {
        body: JSON.stringify(item),
        method: item.id ? 'PUT' :  'POST',
        headers: { 'Content-Type': 'application/json' }
    };

    try {
        let url = `${apiUrl}`;
        if(item.id) {
            url = `${apiUrl}/${item.id}`;
        }
        const response = yield call(fetchJSON, url, options);
        if(response.status === 'OK') {
            yield put(submitTelegramCoeFormSuccess(response.data));
        }
        else {
            yield put(submitTelegramCoeFormFailed(response.message));
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(submitTelegramCoeFormFailed(message));
    }
}

export function* watchGetAllTelegramCoes() {
    yield takeEvery(GET_TELEGRAM_COES, getAllTelegramCoes);
}
export function* watchGetAllTelegramUsers() {
    yield takeEvery(GET_TELEGRAM_USERS, getAllTelegramUsers);
}
export function* watchGetAllTelegramColleges() {
    yield takeEvery(GET_TELEGRAM_COLLEGES, getAllTelegramColleges);
}
export function* watchGetTelegramReport() {
    yield takeEvery(GET_TELEGRAM_REPORT, getTelegramReport);
}
export function* watchExportTelegramCoes() {
    yield takeEvery(EXPORT_TELEGRAM_COES, exportTelegramCoes);
}
export function* watchSubmitTelegramCoe() {
    yield takeEvery(SUBMIT_TELEGRAM_COE_FORM, submitTelegramCoe);
}

function* telegramCoeSaga() {
    yield all([
        fork(watchGetAllTelegramCoes),
        fork(watchGetAllTelegramUsers),
        fork(watchGetAllTelegramColleges),
        fork(watchGetTelegramReport),
        fork(watchExportTelegramCoes),
        fork(watchSubmitTelegramCoe)
    ]);
}

export default telegramCoeSaga;