import {
    GET_COES,
    GET_COES_SUCCESS,
    GET_COES_FAILED,

    SUBMIT_COES_FORM,
    SUBMIT_COES_FORM_SUCCESS,
    SUBMIT_COES_FORM_FAILED,

    DELETE_COES,
    DELETE_COES_SUCCESS,
    DELETE_COES_FAILED,

    FETCH_COES,
    FETCH_COES_SUCCESS,
    FETCH_COES_FAILED,

    EXPORT_COES,
    EXPORT_COES_SUCCESS,
    EXPORT_COES_FAILED,

    MATCH_COES,
    MATCH_COES_SUCCESS,
    MATCH_COES_FAILED
} from '../../constants/actionTypes';

const INIT_STATE = {
    loading: false,
    coes: [],
    submittingForm: false,
    error: null,
    deleting: false,
    fetching: false,
    exporting: false,
    matching: false
};

const CoeReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_COES:
            return { ...state, loading: true };
        case GET_COES_SUCCESS:
            return { ...state, coes: action.payload.data, total: action.payload.total, currentRole: null, loading: false, error: null };
        case GET_COES_FAILED:
            return { ...state, error: action.payload, loading: false };

        case FETCH_COES:
            return { ...state, fetching: true, };
        case FETCH_COES_SUCCESS:
            return { ...state, coes: action.payload.data, total: action.payload.total, currentRole: null, fetching: false, error: null };
        case FETCH_COES_FAILED:
            return { ...state, error: action.payload, fetching: false };

        case MATCH_COES:
            return { ...state, matching: true, };
        case MATCH_COES_SUCCESS:
            return { ...state, matching: false, error: null };
        case MATCH_COES_FAILED:
            return { ...state, error: action.payload, matching: false };

        case SUBMIT_COES_FORM:
            return { ...state, error: null, submittingForm: true };
        case SUBMIT_COES_FORM_SUCCESS:
            const alreadyExists = state.coes.find((item) => item.id === action.payload.id);
            let coes;
            let total = state.total;
            if (!alreadyExists) {
                coes = [action.payload, ...state.coes];
                total += 1;
            } else {
                coes = state.coes.map((item) => {
                if (item.id === action.payload.id) {
                  return action.payload;
                }
                return item;
              });
            }
            return { ...state, total,  coes, submittingForm: false };
        case SUBMIT_COES_FORM_FAILED:
            return { ...state, error: action.payload, submittingForm: false };

        case DELETE_COES:
            return { ...state, error: null, deleting: true };
        case DELETE_COES_SUCCESS:
            return { ...state, total: state.total - 1, coes: state.coes.filter(item => item.id !== action.payload) , deleting: false };
        case DELETE_COES_FAILED:
            return { ...state, error: action.payload, deleting: false };

        case EXPORT_COES:
            return { ...state, error: null, exporting: true };
        case EXPORT_COES_SUCCESS:
            return { ...state, exporting: false };
        case EXPORT_COES_FAILED:
            return { ...state, error: action.payload, exporting: false };
        
        default: return { ...state };
    }
}

export default CoeReducer;