import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { fetchJSON, apiRootEndpoint } from '../../helpers';
import {
    GET_TIMESHEETS, 
    SUBMIT_TIMESHEET_FORM,
    DELETE_TIMESHEET,
    EXPORT_TIMESHEET
} from '../../constants/actionTypes';


import {
    getAllTimesheetsSuccess,
    getAllTimesheetsFailed,

    submitTimesheetFormSuccess,
    submitTimesheetFormFailed,

    deleteTimesheetSuccess,
    deleteTimesheetFailed,

    exportTimesheetSuccess,
    exportTimesheetFailed,
} from './actions';

const apiUrl = `${apiRootEndpoint}/api/v1/timesheets`;

function* getAllTimesheets({ payload: { offset, limit, filter } }) {
    const options = {
        method: 'GET',
    };

    try {
        let url = `${apiUrl}?offset=${offset}&limit=${limit}`;
        if(filter.branchId) {
            url = `${url}&branchId=${filter.branchId}`
        }
        if(filter.startDate && filter.endDate ) {
            url = `${url}&startDate=${filter.startDate}&endDate=${filter.endDate}`
        }
        if(filter.userId) {
            url = `${url}&userId=${filter.userId}`
        }
        if(filter.query) {
            url = `${url}&query=${filter.query}`
        }
        const {total, data} = yield call(fetchJSON, url, options);
        yield put(getAllTimesheetsSuccess({total, data}));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(getAllTimesheetsFailed(message));
    }
}

function* submitTimesheet({ payload: timesheet }) {
    const options = {
        body: JSON.stringify(timesheet),
        method: timesheet.id ? 'PUT' :  'POST',
        headers: { 'Content-Type': 'application/json' }
    };

    try {
        let url = `${apiUrl}`;
        if(timesheet.id) {
            url = `${apiUrl}/${timesheet.id}`;
        }
        const response = yield call(fetchJSON, url, options);
        if(response.status === 'OK') {
            yield put(submitTimesheetFormSuccess(response.data));
        }
        else {
            yield put(submitTimesheetFormFailed(response.message));
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(submitTimesheetFormFailed(message));
    }
}

function* deleteTimesheet({ payload: id }) {
    const options = {
        method: 'DELETE',
    };
    try {
        const url = `${apiUrl}/${id}`;
        const response = yield call(fetchJSON, url, options);
        
        if(response.status === "OK") {
            yield put(deleteTimesheetSuccess(id));
        }
        else {
            yield put(deleteTimesheetFailed(response.message));
        }
    } catch (error) {
       
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(deleteTimesheetFailed(message));
    }
}

function* exportTimesheet({ payload: data }) {
    const options = {
        body: JSON.stringify(data),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' }
    };
    try {
        const url = `${apiUrl}/export`;
        const response = yield call(fetchJSON, url, options);
        
        if(response.status === "OK") {
            if(response.url) {
                window.open(response.url);
            }
            yield put(exportTimesheetSuccess());
        }
        else {
            yield put(exportTimesheetFailed(response.message));
        }
    } catch (error) {
       
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(exportTimesheetFailed(message));
    }
}


export function* watchGetAllTimesheets() {
    yield takeEvery(GET_TIMESHEETS, getAllTimesheets);
}
export function* watchSubmitTimesheetForm() {
    yield takeEvery(SUBMIT_TIMESHEET_FORM, submitTimesheet);
}
export function* watchDeleteTimesheet() {
    yield takeEvery(DELETE_TIMESHEET, deleteTimesheet);
}
export function* watchExportTimesheet() {
    yield takeEvery(EXPORT_TIMESHEET, exportTimesheet);
}

function* timesheetSaga() {
    yield all([
        fork(watchGetAllTimesheets),
        fork(watchSubmitTimesheetForm),
        fork(watchDeleteTimesheet),
        fork(watchExportTimesheet),
    ]);
}

export default timesheetSaga;