import {
    GET_DEPARTMENTS,
    GET_DEPARTMENTS_SUCCESS,
    GET_DEPARTMENTS_FAILED,

    SUBMIT_DEPARTMENT_FORM,
    SUBMIT_DEPARTMENT_FORM_SUCCESS,
    SUBMIT_DEPARTMENT_FORM_FAILED,

    DELETE_DEPARTMENT,
    DELETE_DEPARTMENT_SUCCESS,
    DELETE_DEPARTMENT_FAILED,
} from '../../constants/actionTypes';


/** GET ALL */
export const getAllDepartments = ({offset, limit}) => ({
    type: GET_DEPARTMENTS,
    payload: { offset, limit }
});

export const getAllDepartmentsSuccess = ({total, data}) => ({
    type: GET_DEPARTMENTS_SUCCESS,
    payload: {total, data}
});

export const getAllDepartmentsFailed = (error) => ({
    type: GET_DEPARTMENTS_FAILED,
    payload: error
});

/** SUBMIT FORM */
export const submitDepartmentForm = (country) => ({
    type: SUBMIT_DEPARTMENT_FORM,
    payload: country
});

export const submitDepartmentFormSuccess = (country) => ({
    type: SUBMIT_DEPARTMENT_FORM_SUCCESS,
    payload: country
});

export const submitDepartmentFormFailed = (error) => ({
    type: SUBMIT_DEPARTMENT_FORM_FAILED,
    payload: error
});

/** DELETE FORM */
export const deleteDepartment = (id) => ({
    type: DELETE_DEPARTMENT,
    payload: id
});

export const deleteDepartmentSuccess = (id) => ({
    type: DELETE_DEPARTMENT_SUCCESS,
    payload: id
});

export const deleteDepartmentFailed = (error) => ({
    type: DELETE_DEPARTMENT_FAILED,
    payload: error
});