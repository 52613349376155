import {
    GET_COES,
    GET_COES_SUCCESS,
    GET_COES_FAILED,

    SUBMIT_COES_FORM,
    SUBMIT_COES_FORM_SUCCESS,
    SUBMIT_COES_FORM_FAILED,

    DELETE_COES,
    DELETE_COES_SUCCESS,
    DELETE_COES_FAILED,

    FETCH_COES,
    FETCH_COES_SUCCESS,
    FETCH_COES_FAILED,

    EXPORT_COES,
    EXPORT_COES_SUCCESS,
    EXPORT_COES_FAILED,

    MATCH_COES,
    MATCH_COES_SUCCESS,
    MATCH_COES_FAILED
} from '../../constants/actionTypes';


/** GET ALL */
export const getAllCoes = ({offset, limit, filter}) => ({
    type: GET_COES,
    payload: { offset, limit, filter }
});

export const getAllCoesSuccess = ({total, data}) => ({
    type: GET_COES_SUCCESS,
    payload: {total, data}
});

export const getAllCoesFailed = (error) => ({
    type: GET_COES_FAILED,
    payload: error
});

/** FETCH */
export const fetchCoes = () => ({
    type: FETCH_COES
});

export const fetchCoesSuccess = ({total, data}) => ({
    type: FETCH_COES_SUCCESS,
    payload: {total, data}
});

export const fetchCoesFailed = (error) => ({
    type: FETCH_COES_FAILED,
    payload: error
});

/** SUBMIT FORM */
export const submitCoeForm = (country) => ({
    type: SUBMIT_COES_FORM,
    payload: country
});

export const submitCoeFormSuccess = (role) => ({
    type: SUBMIT_COES_FORM_SUCCESS,
    payload: role
});

export const submitCoeFormFailed = (error) => ({
    type: SUBMIT_COES_FORM_FAILED,
    payload: error
});

/** DELETE FORM */
export const deleteCoe = (id) => ({
    type: DELETE_COES,
    payload: id
});

export const deleteCoeSuccess = (id) => ({
    type: DELETE_COES_SUCCESS,
    payload: id
});

export const deleteCoeFailed = (error) => ({
    type: DELETE_COES_FAILED,
    payload: error
});

/** EXPORT */
export const exportCoes = (data) => ({
    type: EXPORT_COES,
    payload: data
});

export const exportCoesSuccess = () => ({
    type: EXPORT_COES_SUCCESS
});

export const exportCoesFailed = (error) => ({
    type: EXPORT_COES_FAILED,
    payload: error
});

/** MATCH */
export const matchCoes = () => ({
    type: MATCH_COES,
});

export const matchCoesSuccess = () => ({
    type: MATCH_COES_SUCCESS
});

export const matchCoesFailed = (error) => ({
    type: MATCH_COES_FAILED,
    payload: error
});