import {
    GET_EVENTS,
    GET_EVENTS_SUCCESS,
    GET_EVENTS_FAILED,

    SUBMIT_EVENT_FORM,
    SUBMIT_EVENT_FORM_SUCCESS,
    SUBMIT_EVENT_FORM_FAILED,

    DELETE_EVENT,
    DELETE_EVENT_SUCCESS,
    DELETE_EVENT_FAILED,
} from '../../constants/actionTypes';


/** GET ALL */
export const getAllEvents = ({offset, limit, filter}) => ({
    type: GET_EVENTS,
    payload: { offset, limit, filter }
});

export const getAllEventsSuccess = ({total, data}) => ({
    type: GET_EVENTS_SUCCESS,
    payload: {total, data}
});

export const getAllEventsFailed = (error) => ({
    type: GET_EVENTS_FAILED,
    payload: error
});

/** SUBMIT FORM */
export const submitEventForm = (item) => ({
    type: SUBMIT_EVENT_FORM,
    payload: item
});

export const submitEventFormSuccess = (item) => ({
    type: SUBMIT_EVENT_FORM_SUCCESS,
    payload: item
});

export const submitEventFormFailed = (error) => ({
    type: SUBMIT_EVENT_FORM_FAILED,
    payload: error
});

/** DELETE FORM */
export const deleteEvent = (id) => ({
    type: DELETE_EVENT,
    payload: id
});

export const deleteEventSuccess = (id) => ({
    type: DELETE_EVENT_SUCCESS,
    payload: id
});

export const deleteEventFailed = (error) => ({
    type: DELETE_EVENT_FAILED,
    payload: error
});