import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { fetchJSON, apiRootEndpoint } from '../../helpers';
import {
    GET_EVENTS, 
    SUBMIT_EVENT_FORM,
    DELETE_EVENT
} from '../../constants/actionTypes';


import {
    getAllEventsSuccess,
    getAllEventsFailed,

    submitEventFormSuccess,
    submitEventFormFailed,

    deleteEventSuccess,
    deleteEventFailed,
} from './actions';

const apiUrl = `${apiRootEndpoint}/api/v1/events`;

function* getAllEvents({ payload: { offset, limit, filter = {} } }) {
    const options = {
        method: 'GET',
    };

    try {
        const url = `${apiUrl}?offset=${offset}&limit=${limit}`;
        const {total, data} = yield call(fetchJSON, url, options);
        yield put(getAllEventsSuccess({total, data}));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(getAllEventsFailed(message));
    }
}

function* submitEvent({ payload: item }) {
    const options = {
        body: JSON.stringify(item),
        method: item.id ? 'PUT' :  'POST',
        headers: { 'Content-Type': 'application/json' }
    };

    try {
        let url = `${apiUrl}`;
        if(item.id) {
            url = `${apiUrl}/${item.id}`;
        }
        const response = yield call(fetchJSON, url, options);
        if(response.status === 'OK') {
            yield put(submitEventFormSuccess(response.data));
        }
        else {
            yield put(submitEventFormFailed(response.message));
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(submitEventFormFailed(message));
    }
}

function* deleteEvent({ payload: id }) {
    const options = {
        method: 'DELETE',
    };
    try {
        const url = `${apiUrl}/${id}`;
        const response = yield call(fetchJSON, url, options);
        if(response.status === 'OK') {
            yield put(deleteEventSuccess(id));
        }
        else {
            yield put(deleteEventFailed(response.message));
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(deleteEventFailed(message));
    }
}


export function* watchGetAllEvents() {
    yield takeEvery(GET_EVENTS, getAllEvents);
}
export function* watchSubmitEventForm() {
    yield takeEvery(SUBMIT_EVENT_FORM, submitEvent);
}
export function* watchDeleteEvent() {
    yield takeEvery(DELETE_EVENT, deleteEvent);
}

function* eventSaga() {
    yield all([
        fork(watchGetAllEvents),
        fork(watchSubmitEventForm),
        fork(watchDeleteEvent),
    ]);
}

export default eventSaga;