import {
    GET_SUPPORT_FUND_ORGANIZATIONS,
    GET_SUPPORT_FUND_ORGANIZATIONS_SUCCESS,
    GET_SUPPORT_FUND_ORGANIZATIONS_NOTES_FAILED,

    SUBMIT_SUPPORT_FUND_ORGANIZATION_FORM,
    SUBMIT_SUPPORT_FUND_ORGANIZATION_FORM_SUCCESS,
    SUBMIT_SUPPORT_FUND_ORGANIZATION_FORM_FAILED,

    DELETE_SUPPORT_FUND_ORGANIZATION,
    DELETE_SUPPORT_FUND_ORGANIZATION_SUCCESS,
    DELETE_SUPPORT_FUND_ORGANIZATION_FAILED,
} from '../../constants/actionTypes';


/** GET ALL */
export const getAllSupportFundOrganizations = ({offset, limit}) => ({
    type: GET_SUPPORT_FUND_ORGANIZATIONS,
    payload: { offset, limit }
});

export const getAllSupportFundOrganizationsSuccess = ({total, data}) => ({
    type: GET_SUPPORT_FUND_ORGANIZATIONS_SUCCESS,
    payload: {total, data}
});

export const getAllSupportFundOrganizationsFailed = (error) => ({
    type: GET_SUPPORT_FUND_ORGANIZATIONS_NOTES_FAILED,
    payload: error
});

/** SUBMIT FORM */
export const submitSupportFundOrganizationForm = (organization) => ({
    type: SUBMIT_SUPPORT_FUND_ORGANIZATION_FORM,
    payload: organization
});

export const submitSupportFundOrganizationFormSuccess = (organization) => ({
    type: SUBMIT_SUPPORT_FUND_ORGANIZATION_FORM_SUCCESS,
    payload: organization
});

export const submitSupportFundOrganizationFormFailed = (error) => ({
    type: SUBMIT_SUPPORT_FUND_ORGANIZATION_FORM_FAILED,
    payload: error
});

/** DELETE FORM */
export const deleteSupportFundOrganization= (id) => ({
    type: DELETE_SUPPORT_FUND_ORGANIZATION,
    payload: id
});

export const deleteSupportFundOrganizationSuccess = (id) => ({
    type: DELETE_SUPPORT_FUND_ORGANIZATION_SUCCESS,
    payload: id
});

export const deleteSupportFundOrganizationFailed = (error) => ({
    type: DELETE_SUPPORT_FUND_ORGANIZATION_FAILED,
    payload: error
});