import {
    GET_APPRAISAL_FEEDBACKS,
    GET_APPRAISAL_FEEDBACKS_SUCCESS,
    GET_APPRAISAL_FEEDBACKS_FAILED,

    SUBMIT_APPRAISAL_FEEDBACK_FORM,
    SUBMIT_APPRAISAL_FEEDBACK_FORM_SUCCESS,
    SUBMIT_APPRAISAL_FEEDBACK_FORM_FAILED,
} from '../../constants/actionTypes'; 

/** GET ALL */
export const getAllAppraisalFeedbacks = ({offset, limit, filter}) => ({
    type: GET_APPRAISAL_FEEDBACKS,
    payload: { offset, limit, filter }
});

export const getAllAppraisalFeedbacksSuccess = ({total, data}) => ({
    type: GET_APPRAISAL_FEEDBACKS_SUCCESS,
    payload: {total, data}
});

export const getAllAppraisalFeedbacksFailed = (error) => ({
    type: GET_APPRAISAL_FEEDBACKS_FAILED,
    payload: error
});


/** SUBMIT FORM */
export const submitAppraisalFeedbackForm = (item) => ({
    type: SUBMIT_APPRAISAL_FEEDBACK_FORM,
    payload: item
});

export const submitAppraisalFeedbackFormSuccess = (item) => ({
    type: SUBMIT_APPRAISAL_FEEDBACK_FORM_SUCCESS,
    payload: item
});

export const submitAppraisalFeedbackFormFailed = (error) => ({
    type: SUBMIT_APPRAISAL_FEEDBACK_FORM_FAILED,
    payload: error
});
