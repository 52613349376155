import {
    GET_USERS,
    GET_USERS_SUCCESS,
    GET_USERS_FAILED,

    SUBMIT_USER_FORM,
    SUBMIT_USER_FORM_SUCCESS,
    SUBMIT_USER_FORM_FAILED,

    DELETE_USER,
    DELETE_USER_SUCCESS,
    DELETE_USER_FAILED,

    GET_CURRENT_USER,
    GET_CURRENT_USER_SUCCESS,
    GET_CURRENT_USER_FAILED
} from '../../constants/actionTypes'; 


/** GET ALL */
export const getAllUsers = ({offset, limit, filter}) => ({
    type: GET_USERS,
    payload: { offset, limit, filter }
});

export const getAllUsersSuccess = ({total, data}) => ({
    type: GET_USERS_SUCCESS,
    payload: {total, data}
});

export const getAllUsersFailed = (error) => ({
    type: GET_USERS_FAILED,
    payload: error
});

/** SUBMIT FORM */
export const submitUserForm = (user) => ({
    type: SUBMIT_USER_FORM,
    payload: user
});

export const submitUserFormSuccess = (user) => ({
    type: SUBMIT_USER_FORM_SUCCESS,
    payload: user
});

export const submitUserFormFailed = (error) => ({
    type: SUBMIT_USER_FORM_FAILED,
    payload: error
});

/** DELETE FORM */
export const deleteUser = (id) => ({
    type: DELETE_USER,
    payload: id
});

export const deleteUserSuccess = (id) => ({
    type: DELETE_USER_SUCCESS,
    payload: id
});

export const deleteUserFailed = (error) => ({
    type: DELETE_USER_FAILED,
    payload: error
});


/** GET CURRENT */
export const getCurrentUser = (id) => ({
    type: GET_CURRENT_USER,
    payload: id
});

export const getCurrentUserSuccess = (user) => ({
    type: GET_CURRENT_USER_SUCCESS,
    payload: user
});

export const getCurrentUserFailed = (error) => ({
    type: GET_CURRENT_USER_FAILED,
    payload: error
});